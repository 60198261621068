/*****************************
USE  to define image path
******************************
Media Queries
1. 400, 480, 640, 767, 1024, 1366
2. use Max-Width
******************************/

/** General */

html,
body,
button,
select {
    font-family: Prompt, sans-serif;
    /* webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0); */
}

a,
a:active,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
}

body {
    overflow-x: hidden;
}

-webkit-scrollbar {
    display: none !important;
}

::-webkit-scrollbar {
    display: none;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.topbar-column {
    display: table-cell;
    vertical-align: middle;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-decoration: none;
    width: 250px;
    height: 50px;
}

.clearfix {
    clear: both;
    display: flex;
}

.custom-btn {
    padding: 0px 15px;
    border-radius: 7px;
    margin: 0px 0px 0px 15px !important;
}

.accordion .card {
    margin-bottom: 5px;
    border-radius: 5px;
}

.accordion .card:first-of-type {
    border-bottom: 1px solid #e1e7ec;
    border-radius: 5px;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 5px;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-radius: 5px;
    border-bottom: 1px solid #e1e7ec;
}

.accordion .card:last-of-type {
    border-radius: 5px;
}

.card-header,
.card-footer {
    background-color: #f5f5f5;
    border-bottom-color: #f5f5f5;
}

.accordion .card:first-of-type {
    border-bottom: 1px solid #e1e7ec;
}

.modal {
    text-align: center;
    padding: 0 !important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

/* Notification modal */

#notificationModal .modal-body {
    padding: 0;
}

#notificationModal .modal-content {
    width: 400px;
    background-color: transparent;
    border: none;
}

#notificationModal .modal-content img {
    border-radius: 10px 10px 0px 0px;
    width: 400px;
}

#notificationModal button.close {
    position: absolute;
    top: 10px;
    color: #ffffff;
    right: 15px;
    text-shadow: none;
    opacity: 1;
}

.modal-dialog.samepayment {
    width: 550px !important;
}

.notification-description {
    padding: 16px 20px 10px 20px;
    font-weight: bold;
    background-color: #fff;
    color: #000;
    border-radius: 10px 10px 0 0;
    margin: -8px 0px 0px 0px;
    white-space: pre-wrap;
}

.notification-link {
    cursor: pointer;
    text-align: center;
    padding: 5px 20px;
    border-radius: 0px 0px 10px 10px;
}

@media (min-width: 768px) {
    #notificationModal .modal-dialog {
        width: 400px !important;
    }

    .maint-read-more-link {
        display: none;
    }

    .table-comm-lasb-mob {
        padding: 0px 100px !important;
    }
}

@media (max-width: 768px) {
    #notificationModal .modal-dialog {
        width: 90% !important;
    }

    #notificationModal .modal-content {
        width: 100%;
    }

    #notificationModal .modal-content img {
        width: 100%;
    }

    .modal:before {
        margin-right: 0px;
    }

    .form-warranty-center-bios {
        width: 85%;
        margin: 0 auto;
    }

    .form-warranty-center-health {
        width: 100%;
        margin: 0 auto;
    }
}

/* ----------------------------------------- */

.page-container,
#page-wrap {
    background-color: #ffffff;
    height: 100%;
}

.social-icons {
    display: none;
}

iconHover {
    cursor: pointer;
}

iconHover:hover {
    cursor: pointer;
    color: #0da9ef;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block !important;
}

.pills .btn-outline-primary.focus,
.pills .btn-outline-primary:focus {
    box-shadow: 0 0 0 0 !important;
}

.btn-outline-primary:hover {
    border-color: #0da9ef;
}

.dropdown-menu {
    margin: 0.4rem !important;
}

.dropdown-menu.disabled-true {
    display: none;
}

.btn.dropdown-toggle[disabled] {
    background-color: #f5f5f5;
    opacity: 0.85;
}

.reportTab .dropdown-menu {
    width: 225px;
    margin: 0.4rem auto !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle,
.btn-outline-secondary:hover {
    background: transparent;
    color: #606975;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
    background-color: #0aa9ef;
    border-color: #0aa9ef;
    box-shadow: none;
    color: #fff;
}

.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    border-color: #e1e7ec;
}

.btn-outline-secondary:hover {
    border-color: #e1e7ec;
}

.dropdown-menu a.dropdown-item {
    padding-top: 5px;
    padding-bottom: 5px;
}

.checkout-footer {
    padding: 20px;
}

@media only screen and (max-width: 411px) {
    .checkout-footer {
        padding: 14px;
    }

    .checkbox-re {
        position: relative;
        left: 6px !important;
    }
}

.checkout-footer.not-login {
    height: 273px;
    margin-top: 0px;
}

.checkout-footer .btn > i,
.checkout-footer a > i {
    margin-top: -4px;
}

.checkout-footer a.btn.btn-primary span,
.checkout-footer a.btn.btn-primary i {
    color: #fff;
}

.checkout-payment.checkout-footer .btn {
    width: 100%;
    font-weight: bold;
}

.max-height-160 {
    max-height: 160px !important;
}

.hide-filter-section-active {
    display: none;
}

/*Sathya's UI changes*/

.live-button {
    border: 1px solid gray;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 9px !important;
}

.verticalMiddle {
    vertical-align: middle;
}

.fade-in-on-load {
    animation-duration: 2.3s;
    animation-name: fadeInOnLoad;
}

@keyframes fadeInOnLoad {
    0%,
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.transparent-bg {
    background-color: transparent;
}

.ushop-webview-footer-icon {
    font-size: 12;
    font-weight: bold;
}

.border-none {
    border: none;
}

.alignSelf-center {
    align-self: center;
}

.ushop-webview-footer {
    position: fixed;
    bottom: 0;
    z-index: 2222;
    left: 0;
    right: 0;
    text-align: center;
    height: 55px;
    /* border: 1px grey; */
    box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
    -webkit-box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
    -moz-box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
}

.ushop-webview-footer-container {
    height: 55px;
    border-top: 1px solid rgb(225, 231, 236);
    background-color: white;
}

.min-width-200 {
    min-width: 200px !important;
}

.max-height-180 {
    max-height: 180px;
}

.margin-right-10-imp {
    margin-right: 10px !important;
}

.margin-right-5-imp {
    margin-right: 5px !important;
}

.padding-1x {
    padding: 24px;
}

.slick-slide-500.slick-slide {
    height: 500px !important;
}

.padding-left-15 {
    padding-left: 15px;
}

.absolute-center {
    margin: auto;
    left: 0;
    right: 0;
}

.margin-left-1x {
    margin-left: 24px !important;
}

.margin-bottom-3 {
    margin-bottom: 3px !important;
}

.height-30 {
    height: 30px;
    max-height: 30px;
}

.max-width-80x {
    max-width: 80px !important;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-left-right-null {
    padding-left: 0px;
    padding-right: 0px;
}

.max-width-100x {
    max-width: 100px;
}

.padding-left-1x {
    padding-left: 24px;
}

.padding-left-right-1x {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.padding-right-10 {
    padding-right: 10px !important;
}

.padding-left-null {
    padding-left: 0px !important;
}

.padding-010 {
    padding: 0 10% !important;
}

.fbshareicon {
    border-radius: 5px;
    cursor: pointer;
}

.twshareicon {
    border-radius: 5px;
    cursor: pointer;
}

.emailshareicon {
    border-radius: 5px;
    cursor: pointer;
}

.whatsappshareicon {
    border-radius: 5px;
    cursor: pointer;
}

.radio-button-style {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.display-inline {
    display: inline;
}

.padding-left-30 {
    padding-left: 30px;
}

.height-60 {
    height: 50px !important;
    max-height: 50px !important;
    min-height: 50px !important;
}

.padding-top-6 {
    padding-top: 6px !important;
}

.padding-top-10 {
    padding-top: 10px;
}

.text-success {
    color: #27ae60 !important;
}

.button-center {
    margin: 0 auto;
    display: block;
}

.notification-icon {
    /*width: 30px;*/
    transition: background-color 0.35s;
    color: #606975;
    font-size: 14px;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding-top: 4px;
}

.padding-24 {
    padding: 24px;
}

.btn-xs {
    height: 28px;
    padding: 4px 30px;
    border-radius: 18px;
    font-size: 12px;
    line-height: 20px;
}

.genealogy-page-wrapper button.btn {
    font-family: Prompt, sans-serif !important;
    line-height: 0 !important;
    letter-spacing: 0 !important;
    font-weight: 100 !important;
    text-transform: unset;
    margin-right: 0px;
}

div#genealogyComponent .btn-show-all-less {
    line-height: 0px;
}

div#genealogyComponent-loading {
    min-height: 500px;
    opacity: 0.2;
}

div#genealogyComponent-loading #card-parent {
    opacity: 0;
}

#genealogy-table-loader > div {
    position: fixed;
    z-index: 2;
    height: 500px;
}

.notification-modal {
    width: 450px;
    background: white;
    position: fixed;
    right: 20px;
    top: 63px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    -webkit-transition-property: none;
    -moz-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
    z-index: 1000;
}

.width-60 {
    width: 60px;
}

.vertical-center-align {
    position: absolute !important;
    top: 44% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 50% !important;
    height: 50% !important;
}

.vertical-center-align-btn {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bar {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.padding-border-top-color {
    padding-top: 0px !important;
    border-top: 0px !important;
}

.min-width-225 {
    min-width: 225px;
}

.padding-15 {
    padding: 15px;
}

.margin-bottom-6 {
    margin-bottom: 6px;
}

.ref-table p {
    font-size: 16px;
}

.mob-cart-img {
    display: none;
}

.order-history p {
    margin-left: 33%;
}

.order-history tr td {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px;
    vertical-align: top;
}

/* .order-history img {
    margin-left: 33%;
} */

.orderid-inline {
    display: inline-block;
}

.order-items .order-history {
    text-align: center;
}

.order-items .col {
    margin: auto;
}

.form-check label {
    text-align: center;
    display: block;
}

.form-check a {
    /* white-space: nowrap; */
}

.form-check-input {
    margin-top: 0.44rem !important;
    margin-left: -2rem !important;
}

.enrollment-page div > h4 {
    text-align: center;
}

.custom-control .custom-control-label {
    padding-left: 5px;
}

.custom-control-label::before,
.custom-control-label::after {
    left: 0;
    top: 2px;
    width: 14px;
    height: 14px;
}

.form-city .pills {
    margin-top: 0;
}

.form-city .dropdown-menu.show {
    max-height: 600px;
    overflow: scroll;
}

.form-bank .pills {
    margin-top: 0;
}

.form-bank .dropdown-menu.show {
    max-height: 600px;
    overflow: scroll;
}

.slick-list .slick-slide .header {
    font-size: 40px;
}

.slick-list .slick-slide .body {
    font-size: 18px;
}

.slick-list .slick-slide .mb_header {
    font-size: 28px;
}

.checkTC {
    width: 320px;
}

.checkTC-VN {
    width: 267px;
}

.TH .checkTC {
    width: 240px;
}

.checkTC label {
    width: 300px;
}

.pills .btn {
    text-transform: capitalize;
}

a.order-button-report:not([href]):not([tabindex]) {
    color: #48a8e9;
}

.order-button-report.active:not([href]):not([tabindex]) {
    color: #f0f0f0;
}

.order-button-report.active {
    background-color: #0aa9ef;
}

.order-button-report.active::before {
    font-family: feather;
    content: '\e042';
    margin-right: 5px;
}

.not-login .custom-control-label::before,
.not-login .custom-control-label::after {
    left: 2px;
}

.scrollable-desktop {
    margin: 0 -15px;
}

.scrollable {
    overflow: auto;
    white-space: nowrap;
    margin: 0 -15px;
}

.scrollable button:first-child {
    margin-left: 16px;
}

.scrollable button:last-child {
    margin-right: 16px;
}

i.icon-bag {
    top: 4px !important;
}

i.icon-head {
    top: 5px !important;
}

.row.img-footer-japan,
.row.img-footer-vietnam {
    margin-top: 87px;
}

.font-size-genealogy-screen {
    font-size: 16px;
    font-family: Prompt, sans-serif;
    font-weight: 100;
}

.font-size-genealogy-screen-small {
    font-size: 12px;
    font-family: Prompt, sans-serif;
    font-weight: normal;
}

.pills > button[disabled] {
    border-color: #dbe2e8 !important;
    color: #495057 !important;
    background: #f5f5f5 !important;
}

.padding-left-right-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.margin-left-right-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.table.genealogyCard.ba-info tr td.text-right {
    padding-right: 0px;
}

@media only screen and (max-width: 640px) {
    .table.genealogyCard.ba-info tr td.text-right {
        overflow: hidden;
        padding-right: 0px;
    }
}

@media only screen and (max-width: 320px) {
    .table.genealogyCard.ba-info tr td.text-right {
        overflow: hidden;
        padding-right: 0px;
    }

    .youtube-vid {
        padding: 15px;
        border-radius: 10px !important;
    }

    .look-forward {
        padding: 15px;
        border-radius: 10px !important;
    }

    .promotional-countDown-left-no-padding {
        padding: 15px;
    }

    .media-border-radius {
        border-radius: 10px !important;
    }

    .image-object-fit {
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
    }

    .margin-top-20 {
        margin-top: 08px;
    }

    .small-device-text-right {
        text-align: right;
    }

    .small-device-margin-top-18 {
        margin-top: 18px;
    }

    .border-radius-promotional-pic {
        border-radius: 10px;
    }

    .border-shadow-delivery {
        -moz-box-shadow: 0px 1px 1px #ccc;
        box-shadow: 0px 1px 1px #ccc;
    }

    .responsive-top {
        top: 70px;
    }

    .single-product-resp-img {
        max-height: 250px;
    }

    .order-history p {
        margin-left: 10%;
    }

    .order-history img {
        margin-left: 10%;
    }

    .agreeTC-center::before,
    .agreeTC-center::after {
        left: auto;
    }

    .checkTC {
        width: 190px;
    }

    .checkTC label {
        width: 210px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 750px) {
    .seminar-side-tabs {
        height: 40px !important;
    }
}

@media only screen and (min-width: 1050px) and (max-width: 2560px) {
    .seminar-side-tabs {
        height: 40px !important;
    }

    .dropdown-healthcheck {
        width: 107% !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
    .order-history p {
        margin-left: 10%;
    }

    .order-history img {
        margin-left: 10%;
    }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
    .agreeTC-center::before,
    .agreeTC-center::after {
        left: 50px;
    }
}

@media only screen and (min-width: 350px) and (max-width: 419px) {
    .order-history p {
        margin-left: 20%;
    }

    .order-history img {
        margin-left: 20%;
    }
}

@media only screen and (max-width: 480px) {
    .youtube-vid {
        padding: 15px;
        border-radius: 10px !important;
    }

    .look-forward {
        padding: 15px;
        border-radius: 10px !important;
    }

    .promotional-countDown-left-no-padding {
        padding: 15px;
    }

    .media-border-radius {
        border-radius: 10px !important;
    }

    .image-object-fit {
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
    }

    .margin-top-20 {
        margin-top: 20px;
    }

    .small-device-text-right {
        text-align: right;
    }

    .small-device-margin-top-18 {
        margin-top: 18px;
    }

    .border-radius-promotional-pic {
        border-radius: 10px;
    }

    .border-shadow-delivery {
        -moz-box-shadow: 0px 1px 1px #ccc;
        box-shadow: 0px 1px 1px #ccc;
    }

    .responsive-top {
        top: 70px;
    }

    .single-product-resp-img {
        max-height: 270px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 812px) {
    .youtube-vid {
        padding: 15px;
    }

    .look-forward {
        padding: 15px;
        border-radius: 10px !important;
    }

    .promotional-countDown-left-no-padding {
        padding: 15px;
    }

    .promotional-countDown-padding {
        padding: 15px;
    }

    .media-border-radius {
        border-radius: 10px !important;
    }

    .image-object-fit {
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
    }

    .margin-top-20 {
        margin-top: 20px;
    }

    .border-radius-promotional-pic {
        border-radius: 10px;
    }

    .border-shadow-delivery {
        -moz-box-shadow: 0px 1px 1px #ccc;
        box-shadow: 0px 1px 1px #ccc;
    }

    .responsive-top {
        top: 75px;
    }

    #genealogySnapshot {
        padding-left: 0px;
        padding-right: 0px;
    }

    #genealogySnapshot .col-4 {
        flex: 0 0 30.3333333333%;
        max-width: 30.333333%;
    }

    #genealogySnapshot .col-8 {
        flex: 69%;
        max-width: 70%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .youtube-vid {
        padding: 15px 0 15px 15px;
    }

    /* .look-forward {
        padding: 15px 15px 15px 1px;
    } */
    .look-forward {
        padding: 15px !important;
        border-radius: 10px !important;
    }

    .promotional-countDown-left-no-padding {
        padding: 15px 15px 15px 0;
    }

    .promotional-countDown-padding {
        padding: 15px;
    }

    .border-radius-left-top-bottom {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .border-radius-right-top-bottom {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
        -webkit-border-top-right-radius: 10px !important;
        -webkit-border-bottom-right-radius: 10px !important;
    }

    .border-radius-promotional-pic {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }

    .event-promotional-border-radius {
        border-radius: 10px !important;
    }

    .image-object-fit {
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
    }

    .margin-top-20 {
        margin-top: 20px;
    }

    .min-height-promotional-pic {
        min-height: 421px;
    }

    .min-height-look-forward-pic {
        min-height: 340px;
    }

    .border-shadow-delivery {
        -moz-box-shadow: 0px 1px 1px #ccc;
        box-shadow: 0px 1px 1px #ccc;
    }

    .responsive-top {
        top: 80px;
    }

    .global-connected-button-right,
    .global-connected-button-left {
        padding: 5px 15px !important;
    }

    .global-connected-button-left {
        margin-left: 20px;
    }

    .slick-initialized .slick-slide .product-card .product-thumb {
        display: block;
    }
}

@media only screen and (min-width: 959px) and (max-width: 1200px) {
    .min-height-promotional-pic {
        min-height: 421px;
    }

    .promotional-countDown-padding {
        padding: 15px;
    }

    .youtube-vid {
        padding: 15px 0 15px 15px;
    }

    .look-forward {
        /* padding: 15px 15px 15px 1px; */
        border-radius: 10px !important;
    }

    .promotional-countDown-left-no-padding {
        padding: 15px 15px 15px 0;
    }

    .min-height-promotional-pic {
        min-height: 421px;
    }

    .min-height-look-forward-pic {
        min-height: 430px;
    }

    .border-radius-left-top-bottom {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    .border-radius-right-top-bottom {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
        -webkit-border-top-right-radius: 10px !important;
        -webkit-border-bottom-right-radius: 10px !important;
    }

    .border-radius-promotional-pic {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }

    .border-shadow-delivery {
        -moz-box-shadow: 0px 1px 1px #ccc;
        box-shadow: 0px 1px 1px #ccc;
    }

    .responsive-top {
        top: 80px;
    }
}

@media only screen and (min-width: 959px) and (max-width: 991px) {
    .border-radius-promotional-pic {
        border-radius: 10px;
    }

    .event-promotional-border-radius {
        border-radius: 10px !important;
    }

    .promotional-countDown-padding {
        padding: 15px;
    }

    .border-shadow-delivery {
        -moz-box-shadow: 0px 1px 1px #ccc;
        box-shadow: 0px 1px 1px #ccc;
    }

    .promotional-countDown-left-no-padding {
        padding: 15px 15px 15px 0;
    }

    .responsive-top {
        top: 80px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 2560px) {
    .youtube-vid {
        padding: 15px 0 15px 15px;
    }

    .look-forward {
        padding: 15px 15px 15px 1px;
    }

    .form-warranty-center-bios {
        width: 50% !important;
        margin: 0 auto !important;
    }

    .form-warranty-center-health {
        width: 52% !important;
        margin: 0 auto !important;
    }

    .promotional-countDown-left-no-padding {
        padding: 15px 15px 15px 0;
    }

    .border-radius-left-top-bottom {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .border-radius-right-top-bottom {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
        -webkit-border-top-right-radius: 10px !important;
        -webkit-border-bottom-right-radius: 10px !important;
    }

    .border-radius-promotional-pic {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }

    .margin-top-20 {
        margin-top: 20px;
    }

    .min-height-promotional-pic {
        min-height: 421px;
    }

    .min-height-look-forward-pic {
        min-height: 430px;
    }

    .border-shadow-count {
        -moz-box-shadow: 0px 1px 1px #ccc;
        box-shadow: 0px 1px 1px #ccc;
    }

    .border-shadow-delivery {
        -moz-box-shadow: 0px 1px 1px #ccc;
        box-shadow: 0px 1px 1px #ccc;
    }

    .responsive-top {
        top: 80px;
    }
}

.minHeight230 {
    min-height: 230px !important;
}

.unicity-place {
    font-size: 25px;
    color: red;
}

.border-normal-radius {
    border-radius: 10px;
}

.border-radius-left-top-bottom {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.bottom-click-button {
    position: absolute;
    margin-left: -100px !important;
    left: 50%;
    width: 200px;
    bottom: 24px;
}

.bottom-click-button-copy {
    top: 0;
}

.bottom-click-button-copy-mobile {
    bottom: 10px !important;
}

.bottom-click-button-space {
    bottom: -20px !important;
}

.navi,
.infoi {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.infoi {
    z-index: 10;
}

.bottom-place-text {
    position: absolute;
    bottom: 65px;
    right: 0;
}

.bottom-place-button {
    position: absolute;
    bottom: 15px;
    right: 0;
}

.margin-right-10 {
    margin-right: 10px;
}

.shopping-summary table td:first-child {
    text-align: right;
}

.shopping-summary table td:nth-child(2) {
    padding-left: 0;
}

@media (max-width: 330px) {
    .element {
        background: #cccccc;
    }
}

@media (max-width: 860px) and (min-width: 767px) {
    .element {
        background: #888888;
    }
}

@media (max-width: 897px) and (min-width: 321px) {
    .element {
        background: #222222;
    }
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-1 {
    opacity: 1 !important;
}

.vertical-center-span {
    display: inline-flex;
    align-items: center;
}

.width-225 {
    width: 225px !important;
}

.width-175 {
    width: 175px !important;
}

.dropDownPlace {
    float: right;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(-50%, -50%);
}

.search-style {
    height: 50px !important;
    position: relative !important;
}

.search-style .site-search .search-tools {
    margin-top: 15px !important;
}

.shopping-search {
    padding-right: 35px !important;
    padding-left: 0px !important;
}

.site-search .search-tools .close-search {
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 50px;
}

.input-range__label {
    font-family: Prompt, san-serif !important;
}

.lsb-header {
    background: #606975;
    color: #ffffff;
}

.display-block {
    display: block !important;
}

.profile-pic {
    height: 115px;
    width: 115px;
    border-radius: 50%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
    background-color: white;
}

.profile-pic-sm {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    margin-left: 10px;
    margin-top: 20px;
    /*margin-bottom: 20px;*/
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
    background-color: white;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 10;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
}

.user-avatar:hover .overlay {
    opacity: 0.9;
}

.iconUpload:hover .overlay {
    opacity: 1;
}

.iconUpload {
    color: #48a8e9;
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.fa-upload:hover {
    color: #48a8e9;
    opacity: 1;
}

.over-flow {
    overflow-y: scroll;
    height: 350px;
    left: -20px !important;
    text-align: center !important;
}

.vertical-middle {
    vertical-align: middle !important;
}

.min-width-120 {
    min-width: 120px;
}

.min-width-90 {
    min-width: 90px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

.profile-pic-side {
    height: 60px;
    width: 60px;
    border-radius: 14px;
    margin: 0px 0px 0px 0px;
    border: 1px solid white;
    padding: 1px;
    background: #ffffff;
}

.wrap-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-height: 1.25em;
    /* fallback */
    max-height: 2.5em;
    height: 2.5em;
    display: block;
}

.minHeight {
    height: 150px !important;
}

.productImage {
    width: auto;
    height: 200px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.margin-top-medium {
    margin-top: 08px !important;
}

.place-text-center {
    text-align: center !important;
    left: -45px !important;
}

.dropdown-item {
    cursor: pointer;
}

.dropdown-item:hover {
    color: #0da9ef !important;
}

.separator-line {
    position: relative;
    border-left: 1px solid;
    right: -149px;
    z-index: 2;
    border-color: white;
}

/* padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left); */

/* End of general */

/** Utilities */

.hover-shadow-qs {
    transition: all 0.25s ease-in-out;
}

.hover-shadow-qs:hover {
    -webkit-box-shadow: 1px 1px 2px 2.5px #eee;
    -moz-box-shadow: 1px 1px 2px 2.5px #eee;
    box-shadow: 1px 1px 2px 2.5px #eee;
}

.hover-shadow {
    transition: all 0.25s ease-in-out;
}

.hover-shadow:hover {
    -webkit-box-shadow: 1px 1px 2px 2.5px #eee;
    -moz-box-shadow: 1px 1px 2px 2.5px #eee;
    box-shadow: 1px 1px 2px 2.5px #eee;
}

.img-promotions {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
}

.footer-phone a {
    text-decoration: none;
    color: white;
}

.footer-phone a:hover {
    color: white;
}

.hide {
    display: none !important;
}

.no-margin {
    margin: 0px !important;
}

.sidebar-hover {
    transition: all 0.1s ease-in-out;
}

.sidebar-div-hover :hover .sidebar-hover {
    color: #0da9ef;
    transition: all 0.1s ease-in-out;
}

.sidebar-hover > span:hover {
    color: #0da9ef;
    transition: all 0.1s ease-in-out;
}

.success-label {
    position: relative;
    z-index: 2;
    color: #3c4246;
    /* color:white; */
    bottom: 1px;
    top: 2px;
}

.loadingImage {
    min-height: 75px !important;
    background-position: center;
    background-image: url(../assets/loadding.gif);
    background-repeat: no-repeat;
    background-size: 75px;
}

.loadingImagePH {
    min-height: 75px;
    background-position: center;
    background-image: url(../assets/loaddiing.gif);
    background-repeat: no-repeat;
    background-size: 75px;
}

.loading-enroll {
    min-height: 75px;
    background-position: center;
    background-image: url(../assets/loadding.gif);
    background-repeat: no-repeat;
    background-size: 75px;
}

.loading-image-container {
    width: 75px !important;
    height: 75px !important;
    -webkit-transform: rotateZ(0deg) !important;
    -ms-transform: rotate(0deg) !important;
    transform: rotateZ(0deg) !important;
}

.success-position {
    position: relative;
    right: 30px;
    top: 2px;
}

.no-padding {
    padding: 0px !important;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.top-0 {
    top: 0px !important;
}

.top-40 {
    top: 40px !important;
}

.padding-top-20 {
    padding-top: 20px;
}

.bg-white {
    background-color: #ffffff;
}

.width-300 {
    width: 300px !important;
}

.width-30 {
    width: 30px !important;
}

.profile-Icon {
    display: inline-block;
    border-radius: 60px;
    box-shadow: rgb(136, 136, 136) 0px 0px 2px;
    position: absolute;
    right: 8px;
    padding: 0px 8px;
    background-color: #fff;
    font-size: 12px;
    line-height: 28px;
    cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: none;
}

.slick-slide.slick-active {
    z-index: 999;
}

.profile-label {
    position: absolute;
    right: 0;
    top: 0;
}

.inline-block {
    display: inline-block;
}

.push-backdrop {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 100%, 0.3);
    z-index: 999;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
}

.scroll-to-top-btn {
    border: 0px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    right: 32px !important;
    z-index: 200 !important;
}

.scroll-to-top-btn.visible {
    border: 0px;
    bottom: 40px;
}

.scroll-to-top-btn .fa-arrow-up {
    bottom: 53px;
}

.scroll-to-top-btn.fb-open {
    bottom: 100px;
}

.scroll-to-top-btn.fb-open .fa-arrow-up {
    bottom: 100px;
}

.progress-fixed {
    width: 100%;
    padding-top: 20px;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    background: white;
    transition: 0.5s;
}

.redirect-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.redirect-loading-container-success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 60vh !important;
    width: 100% !important;
}

.progress-inner {
    border-radius: 4px;
    position: absolute;
    left: 15px;
    right: 15px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.progress-point {
    border-radius: 30px;
    position: absolute;
    margin-top: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}

.sub-menu-separator {
    margin-top: 5px;
    margin-bottom: 3px;
    border-top: 1px solid #e1e7ec;
}

.next-btn {
    height: 35px;
    background: #0da9ef;
    border: 0;
    color: #fff;
    width: 100px;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 3px;
    margin-bottom: 10px;
    cursor: pointer;
}

.danger-btn {
    background-color: #ff5252;
    border: 0px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    padding-top: 5px;
    cursor: pointer;
    padding-bottom: 5px;
}

.info-btn {
    background-color: #2ab8f5;
    border: 0px;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    padding-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    padding-bottom: 5px;
}

.primary-btn {
    background-color: #2ab8f5;
    border: 0px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    padding-top: 5px;
    cursor: pointer;
    padding-bottom: 5px;
    margin-top: 5px;
    margin-bottom: px;
}

.primary-btn:hover {
    background-color: #2ab8f5;
    border: 0px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    padding-top: 5px;
    cursor: pointer;
    padding-bottom: 5px;
}

.shortcut {
    background-color: white;
    color: black;
}

.order-heading {
    font-size: 25px;
    border-bottom: 1px solid;
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;
    margin-bottom: 0px;
}

.fa-dollar {
    margin-left: 3.5px !important;
    margin-right: 6.5px !important;
}

.fa-usd {
    color: #2ab8f5;
}

.fa-list-ul {
    color: #2ab8f5;
}

.fa-users {
    color: #2ab8f5;
}

.report-back {
    background-color: transparent;
    border: 1px solid #2ab8f5;
    color: #2ab8f5;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 5px;
    cursor: pointer;
    padding-bottom: 5px;
}

.profileCard {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 300px;
    box-shadow: 0 1px 8px 0 rgba(56, 56, 56, 0.2);
}

.orderBtn {
    text-align: center;
    border: 1px solid #2ab8f5;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.orderBtnM {
    text-align: center;
    border: 1px solid #2ab8f5;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.successContainer {
    width: 80%;
    /* border: 1px solid #CCC; */
    border-radius: 5px;
    padding: 10px;
    margin-right: -25px;
}

.reportPic {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
    border: 1px solid white;
    border-radius: 50%;
}

.report-table-title {
    text-align: left;
}

.report-table-data {
    text-align: left;
}

.back-to-gene {
    margin-left: 20px;
    margin-top: 20px;
}

.report-btn {
    background-color: white;
    color: #2ab8f5;
    border-color: #2ab8f5;
    border-width: 1px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    padding-top: 5px;
    cursor: pointer;
    padding-bottom: 5px;
    margin-bottom: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
}

.report-dropdown {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    padding-top: 5px;
    cursor: pointer;
    padding-bottom: 5px;
    margin-bottom: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
}

.report-facet {
    position: absolute;
    padding-top: 4px;
    padding-left: 4px;
}

.details-table {
}

.details-table > tbody > tr {
    border: 1px solid #ccc;
}

.details-table > tbody > tr > td:first-child {
    border-right: 1px solid #ccc;
}

.report-user-view-table-col {
    padding: 0px;
}

.report-user-view-table > thead > tr {
    border: 1px solid #ccc;
}

.report-user-view-table > tbody > tr {
    border: 1px solid #ccc;
}

.report-user-view-table > tbody > tr > td {
    border-right: 1px solid #ccc;
}

.detail-btn {
    background-color: #2ab8f5;
    border: 0px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.hr-style {
    border: 0;
    height: 1px;
    margin-top: 5px;
    margin-bottom: 15px;
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        #2ab8f5,
        rgba(0, 0, 0, 0)
    );
}

.font-style {
    font-weight: bolder;
}

.report-inline-text {
    display: inline;
    margin: 0px;
}

.inline-text-table {
    display: inline;
    margin: 0px;
}

.dropdown-items {
    cursor: pointer;
    background-color: white;
}

.dropdown-items a:hover {
    background-color: #f5f5f5 !important;
}

.report-select-text {
    margin-top: 4px;
}

.detail-report-user-table > tbody {
    width: 100%;
}

/* .detail-report-user-table>tbody>tr>td {
} */

.detail-report-user-table > tbody > tr > td:nth-child(odd) {
    font-weight: bold;
}

.qs-row .container {
    width: auto;
}

/* .detail-report-user-table > tbody > tr:nth-child(odd){
    background-color: #F2F2F2;
} */

.order-button-report {
    border: 1px solid;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 230px;
    display: block;
    margin: 0 auto;
}

.global-connected-button-left {
    background-color: #f5f5f5;
    border: 1px solid #e1e7ec;
    color: gray;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 5px 47px;
    cursor: pointer;
    max-width: 100% !important;
}

.global-connected-button-middle {
    background-color: #f5f5f5;
    border: 1px solid #e1e7ec;
    color: gray;
    padding: 5px 20px;
    cursor: pointer;
}

.global-connected-button-right {
    background-color: #f5f5f5;
    border: 1px solid #e1e7ec;
    color: gray;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 5px 47px;
    cursor: pointer;
    max-width: 100% !important;
}

.global-connected-button-active {
    background-color: #2ab8f5;
    border: 1px solid #2ab8f5;
    color: white;
}

.next-btn.disabled,
.next-btn:disabled {
    background-color: #e3e3e3;
    border-color: #e3e3e3;
}

.input-spinner {
    position: absolute;
    top: 45px;
    right: 50px;
}

.form-control.alert,
.form-control:disabled {
    border-radius: 44px;
}

/*.page-title h1,
.page-title h2,
.page-title h3 {
     margin-left: 25px;
}*/

.page-title h1 {
    width: 100% !important;
    color: black !important;
    max-width: 100% !important;
    text-align: center;
}

.nav-link {
    cursor: pointer;
}

.circle-75 {
    color: #2bd395;
    padding-top: 25px;
    font-weight: bold;
    font-size: 40px;
}

.switch-shop {
    cursor: pointer;
    font-weight: bold;
    width: auto;
}

.switch-shop.active {
    border-bottom: 1px solid #0da9ef;
}

/** End of utilities */

/** Top Navigation */

.route-wrapper .topbar {
    min-height: 40px;
}

/** End of top navigation */

/** Left Side Navigation */

.hamburger-container {
    width: 74px !important;
}

.main-menu {
    transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

.offmenu {
    transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

.menu-cell {
    width: 100%;
    height: 50px;
    display: table !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    cursor: pointer;
}

.menu-inner-cell {
    display: table-cell;
    vertical-align: middle;
    font-weight: 500;
}

.hello-fa {
    margin-left: 20px;
}

.hello-name {
    font-weight: bold;
    display: inline;
    font-size: 16px;
}

.hello-user {
    margin-left: 10px;
    font-weight: normal;
    display: inline;
    font-size: 16px;
}

.side-bar-login {
    margin-left: 20px;
    font-size: 16px;
}

.active-sidebar {
    cursor: pointer;
    color: #0da9ef;
}

.caret-container,
.menu-inner-cell {
    display: table-cell;
    vertical-align: middle;
}

.caret-container {
    text-align: center !important;
}

.caret-cell,
.menu-cell {
    height: 50px;
    display: table;
}

.menu-cell {
    width: 100%;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.12);
    cursor: pointer;
}

.caret-cell {
    border-left: 1px solid hsla(0, 0%, 100%, 0.12);
    width: 50px;
    float: right;
}

.menu-container,
#menu-container {
    background-color: #374250;
    -webkit-overflow-scrolling: touch;
    color: white;
    width: 250px;
    margin-top: 0px;
}

.topbar {
    background-color: #f5f5f5;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    top: 0;
    bottom: 0;
    z-index: 800 !important;
    padding-right: 15px;
    padding-left: 15px;
}

.slick-prev {
    /* left: 15px !important; */
    width: 20px;
    height: 42px !important;
    padding-right: 30px !important;
    display: block;
    /* height: 100% !important; */
    /* padding-left: 20px !important; */
    left: 0px !important;
    margin-left: 15px !important;
}

.slick-prev::before {
    left: 15px !important;
    font-size: 40px !important;
}

.slick-next {
    right: 0px !important;
    width: 20px;
    height: 42px !important;
    display: block;
    /* height: 100% !important; */
    padding-right: 30px !important;
    padding-left: 20px !important;
    margin-right: 15px !important;
}

.slick-next::before {
    right: 15px !important;
    font-size: 40px !important;
}

.route-wrapper {
    z-index: 2;
    background-color: rgb(255, 255, 255);
    min-height: 100vh;
}

/** End of left side navigation */

/** Login */

.lang-currency-switcher-wrap .dropdown-menu {
    left: -35px;
    padding-bottom: 12px;
    overflow: hidden;
    width: 200px;
    z-index: 1001;
    top: 30px;
    display: block;
}

/** End of login */

/** Header */

.navbar {
    min-height: 70px;
    height: 77px;
    z-index: 10 !important;
    /* -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0); */
}

.navbar.navbar-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.topbar {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

.name-header {
    font-size: 14px !important;
    color: #0da9ef !important;
    padding-top: 10px !important;
}

.download-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.offcanvas-toggle {
    border-right: 0px;
    position: relative !important;
    width: 50px;
    height: 76px;
}

.offcanvas-toggle {
    width: 74px;
    margin-right: 20px;
}

/*** New Menu Hamburger Icon ***/

.block-nav-menu-icon {
    width: 74px;
    height: 80px;
    position: relative !important;
    border-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.block-nav-menu-icon .line,
.block-nav-menu-icon .line:before,
.block-nav-menu-icon .line:after {
    display: block;
    width: 16px;
    height: 2px;
    background: #7a828b;
    border-radius: 1px;
    position: relative;
    transition: 0.2s;
}

.block-nav-menu-icon:hover .line,
.block-nav-menu-icon:hover .line:before,
.block-nav-menu-icon:hover .line:after {
    background: #19a5e9;
}

.block-nav-menu-icon .nav-menu-icon {
    display: block;
    margin-top: 3px;
}

.block-nav-menu-icon .line:before,
.block-nav-menu-icon .line:after {
    content: '';
    position: absolute;
}

.block-nav-menu-icon .line:before {
    top: -5px;
}

.block-nav-menu-icon .line:after {
    bottom: -5px;
}

/*** END New Menu Hamburger Icon ***/

.box-center {
    text-align: center;
}

.box-center-inner {
    margin: 0 auto;
}

.navbar.navbar-stuck {
    width: 100%;
    z-index: 999 !important;
}

.site-search {
    left: 0;
    top: 0px;
}

.site-search > input {
    padding-left: 0px;
    padding-right: 0px;
}

.route-wrapper.navbar {
    min-height: 78px;
}

.navbar.navbar-stuck {
    right: 1em;
    background-color: rgba(255, 255, 255, 1);
    /*box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .12), inset 0 0px 0 0 #dadce0;*/
    box-shadow: none;
    height: 76px;
    max-height: 76px;
}

.site-branding,
.site-language {
    height: 77px;
    overflow-y: hidden;
}

.site-language .inner {
    position: absolute;
    right: 15px;
    top: 28px;
    font-family: 'Prompt', sans-serif;
}

@media (min-width: 376px) {
    .site-language .inner {
        right: 22px;
    }
}

.lang-currency-switcher-wrap {
    /* margin-right: 20px; */
    margin-left: 0px;
}

.lang-currency-switcher-wrap .lang-currency-switcher.login {
    margin-top: -7px;
}

.lang-currency-switcher-wrap .lang-currency-switcher > .language {
    width: 40px;
    margin-top: -5px;
}

.lang-currency-switcher-wrap .lang-currency-switcher > .currency {
    margin-top: 0px;
}

.language-title {
    margin-left: 20px;
    margin-bottom: 10px;
}

/* Home */

.remove-from-cart {
    color: #ff5252 !important;
    cursor: pointer;
}

.clear-cart-btn:hover {
    color: white !important;
}

.warning-checkout {
    color: red;
}

.viewprod {
    height: 40px !important;
    font-weight: bold;
}

.logo-product {
    height: 70px;
}

.under-logo-title {
    font-size: 30px;
    font-weight: bold;
}

.single-product-case {
    border: 1px solid #e1e7ec;
    border-radius: 5px;
}

.single-product-case > img {
    border-radius: 5px;
    height: auto;
}

.single-product-title {
    font-size: 30px;
    color: black;
}

.single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
}

.service-img {
    /*border: 1px solid #e1e7ec;*/
    width: 100px;
    /*border-radius: 50px;*/
    margin: 0px auto;
    margin-top: 10px;
}

.uniBanner {
    background: url(../assets/bg_test.jpg) 20% 0% / cover !important;
}

.omegaBanner {
    background: url(../assets/omega_bg.jpg) 47% 0% / cover !important;
}

.biosBanner {
    background: url(../assets/bg_bios.png) 47% 0% / cover !important;
}

.hipster-dude {
    background-image: url('http://themes.rokaux.com/unishop/v1.3/img/banners/home01.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 450px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.limited-offer {
    color: #ff5252 !important;
    font-weight: 500;
    margin-bottom: 0px;
}

.margin-3 {
    margin: 3px !important;
}

.offer-left {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.pop-brands {
    background-color: #f5f5f5;
}

.all-products {
    color: gray !important;
    background-color: white !important;
    border: 1px solid #e1e7ec !important;
    border-radius: 20px;
    height: 40px;
    min-width: 150px;
    font-weight: 500;
    cursor: pointer;
    padding: 5px 20px;
}

.product-card {
    height: 100%;
}

.suggested-slider .product-card {
    height: auto;
}

.suggested-slider .product-card .product-thumb {
    width: 100%;
    /* height: 200px; */
}

.suggested-slider-mobile .product-card {
    height: auto;
}

.suggested-slider-mobile .product-card .product-thumb {
    width: 100%;
    /* height: 200px; */
}

/* hiding all bullets by default */

.slick-slider.suggested-slider
    .slick-dots
    li
    + li
    + li
    + li
    + li
    + li
    + li
    + li {
    display: none !important;
}

.slick-slider.suggested-slider-mobile .slick-dots li + li + li + li + li {
    display: none !important;
}

/* only displaying the active bullets and the 2 bullets next to it */

/* .slick-slider.suggested-slider .slick-dots li.slick-active,
.slick-slider.suggested-slider .slick-dots li.slick-active + li,
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li,
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li + li
 {
    display: inline-block!important;
} */

/* displaying the last three bullets when slick-active class isn't applied to any li before them  */

/* .slick-slider.suggested-slider .slick-dots li:nth-last-child(1),
.slick-slider.suggested-slider .slick-dots li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li:nth-last-child(4) {
    display: inline-block!important;
} */

/* hiding the last three bullets if slick-active exist before them */

/* .slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(1),
.slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(4) {
    display: none!important;
} */

/* specific conditions to always display the last three bullets */

/* .slick-slider.suggested-slider .slick-dots li.slick-active + li + li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li:nth-last-child(1),
.slick-slider.suggested-slider .slick-dots li.slick-active + li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li.slick-active + li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li.slick-active + li:nth-last-child(1){
    display: inline-block!important;
} */

.product-card .product-thumb {
    margin-bottom: auto;
    margin: auto;
}

.product-image {
    display: flex;
    margin: auto;
    height: 220px;
    align-items: center;
}

.product-card .product-thumb img {
    width: auto;
    margin: auto;
}

.productImage {
    width: auto;
    max-width: 200px;
    height: auto;
    max-height: 200px;
}

.cart-img {
    width: 160px;
    text-align: center;
    padding: 0px;
    height: 115px;
    display: grid;
    align-items: center;
}

.cart-img > img {
    width: auto;
    height: auto;
    max-height: 115px;
    max-width: 135px;
    justify-self: center;
}

.small-right-product {
    vertical-align: middle;
    text-align: center;
    justify-content: center;
    width: 100%;
}

.small-right-product > img {
    height: auto;
    max-height: 80px;
}

/*
.product-thumb {
    height: 220px;
}*/

.top-cat-cell hr {
    margin-top: 0px;
}

.top-cat-cell > .container-fluid {
    height: 209px;
}

.top-cat-cell > .container-fluid > .row {
    height: 100%;
}

.border-right {
    width: 100%;
}

.left-prod-cat {
    margin: auto;
    vertical-align: middle;
    height: auto;
    max-height: 200px;
    width: auto;
    max-width: 200px;
}

.left-prod-cat img {
    height: auto;
    max-height: 179px;
}

.padding-top-10 {
    padding-top: 12px;
}

.right-prod-cat {
    width: 125px;
    margin: auto;
    width: 50%;
    display: flex;
    align-items: center;
}

.right-prod-cat > img {
    border-radius: 7px;
    max-width: 94px;
    max-height: 83px;
}

.top-cat-cell {
    border-radius: 7px;
    border: 1px solid #e1e7ec;
    margin-bottom: 20px;
}

.view-product-btn {
    width: 50% !important;
}

.left-prod-cat > img {
    border-radius: 7px;
}

.cat-shop {
    font-weight: 500;
}

.input-range__track--active {
    background-color: #9da9b9 !important;
    height: 0.4rem !important;
}

.input-range__slider {
    height: 95px;
    background: white !important;
    border: 4px solid #9da9b9 !important;
    width: 1.6rem !important;
    height: 1.6rem !important;
    margin-top: -1rem !important;
    margin-left: -0.8rem !important;
}

.input-range__label-container {
    line-height: 7rem !important;
    font-size: 1.25rem !important;
    font-weight: 300 !important;
}

.range__slider-range::-moz-range-thumb {
    width: 35px !important;
    height: 35px !important;
    background: #4caf50;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
}

.range-slider-container {
    margin-right: 20px;
    margin-left: 20px;
}

.slick-slide {
    background-size: cover !important;
    /* height: 700px !important; */
    background-position: top left !important;
}

.shopping-cart .product-button {
    padding-bottom: 8px;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

@media screen and (max-width: 1440px) {
    .slick-slide {
        background-size: cover !important;
        background-position: center center !important;
        /* height: 480px !important; */
    }
}

@media screen and (max-width: 1280px) {
    /* .slick-slide {
        height: 340px !important;
    } */
}

@media screen and (max-width: 1024px) and (max-height: 800px) {
    .slick-slide {
        /* height: 350px !important; */
    }
}

@media screen and (max-width: 1024px) and (min-height: 1365px) {
    .slick-slide {
        /* height: 820px !important; */
    }
}

@media screen and (max-width: 960px) {
    .slick-slide {
        /* height: 620px !important; */
    }

    .product-info {
        padding-left: 0px !important;
    }
}

@media screen and (max-width: 414px) {
    .slick-slide {
        background-size: cover !important;
        background-position: top left !important;
        /* height: 330px !important; */
    }
}

@media screen and (max-width: 375px) {
    .slick-slide {
        background-size: cover !important;
        background-position: top left !important;
        /* height: 305px !important; */
    }
}

@media screen and (max-width: 320px) {
    .slick-slide {
        background-size: cover !important;
        background-position: top left !important;
        /* height: 260px !important; */
    }
}

.slides-spinner {
    width: 100%;
    height: 580px;
}

.gallary-slides {
    width: 100%;
    height: 500px;
}

.img-container {
    background-image: url(../assets/main-bg.jpg);
    width: 100%;
    height: 580px;
    background-position: center;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.sliderImg {
    width: auto;
    height: 315px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 27px;
}

.sliderImgBios {
    width: auto;
    height: 220px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 55px;
}

.sliderImgUni {
    width: auto;
    height: 220px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
}

.title-pos {
    position: relative;
    top: 80px;
}

.label-pos {
    position: relative;
    top: 60px;
}

.button-pos {
    position: relative;
    top: 90px;
}

.slider-title {
    font-size: 25px;
}

.slider-label {
    font-family: Prompt, sans-serif;
}

.slider-button-label {
    text-align: center;
    justify-content: center;
    margin-bottom: 0;
    background: linear-gradient(to bottom right, rgb(58, 129, 88), #92d153);
    padding: 10px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
}

.slider-button-label-2 {
    text-align: center;
    justify-content: center;
    margin-bottom: 0;
    background: linear-gradient(to bottom right, #d64c4e, rgb(69, 50, 119));
    padding: 10px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
}

/* .gallary-img{
    display: flex;
    flex: 0.5;
    justify-content: flex-start;
    align-items: center;
}
.gallary-btn{
    flex-direction: column;
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: flex-start;
}*/

.height100 {
    height: 100%;
}

.gallary-cells {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.gallary-cell-bottom-image {
    max-height: 270px !important;
}

.info-button {
    font-size: 14px;
    padding-left: 0px;
}

.info-button > img {
    border: 1px solid #e1e7ec;
    border-radius: 16px;
    width: 33px;
    height: 33px;
    color: gray;
    padding: 7px;
    position: inherit;
    /* right: calc(50% + 65px); */
}

.info-button-detail > img {
    border: 1px solid #e1e7ec;
    border-radius: 16px;
    width: 32px;
    height: 32px;
    color: gray;
    padding: 7px;
}

.qtyplusminusbtn {
    border: none;
    background-color: #e3e8ed;
    cursor: pointer;
    width: 44px;
    height: 48px;
    overflow: hidden;
    padding: 0px 16px;
    /*position: relative;*/
}

/*.qtyplusminusbtn:hover{
    background-color: #48A8E9;
}*/

.borderRight {
    border-right: 1px solid #e1e7ec;
    border-radius: 0px !important;
}

.borderLeft {
    border-left: 1px solid #e1e7ec;
    border-radius: 0px !important;
}

.qtyInput {
    border-width: 1px;
    border-style: solid;
    border-color: #e1e7ec;
    border-radius: 5px;
    min-width: 130px;
}

.singleProduct .qtyInput {
    margin-right: 0px;
}

.qtyInput .form-control-number-new.x {
    border-radius: 5px;
    text-align: center;
}

.form-control-number-new.apple {
    -webkit-text-fill-color: #606975 !important;
    opacity: 1 !important;
    color: #606975 !important;
}

.form-control-number-new {
    width: 50px;
    height: 44px;
    /*margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 5px 3px;
    border: 1px solid #dbe2e8;
    border-radius: 22px;*/
    border: none;
    overflow: hidden;
    background-color: #fff;
    color: #606975;
    font-family: Prompt, sans-serif;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.shopping-cart .qtyInput .form-control-number-new {
    border-radius: 5px;
}

/* List for Shop Profile */

.container-product-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 30px;
    padding: 8px 0 8px 0;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    box-shadow: 7px 8px 12px -12px rgba(47, 56, 68, 0.2);
}

/* Qty Input for Shop Profile & Auto-ship */

.qty-input-product-card button {
    height: 47px;
    width: 32%;
    max-width: 40px;
    cursor: pointer;
    border-color: #e1e7ec;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 0px;
    border: 1px solid #e1e7ec;
    background-color: transparent;
    vertical-align: bottom;
    padding: 0px;
}

.qty-input-product-card button:hover {
    border-color: #48a8e9;
}

.qty-input-product-card input {
    width: 32%;
    height: 47px;
    max-width: 60px;
    padding: 0;
    border: 1px solid #dbe2e8;
    border-radius: 0px;
    border-left: 0px;
    border-right: 0px;
    background-color: #fff;
    color: #606975;
    font-family: Prompt, sans-serif;
    font-size: 16px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    box-shadow: 0px 0px 0px;
    vertical-align: bottom;
    text-align: center;
}

.qty-input-product-card input:hover {
    border: 1px solid #48a8e9;
}

.qty-input-product-card input:focus,
textarea:focus,
select:focus {
    outline: none;
    border: 1px solid #48a8e9;
}

.qty-input-product-card button > i {
    color: #303a46;
}

.qty-input-product-card span:first-child button {
    border-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.qty-input-product-card span:last-child button {
    border-radius: 0px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

/* End -> Qty Input for Shop Profile */

.QtyDetail button {
    position: relative;
    /* top: -1px; */
    height: 40px;
    /* width: 40px; */
    width: 32%;
    max-width: 40px;
    border-radius: 0px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    cursor: pointer;
    border-color: #e1e7ec;
    transition: all 0.3s ease;
    background-color: transparent;
    box-shadow: 0px 0px 0px;
    border: 1px solid #e1e7ec;
}

.QtyDetail button:hover {
    border-color: #48a8e9;
}

.QtyDetail input {
    border-radius: 0px;
    height: 40px;
    border-left: 0px;
    border-right: 0px;
    padding-bottom: 5px;
}

.QtyDetail button > i {
    position: relative;
    right: 5px;
    color: #303a46;
}

.QtyDetail span:first-child button {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.QtyDetail span:last-child button {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.plus-minus {
    color: #424749 !important;
    background-color: white !important;
    border: 1px solid #424749 !important;
    border-radius: 16px;
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.input-detail {
    color: #757777 !important;
    background-color: white !important;
    border: 1px solid #757777 !important;
    border-radius: 5px;
    height: 30px;
    width: 40px;
    text-align: center;
}

.detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.add-to-cart {
    color: #0da9ef !important;
    background-color: white !important;
    border: 1px solid #0da9ef !important;
    border-radius: 16px;
    padding: 5px 15px;
    min-width: 120px;
    margin-left: 10px;
    cursor: pointer;
}

.added-to-cart {
    color: white !important;
    background-color: #0da9ef !important;
    border: 1px solid white !important;
    border-radius: 16px;
    padding: 6px 15px;
    min-width: 120px;
    margin-left: 10px;
    cursor: pointer;
}

.added-to-cart-2 {
    color: white !important;
    background-color: #0da9ef !important;
    border: 1px solid white !important;
    border-radius: 16px;
    padding: 5px 15px 3px 15px;
    min-width: 120px;
    margin-left: 10px;
    cursor: pointer;
}

.out-of-stock {
    color: gray !important;
    background-color: white !important;
    border: 1px solid #ccc !important;
    border-radius: 16px;
    padding: 5px 15px 3px 15px;
    margin-left: 10px;
    min-width: 120px;
}

.qs-row-active {
    border: 1px solid #0da9ef !important;
    border-radius: 5px;
    margin-right: 30px;
    padding-bottom: 3px;
    padding-top: 3px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    box-shadow: 7px 8px 20px -10px rgba(47, 56, 68, 0.22);
}

.qs-row {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 30px;
    padding-bottom: 3px;
    padding-top: 3px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    box-shadow: 7px 8px 20px -10px rgba(47, 56, 68, 0.22);
}

.qs-title {
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
}

.qs-add-to-cart {
    color: #0da9ef !important;
    background-color: white !important;
    border: 1px solid #0da9ef !important;
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
    font-size: 12px;
    padding: 5px 8px;
    position: relative;
    right: 15px;
}

.qs-added-to-cart {
    color: white !important;
    background-color: #0da9ef !important;
    border: 1px solid white !important;
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
    font-size: 12px;
    padding: 5px 20px;
    position: relative;
    right: 15px;
}

.qs-out-of-stock {
    color: gray !important;
    background-color: white !important;
    border: 1px solid #ccc !important;
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
    font-size: 12px;
    margin-right: 15px !important;
    padding: 5px 10px 5px 10px;
    position: relative;
    right: 15px;
}

@media (orientation: portrait) and (max-width: 320px) {
    .qs-added-to-cart {
        padding: 5px 2px;
        position: relative;
        right: 15px;
        font-size: 10px;
    }

    .qs-add-to-cart {
        padding: 5px 8px;
        position: relative;
        right: 15px;
        font-size: 10px;
    }

    .qs-out-of-stock {
        right: 15px;
        padding: 5px;
        width: 100%;
        font-size: 10px;
    }
}

@media (orientation: landscape) and (max-width: 568px) {
    .qs-added-to-cart {
        padding: 5px 15px;
        position: relative;
        right: 15px;
    }

    .qs-add-to-cart {
        padding: 5px 10px;
        position: relative;
        right: 15px;
        font-size: 10px;
    }

    .qs-out-of-stock {
        right: 15px;
        padding: 5px 8px;
        font-size: 10px;
    }
}

@media (orientation: portrait) and (min-width: 321px) and (max-width: 375px) {
    .qs-added-to-cart {
        padding: 5px 15px;
        right: 15px;
        width: 100%;
        position: relative;
        font-size: 12px;
    }

    .qs-add-to-cart {
        padding: 5px 8px;
        right: 15px;
        width: 100%;
        position: relative;
    }

    .qs-out-of-stock {
        padding: 5px;
        right: 15px;
        width: 100%;
        font-size: 12px;
        position: relative;
    }
}

@media (orientation: portrait) and (min-width: 376px) and (max-width: 414px) {
    .qs-added-to-cart {
        padding: 5px 15px;
        right: 15px;
        width: 100%;
        position: relative;
    }

    .qs-add-to-cart {
        padding: 5px 8px;
        right: 15px;
        width: 100%;
        position: relative;
    }

    .qs-out-of-stock {
        padding: 5px 8px;
        right: 15px;
        width: 100%;
        font-size: 10px;
        position: relative;
    }
}

@media (orientation: portrait) and (min-width: 415px) and (max-width: 768px) {
    .qs-add-to-cart {
        padding: 5px 15px;
        position: relative;
        right: 15px;
    }

    .qs-added-to-cart {
        padding: 5px 28px;
        position: relative;
        right: 15px;
    }

    .qs-out-of-stock {
        right: 15px;
        padding: 5px 12px;
        position: relative;
    }
}

@media (orientation: landscape) and (min-width: 569px) and (max-width: 667px) {
    .qs-add-to-cart {
        padding: 5px 15px;
        position: relative;
        right: 15px;
    }

    .qs-added-to-cart {
        padding: 5px 28px;
        position: relative;
        right: 15px;
    }

    .qs-out-of-stock {
        right: -5px;
        padding: 5px 15px;
        width: auto;
        position: relative;
    }
}

@media (orientation: landscape) and (min-width: 668px) and (max-width: 736px) {
    .qs-add-to-cart {
        padding: 5px 15px;
        position: relative;
        right: 15px;
    }

    .qs-added-to-cart {
        padding: 5px 28px;
        position: relative;
        right: 15px;
    }

    .qs-out-of-stock {
        right: 15px;
        padding: 5px 12px;
        position: relative;
    }
}

@media (orientation: landscape) and (min-width: 737px) and (max-width: 768px) {
    .qs-add-to-cart {
        padding: 5px 15px;
        position: relative;
        right: 15px;
    }

    .qs-added-to-cart {
        padding: 5px 28px;
        position: relative;
        right: 15px;
    }

    .qs-out-of-stock {
        right: 15px;
        padding: 5px 12px;
        position: relative;
    }
}

@media (orientation: landscape) and (min-width: 769px) and (max-width: 812px) {
    .qs-add-to-cart {
        padding: 5px 15px;
        position: relative;
        right: 15px;
    }

    .qs-added-to-cart {
        padding: 5px 28px;
        position: relative;
        right: 15px;
    }

    .qs-out-of-stock {
        right: 15px;
        padding: 5px 12px;
        position: relative;
    }
}

@media only screen and (min-width: 813px) and (max-width: 1024px) {
    .qs-add-to-cart {
        padding: 5px 15px;
        position: relative;
        right: 15px;
    }

    .qs-added-to-cart {
        padding: 5px 28px;
        position: relative;
        right: 15px;
    }

    .qs-out-of-stock {
        right: 15px;
        padding: 5px 12px;
        position: relative;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .qs-add-to-cart {
        padding: 5px 15px;
        position: relative;
        right: 15px;
    }

    .qs-added-to-cart {
        padding: 5px 28px;
        position: relative;
        right: 15px;
    }

    .qs-out-of-stock {
        right: 15px;
        padding: 5px 12px;
        position: relative;
    }
}

.expand-row {
    height: 20px;
    margin-left: 20px;
    font-size: 20px;
    color: green;
    width: 100%;
    height: 40px;
    cursor: pointer;
}

.view-ba-detail {
    font-weight: 400;
}

.expand-btn {
    padding-bottom: 10px;
    width: 100%;
}

.alpha-down {
    margin: 0px;
    display: inline;
    margin-left: 10px;
    color: black;
    font-size: 17px;
}

.qs-quicknav {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 60px;
    background-color: rgba(255, 255, 255, 0.6);
    height: calc(100% - 77px);
    z-index: 1;
    padding-top: 30px;
}

.hidden-remove {
    margin-top: 10px;
}

.qsoption {
    text-align: center;
    cursor: pointer;
}

.qsoption:hover {
    color: #0da9ef;
}

.header-cart {
    margin-top: 12px;
    width: 18px;
    margin-right: 8px;
}

.icon-autoship {
    font-size: 16px;
    margin-top: 13px;
    margin-right: 8px;
}

.basket-spin {
    color: #0da9ef;
}

.cart-div {
    /* margin-top: 0px!important; */
}

.cart-info {
}

.cart-text {
    font-size: 12px;
    text-align: left;
    line-height: 13px;
}

.blue-man {
    color: #0da9ef;
}

.new-profile-login {
    background: white;
    position: fixed;
    right: 20px;
    top: 63px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    -webkit-transition-property: none;
    -moz-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
    z-index: 1000;
}

.hovbox-name {
    margin: 0px;
}

.hovbox-rank {
    color: #9da9b9;
}

.lessPadding {
    padding: 3px !important;
}

.after-login-hover-menu {
    width: 215px;
    background: white;
    position: fixed;
    /*right: 20px;*/
    top: 63px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    z-index: 1000;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    z-index: 850 !important;
}

.new-login {
    width: 250px;
    background: white;
    position: fixed;
    right: 20px;
    top: 63px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    -webkit-transition-property: none;
    -moz-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
    z-index: 999;
}

.new-login-ie {
    top: 103px !important;
}

.success-img-ie {
    padding-top: 20px;
    margin: 0 auto;
    max-width: 130px !important;
}

.new-login::selection {
    display: none;
}

.no-blue-highlight:active {
    color: inherit !important;
    background-color: white !important;
}

.no-blue-highlight:hover {
    border-radius: 5px;
    color: inherit !important;
    background-color: white !important;
}

/** End of Header */

/** Enroll */

.referral-link {
    text-align: center;
    margin-top: 10px;
    border: 1px solid #e1e7ec;
    width: 220px;
}

.generated-link {
    background-color: white;
    border: 1px solid #e1e7ec;
    color: #0da9ef;
}

.generated-link:hover {
    background-color: #f2f2f2;
}

.enrollment-page {
    min-height: 50vh;
}

.enroll-btns {
    transition: all 1000ms;
    width: 220px;
    height: 40px;
    font-weight: 500;
}

.quick-shop {
    margin-bottom: 80px;
}

.quick-shop .container {
    width: 100%;
}

.quick-shop .widget-categories > ul > li {
    cursor: pointer;
}

.quick-shop .widget-categories > ul > li > a {
    width: 100%;
    height: auto;
    padding: 15px;
}

.product-detail .widget-categories > ul > li > a:hover,
.quick-shop .widget-categories > ul > li > a:hover {
    background: #f2f2f2;
}

.quick-shop .widget-categories > ul > li > ul {
    margin-left: 40px;
}

.quick-shop .widget-categories > ul > li::before {
    content: unset !important;
    position: absolute;
    left: 0px;
}

.quick-shop .widget-categories > ul > li > ul > li .row {
    margin-top: 20px;
}

.quick-shop .btn-success,
.quick-shop .btn-success:hover,
.product-detail .btn-success,
.product-detail .btn-success:hover {
    background: #0da9ef !important;
}

.quick-shop .input-group .input-group-btn button,
.product-detail .input-group .input-group-btn button {
    font-size: 12px;
    color: white;
}

.quick-shop .btn-danger,
.product-detail .btn-danger {
    background: #c1c1c1 !important;
    border: 1px solid #c1c1c1;
}

.enroll-form {
    min-height: 400px;
}

.enroll-form .quick-shop {
    margin-top: 60px;
}

.quick-shop .input-group-btn {
    margin-top: 20px;
}

.quick-shop .input-group .input-group-addon,
.input-group .input-group-btn {
    position: unset;
}

.product-detail .input-group .input-group-btn {
    padding-top: 15px;
    color: #ffffff;
}

.product-detail .input-group .input-group-btn button {
    background: #000;
}

.widget-categories ul > li > ul > li .row .col-8 > a {
    border-left: 0px;
}

.widget-categories ul > li {
    float: left;
}

.page-header h6 {
    font-size: 28px;
    padding-top: 20px;
    padding-bottom: 40px;
}

.summary {
    list-style: none;
    padding-top: 20px;
}

.summary span {
    /* color: rgb(157, 157, 157); */
}

.summary p {
    font-weight: bold;
    font-size: 20px;
}

.container.container-summary {
    width: 1200px;
}

.widget-categories ul > li::before,
.widget-links ul > li::before {
    content: unset;
}

.product-detail {
    margin-top: 25px;
}

.product-detail .product-item .tag {
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    font-size: 12px;
    margin: 0px;
    height: 14px;
    line-height: 14px;
    display: inline-block !important;
}

.product-detail .product-item .tag.unavailable {
    background-color: #bb6bd9;
    color: #ffffff !important;
}

.th-form-login {
    height: 275px;
}

@media only screen and (max-width: 576px) {
    .mobile-center {
        text-align: center;
    }
}

/** End of enroll */

/** Genealogy */

.gene-result-container {
    border: 1px solid #e4e4e4;
    padding: 20px;
    margin-bottom: 10px;
    padding-top: 10px;
    margin-top: 10px;
}

.no-match {
    color: red;
}

.no-bookmark {
    color: red;
    margin-bottom: 15px;
}

.gene-close-search {
    cursor: pointer;
    z-index: 2;
    position: absolute;
    right: 3px;
    top: 0;
    background: #ff5152;
    color: white;
    border: 0px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.genealogy-search-mobile-modal {
    top: 40%;
    right: 0;
    left: 0;
    position: absolute;
}

.genealogy-search-mobile-modal.show-result {
    top: 0% !important;
}

.gene-result-title {
    margin-bottom: 10px;
    font-size: 20px;
}

.gene-result-item {
    text-decoration: none;
}

.time-gene {
    font-size: 14px;
    margin-left: 10px;
}

.new-left-cell {
    display: block;
    flex-direction: row;
}

.new-left-inner {
    overflow-x: hidden;
    padding-top: 14px;
}

.new-left-name {
    margin-left: 4px;
    display: block;
    align-items: center;
}

.new-left-name:hover {
    color: #57bafc;
}

.new-left-name::-webkit-scrollbar {
    width: 0px;
    /* remove scrollbar space */
    background: transparent;
    display: none;
    /* optional: just make scrollbar invisible */
}

.new-left-name::-webkit-scrollbar-thumb {
    background: transparent;
    display: none;
}

.new-left-arrow {
    display: block;
    align-items: center;
    padding-right: 5px;
    overflow: hidden;
}

.address-table {
    width: 100%;
    margin: auto;
    border: 0px solid #e1e7ec;
}

.address-table > tbody > tr:hover {
    background: rgba(236, 236, 236, 0.425);
}

.address-table > thead {
    border-top: 1px solid #e1e7ec;
    border-bottom: 2px solid #e1e7ec;
    background-color: white;
    color: #606975;
    padding-top: 10px;
    padding-bottom: 10px;
}

.address-table > tbody > tr {
    border-bottom: 1px solid #e1e7ec;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-top: 0px solid #ccc;
}

.address-table > thead > tr > th {
    width: 30%;
    text-align: center;
    color: #2f9ce6;
    font-size: 15px;
    padding: 5px;
}

.address-table > tbody > tr td {
    text-align: left;
    padding: 8px 0 8px 8px;
}

.address-table > tbody > tr td > i {
    cursor: pointer;
    padding: 15px;
}

.address-table .name,
.address-table .address {
    text-align: left;
}

.address-table .edit {
    margin: 0 auto;
}

.address-table .delete {
    width: 12%;
}

.vl {
    border-left: 1px solid #e1e7ec;
    height: 70%;
    vertical-align: middle;
    position: absolute;
    left: 40%;
    top: 15%;
}

.lsb-table {
    width: 100%;
    margin: auto;
    border: 1px solid #e1e7ec;
    border-radius: 5px;
    perspective: 1px;
    border-collapse: separate;
}

.lsb-table > thead {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: white;
    color: #606975;
    padding-top: 10px;
    padding-bottom: 10px;
}

.lsb-table > tbody > tr {
    border-bottom: 1px solid #ccc;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-top: 0px solid #ccc;
}

.lsb-table > tbody > tr > td {
    border-right: 1px solid #ccc;
    text-align: center;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-top: 0px solid #ccc;
    white-space: nowrap;
}

.lsb-table > tbody > tr > td:first-child {
    color: #49a8e9;
    font-weight: bold;
    text-align: center;
}

.lsb-table > tbody > tr > td:last-child {
    text-align: center;
}

.lsb-data {
    width: 100%;
    margin: auto;
    border: 0px solid #e1e7ec;
    border-collapse: initial;
    border-radius: 7px;
    /* border-spacing: 0px 10px; */
}

.lsb-data > thead > tr {
    color: #ffffff;
    -webkit-box-shadow: 0px 5px 20px -10px #a3a3a3 !important;
    -moz-box-shadow: 0px 5px 20px -10px #a3a3a3 !important;
    box-shadow: 0px 5px 20px -10px #a3a3a3 !important;
    -webkit-appearance: none !important;
}

.order-detail-table {
    width: 100%;
    margin: auto;
    border: 1px solid #e1e7ec;
}

.order-detail-table > tbody > tr:hover {
    background: rgba(236, 236, 236, 0.425);
}

.order-detail-table > thead {
    border-top: 1px solid #ccc;
    border-bottom: 2px solid #ccc;
    background-color: white;
    color: #606975;
    padding-top: 10px;
    padding-bottom: 10px;
}

.order-detail-table > tbody > tr {
    border-bottom: 1px solid #ccc;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-top: 0px solid #ccc;
}

.order-detail-table > tbody > tr > td {
    border-right: 1px solid #ccc;
    text-align: center;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-top: 0px solid #ccc;
    white-space: nowrap;
}

.order-detail-table > tbody > tr > td:first-child {
    /* cursor: pointer; */
    color: #0da9ef;
    font-weight: bold;
    padding-top: 10px !important;
}

.order-detail-table > thead > tr > th {
    width: 20%;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-bottom: 0.75rem;
}

.order-detail-table > tbody:hover td[rowspan] {
    background: rgba(236, 236, 236, 0.425);
}

.kpi-detail-table {
    width: 100%;
    margin: auto;
    border: 1px solid #e1e7ec;
}

.kpi-detail-table > tbody > tr:hover {
    background: rgba(236, 236, 236, 0.425);
}

.kpi-detail-table > thead {
    border-top: 1px solid #ccc;
    border-bottom: 2px solid #ccc;
    background-color: white;
    color: #606975;
    padding-top: 10px;
    padding-bottom: 10px;
}

.kpi-detail-table > tbody > tr {
    border-bottom: 1px solid #ccc;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-top: 0px solid #ccc;
}

.kpi-detail-table > tbody > tr > td {
    border-right: 1px solid #ccc;
    text-align: center;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-top: 0px solid #ccc;
    white-space: nowrap;
}

.kpi-detail-table > thead > tr > th {
    width: 20%;
    text-align: center;
}

.kpi-detail-table > tbody:hover td[rowspan] {
    background: rgba(236, 236, 236, 0.425);
}

.kpi-special-table {
    width: 100%;
    margin: auto;
    border: 1px solid #e1e7ec;
}

.kpi-special-table > tbody > tr:hover {
    background: rgba(236, 236, 236, 0.425);
}

.kpi-special-table > thead {
    border-top: 1px solid #ccc;
    border-bottom: 2px solid #ccc;
    background-color: white;
    color: #606975;
    padding-top: 10px;
    padding-bottom: 10px;
}

.kpi-special-table > tbody > tr {
    border-bottom: 1px solid #ccc;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-top: 0px solid #ccc;
}

.kpi-special-table > tbody > tr > td {
    border-right: 1px solid #ccc;
    text-align: center;
    border-right: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-top: 0px solid #ccc;
    white-space: nowrap;
}

.kpi-special-table > tbody > tr > td:first-child {
    /* cursor: pointer; */
    color: #0da9ef;
    font-weight: bold;
}

.kpi-special-table > thead > tr > th {
    width: 20%;
    text-align: center;
}

.kpi-special-table > tbody:hover td[rowspan] {
    background: rgba(236, 236, 236, 0.425);
}

.close-modal-btn {
    position: absolute;
    right: -15px;
    top: -10px;
    padding: 5px;
    padding-left: 12px;
    padding-right: 12px;
}

.genealogy-modal-button {
    width: 250px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.genealogy-option-modal-top {
    height: 70vh;
}

.genealogy-option-modal-bottom {
    padding: 10px;
    padding-bottom: 20px;
    background: white;
    width: 400px;
    margin: 0 auto;
    border-top: 1px solid #ccc;
    box-shadow: 0px 0px 15px 0px #ccc;
}

.genealogy-option-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    left: 0;
    padding-top: 40vh;
    background-color: rgba(255, 255, 255, 0.2);
}

.detail-star-empty {
    font-size: 17px;
}

.detail-star {
    font-size: 17px;
    color: gold;
}

.refreshBtn {
    margin-right: 5px;
}

.refresh-button.refreshBtn.mb-10 > i {
    top: 1px;
}

.refreshBtn.refresh-button.mb-10.filter-icon > i {
    top: 2px !important;
    font-size: 12px !important;
}

.refresh-button {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 20px;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.sort-by-ov {
    background-color: #f5f5f5;
    color: gray;
    border: 1px solid #e1e7ec;
    position: relative;
    top: 1px;
    width: auto;
}

.sort-by-ov.hidden-sm-down {
    top: 0px;
}

.sort-by-ov-active {
    background-color: #2ab8f5;
    border: 1px solid #2ab8f5;
    color: white;
}

.detail-save-btn {
    display: inline;
    padding: 0 0 0 3px;
    color: #0000ff;
}

.bookmark-cells {
    color: #0da9ef;
}

.fav-person {
    cursor: pointer;
    padding: 5px 0;
    border: 1px solid #ececec;
    border-radius: 5px;
    margin: 0 5px;
    padding: 8px 0;
    margin-bottom: 8px;
}

.left-name {
    color: #606975;
    cursor: pointer;
    float: left;
}

.left-name:hover {
    color: #0da9ef;
}

.modal-header {
    justify-content: center !important;
    border-bottom: none !important;
}

/* new shart cart */

.share-social .modal-header .close {
    position: absolute;
    top: 13px;
    right: 15px;
}

.bg-shart-cart {
    background: #e3e8ed;
}

.bg-shart-cart .super-pills .ant-select-selector {
    height: 46px !important;
    align-items: center !important;
    border: 0 !important;
}

.pills-postion {
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.share-with {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* end new shart cart */

.modal-footer {
    border-top: none !important;
    padding-left: 30px;
    padding-right: 30px;
}

.modal-dialog {
    width: auto !important;
}

@media (max-width: 768px) {
    .overwrite-flex {
        flex-direction: column !important;
    }
}

@media (max-width: 992px) {
    .overwrite-flex {
        flex-direction: column !important;
    }

    .row.img-footer-japan,
    .row.img-footer-vietnam {
        margin-top: 0px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .overwrite-flex {
        flex-direction: column !important;
    }
}

.checkout-button {
    background-color: white;
    color: black;
    border: 1px solid #dce2e8;
    padding: 60px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.genealogy-bookmark-modal-bottom {
    overflow: scroll;
    background: white;
    padding: 10px;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 15px 0px #ccc;
    z-index: 2001;
}

.genealogy-bookmark-modal {
    position: fixed;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
    z-index: 2000;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
}

.empty-modal-top {
    height: 10%;
}

.hide-box {
    height: 45px;
    border: 0px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.hide-box i {
    margin: auto;
}

.hide-box:hover {
    color: #0da9ef !important;
}

.hide-box-inner {
    font-size: 30px;
    display: table-cell;
    vertical-align: middle;
}

.double-arrow {
    height: 45px;
    border: 0px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: #c35454 !important;
    font-size: 30px !important;
}

.double-arrow:hover {
    color: #fbc1c1 !important;
}

.double-arrow i {
    margin: auto;
}

.double-arrow-inner {
    font-size: 30px;
    display: table-cell;
    vertical-align: middle;
}

.main-row {
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.select-level-text {
    display: inline;
    margin-right: 10px;
}

.genealogy-select {
    border: 1px solid #ccc;
}

.genealogy-select-container {
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.genealogy-page-wrapper,
.global-container-bottom-padding {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    min-height: 100px;
}

.col-shadow {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    box-shadow: 2px 0px 5px -1px #ccc;
    z-index: 1;
    background-color: transparent;
}

.left-cell {
    height: 45px;
    text-align: center;
}

.lvl-cell {
    display: table;
    font-size: 10px;
    cursor: pointer;
}

.left-cell-font:hover {
    color: #0da9ef !important;
}

.left-cell-font {
    color: #606975;
    font-weight: bold;
    font-size: 15px;
}

.left-cell-row {
    border-bottom: 0px !important;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    border-top: 0px !important;
    min-height: 50px;
}

.new-left-name {
    /* border-bottom: 1px solid #CCC; */
}

.top-thead {
    border-top: 0px !important;
}

.linearBG {
    background-color: #374250 !important;
    color: white !important;
}

.cool-background {
    color: #606975;
    font-weight: bold;
    font-size: 15px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.gene-search {
    width: 50%;
    height: 32px;
    border: 1px solid #e1e7ec;
    border-radius: 4px;
    outline: none;
    padding-left: 15px;
    padding-right: 30px;
    /*margin-bottom: 10px;*/
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.smaller-text {
    font-size: 12px;
    height: 33px;
    max-width: 100px;
}

.gene-search-button {
    cursor: pointer;
    border: 0px;
    background: transparent;
    height: 36px;
    width: 32px;
    position: absolute;
    right: 20px;
}

.gene-search-button > i {
    margin-bottom: 3px;
}

.vertical-mid {
    vertical-align: middle;
    display: table-cell;
    padding-left: 5px;
    text-align: left;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
}

.name-cell {
    padding: 8px;
}

.refresh-button.refreshBtn.mb-10 > i,
.refreshBtn.refresh-button.mb-10 > i {
    margin: 1px;
}

.genealogy-table {
    width: 100%;
    margin: 0px;
    font-size: 12px;
}

.genealogy-table2 {
    width: 100%;
    margin: 0px;
    font-size: 12px;
}

.genealogy-table > thead > tr > th:nth-child(6) {
    padding-right: 10px;
}

.genealogy-table2 > thead > tr > th:nth-child(6) {
    padding-right: 10px;
}

.genealogy-table > thead > tr > th:nth-child(9) {
    padding-right: 10px;
}

.genealogy-table2 > thead > tr > th:nth-child(9) {
    padding-right: 10px;
}

.genealogy-table > thead > tr > th:nth-child(12) {
    padding-right: 10px;
}

.genealogy-table2 > thead > tr > th:nth-child(12) {
    padding-right: 10px;
}

.genealogy-table > tbody > tr > td:nth-child(4),
.genealogy-table2 > tbody > tr > td:nth-child(4),
.genealogy-table > tbody > tr > td:nth-child(7),
.genealogy-table2 > tbody > tr > td:nth-child(7),
.genealogy-table > thead > tr > th:nth-child(4),
.genealogy-table2 > thead > tr > th:nth-child(4),
.genealogy-table > thead > tr > th:nth-child(7),
.genealogy-table2 > thead > tr > th:nth-child(7) {
    border-right: 1px solid #ccc;
}

.genealogy-table.hasVIP > tbody > tr > td:nth-child(6),
.genealogy-table2.hasVIP > tbody > tr > td:nth-child(6),
.genealogy-table.hasVIP > tbody > tr > td:nth-child(9),
.genealogy-table2.hasVIP > tbody > tr > td:nth-child(9),
.genealogy-table.hasVIP > thead > tr > th:nth-child(6),
.genealogy-table2.hasVIP > thead > tr > th:nth-child(6),
.genealogy-table.hasVIP > thead > tr > th:nth-child(9),
.genealogy-table2.hasVIP > thead > tr > th:nth-child(9) {
    border-right: 1px solid #ccc;
}

.genealogy-table.isGlobal > tbody > tr > td:nth-child(6),
.genealogy-table.isGlobal > tbody > tr > td:nth-child(9),
.genealogy-table.isGlobal > thead > tr > th:nth-child(6),
.genealogy-table.isGlobal > thead > tr > th:nth-child(9) {
    border-right: 1px solid #ccc;
}

.genealogy-table.isGlobal > tbody > tr > td:nth-child(4),
.genealogy-table.isGlobal > tbody > tr > td:nth-child(7),
.genealogy-table.isGlobal > thead > tr > th:nth-child(4),
.genealogy-table.isGlobal > thead > tr > th:nth-child(7) {
    border-right: 0px;
}

.genealogy-table.hasVIP > tbody > tr > td:nth-child(4),
.genealogy-table.hasVIP > tbody > tr > td:nth-child(7),
.genealogy-table.hasVIP > thead > tr > th:nth-child(4),
.genealogy-table.hasVIP > thead > tr > th:nth-child(7),
.genealogy-table2.hasVIP > tbody > tr > td:nth-child(4),
.genealogy-table2.hasVIP > tbody > tr > td:nth-child(7),
.genealogy-table2.hasVIP > thead > tr > th:nth-child(4),
.genealogy-table2.hasVIP > thead > tr > th:nth-child(7) {
    border-right: 0px;
}

.genealogy-table.hasAPV > tbody > tr > td:nth-child(5),
.genealogy-table.hasAPV > tbody > tr > td:nth-child(8),
.genealogy-table.hasAPV > thead > tr > th:nth-child(5),
.genealogy-table.hasAPV > thead > tr > th:nth-child(8),
.genealogy-table2.hasAPV > tbody > tr > td:nth-child(5),
.genealogy-table2.hasAPV > tbody > tr > td:nth-child(8),
.genealogy-table2.hasAPV > thead > tr > th:nth-child(5),
.genealogy-table2.hasAPV > thead > tr > th:nth-child(8) {
    border-right: 1px solid #ccc;
}

.genealogy-table.hasAPV > tbody > tr > td:nth-child(4),
.genealogy-table.hasAPV > tbody > tr > td:nth-child(7),
.genealogy-table.hasAPV > thead > tr > th:nth-child(4),
.genealogy-table.hasAPV > thead > tr > th:nth-child(7),
.genealogy-table2.hasAPV > tbody > tr > td:nth-child(4),
.genealogy-table2.hasAPV > tbody > tr > td:nth-child(7),
.genealogy-table2.hasAPV > thead > tr > th:nth-child(4),
.genealogy-table2.hasAPV > thead > tr > th:nth-child(7) {
    border-right: 0px;
}

.genealogy-table.hasVipApv > tbody > tr > td:nth-child(6),
.genealogy-table.hasVipApv > tbody > tr > td:nth-child(9),
.genealogy-table.hasVipApv > thead > tr > th:nth-child(6),
.genealogy-table.hasVipApv > thead > tr > th:nth-child(9),
.genealogy-table2.hasVipApv > tbody > tr > td:nth-child(6),
.genealogy-table2.hasVipApv > tbody > tr > td:nth-child(9),
.genealogy-table2.hasVipApv > thead > tr > th:nth-child(6),
.genealogy-table2.hasVipApv > thead > tr > th:nth-child(9) {
    border-right: 0px;
}

.expand-icon {
    font-size: 22px;
}

.button-margin-left {
    margin-left: 10px !important;
}

.reviewStar .dv-star-rating > label > i {
    font-size: 25px;
}

.rating-stars-single {
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 12px;
}

.rating-stars-shop {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 14px;
}

.expand-icon-container {
    height: 100%;
    text-align: right;
    float: left;
}

.genealogy-table > tbody > tr > td:nth-child(6) {
    padding-right: 10px;
}

.genealogy-table2 > tbody > tr > td:nth-child(6) {
    padding-right: 10px;
}

.genealogy-table > tbody > tr > td:nth-child(9) {
    padding-right: 10px;
}

.genealogy-table2 > tbody > tr > td:nth-child(9) {
    padding-right: 10px;
}

.genealogy-table > tbody > tr > td:nth-child(12) {
    padding-right: 10px;
}

.genealogy-table2 > tbody > tr > td:nth-child(12) {
    padding-right: 10px;
}

.genealogy-table > tbody tr td {
    height: 45px !important;
}

.genealogy-table > tbody > tr > td::after {
    border-bottom: 1px solid #ccc;
}

.genealogy-table > thead > tr > th {
    height: 45px !important;
    text-align: center;
    color: #606975;
    font-weight: bold;
    font-size: 15px;
    border: 0px;
    border-bottom: 1px solid #ccc;
    background: transparent;
}

.genealogy-table2 > thead > tr > th {
    height: 45px;
    text-align: center;
    color: #606975;
    font-weight: bold;
    font-size: 15px;
    border: 0px;
    border-bottom: 1px solid #ccc;
}

.genealogy-table > thead > tr:nth-child(2) > th {
    text-align: center;
}

.genealogy-table2 > thead > tr:nth-child(2) > th {
    text-align: center;
}

/* .genealogy-table>thead>tr>th:hover {
    /* color: #0da9ef !important;


}*/

.overscroll {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
}

.overscroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
}

.genealogy-table > tbody > tr > td {
    width: 6.333333%;
    height: 50px;
    text-align: center;
    border-right: 0px;
    border-left: 0px;
    background: white;
    border-bottom: 1px solid #ccc;
    padding: 2px !important;
}

.genealogy-table2 > tbody > tr > td {
    width: 8.333333%;
    height: 50px;
    text-align: center;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 1px solid #ccc;
}

.genealogy-table-col {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.genealogy-table2-col {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.genealogy-table-col::-webkit-scrollbar {
    display: none;
}

.genealogy-table2-col::-webkit-scrollbar {
    display: none;
}

.lastCSS {
    border-bottom: 0px;
    height: 49px;
}

.rankSelect {
    position: relative;
}

.centerSelect {
    text-align: center;
    text-align-last: center;
    -webkit-appearance: none;
    align-content: center;
}

/** End of genealogy */

/* LSB Tab */

.lsb-box,
.orderhistory-box,
.commission-box {
    display: grid;
    align-items: center;
    border-radius: 7px;
    margin-bottom: 10px;
    text-align: center;
}

.table-lsb > td {
    vertical-align: middle;
}

.col-head-title {
    background-color: #606975;
    color: #ffffff;
    padding: 5px !important;
    border: 0px !important;
}

.row-col {
    font-size: 15;
    align-content: top;
}

.table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
}

.no-xscroll {
    overflow-x: hidden;
}

.nohover tbody tr:hover {
    background: none;
}

.small-12 {
    width: 100%;
}

.columns {
    border: 0px solid #000000;
}

.table-responsive > .table-bordered {
    border: 0;
}

/* End of LSB Tab */

/* Commission Statement */

.commission-table {
    width: 100%;
    border-left: 1px solid #ccc;
}

.commission-table > tbody > tr {
    border-bottom: 1px solid #ccc;
}

.commission-table > tbody > tr > td {
    text-align: center;
    border-right: 1px solid #ccc;
}

.select-state {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 50%;
    height: 30px;
    margin: 0 auto;
    margin-bottom: 17px;
    background: #f8f8f8;
    padding: 0px;
    display: block;
}

.table-head {
    text-align: center;
    color: #606975;
    vertical-align: middle;
    font-size: 13px;
    vertical-align: middle;
}

.button-select {
    display: block;
    margin: 0 auto;
    margin-bottom: 12px;
}

/* Kpitable */

.kpi-table-header {
    background-color: #606975;
    height: 20px;
    border: 1px solid #ccc;
    border-collapse: collapse;
    color: white;
    text-align: center;
}

.kpi-table-borby {
    text-align: center;
}

.suggested-slider .slick-slide {
    padding: 8px !important;
}

.suggested-slider-mobile .slick-slide {
    padding: 8px !important;
}

/* tr:nth-of-type(even) {
    background-color: #dddddd;
} */

td,
th {
    font-size: 12px;
    text-align: left;
    padding: 8px;
}

.shopping-cart th {
    color: #606975;
}

table {
    width: 100%;
    align-content: center;
    margin-bottom: 15px;
}

/* Profile */

.profile-link {
    cursor: pointer;
}

.user-info-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: -1px;
    padding-top: 90px;
    padding-bottom: 0px;
    border: 1px solid #e1e7ec;
    border-radius: 7px;
}

.arbalance {
    position: relative;
    width: 100%;
    margin-top: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 1px solid #e1e7ec;
    border-radius: 7px;
    background: #ffffff;
    -webkit-perspective: 1px;
    perspective: 1px;
    border-radius: 5px;
    border-collapse: separate;
}

.arbalance-label-container {
    position: relative;
    width: 100%;
    height: 40px;
    justify-content: center;
    text-align: left;
    padding: 6px 0px 0px 15px;
    cursor: pointer;
}

.arbalance-label {
    font-size: 18px;
    font-weight: 700;
    color: rgb(96, 105, 117);
}

.arbalance-summary {
    max-width: 82px;
    height: 20px;
    border-top: 0;
    border-right: 0;
    border-bottom: solid 1px;
    border-left: 0;
    outline: none;
    border-radius: 0;
    padding: 0;
    text-align: center;
}

.arbalance-summary.super-input {
    font-weight: bold;
    font-size: 14px;
    padding: 0px;
    height: 20px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.arbalance-summary.super-input:hover {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.arbalanceMessage {
    padding: 0px 0px 0px 5px;
    color: red;
}

.user-cover {
    background-image: url(../assets/profileBG.svg);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background-position: center;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 1px solid #e1e7ec;
}

.info-label {
    display: block;
    position: absolute;
    top: 18px;
    right: 18px;
    height: 26px;
    padding: 0 12px;
    border-radius: 13px;
    background-color: #fff;
    color: #606975;
    font-size: 12px;
    line-height: 26px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
    cursor: default !important;
}

.user-info {
    display: table;
    position: relative;
    width: 100%;
    padding: 0 18px;
    z-index: 5;
    margin-bottom: 20px;
}

.single-product {
    max-height: 300px;
}

.user-info-sm {
    display: table;
    /*position: relative;*/
    width: 100%;
    /*padding-top:25px;*/
    /*padding: 0 18px;*/
    z-index: 5;
    margin-bottom: 5px;
}

.user-avatar {
    position: relative;
    width: 115px;
    display: table-cell;
    vertical-align: top;
    border-radius: 100px;
}

.user-avatar-sm {
    position: relative;
    width: 65px;
    display: table-cell;
    /*vertical-align: top;
    border-radius: 100px;*/
}

.user-avatar > img {
    background-color: white;
    display: block;
    width: 100%;
}

.user-data {
    padding-top: 48px;
    padding-left: 12px;
    display: table-cell;
    vertical-align: top;
}

.user-data-sm {
    padding-top: 10px;
    padding-left: 8px;
    margin-right: 5px;
    /*margin-top20px;
    padding-top: 10px;
    padding-left: 5px;*/
    display: table-cell;
    vertical-align: middle;
}

.user-data > span {
    display: block;
    color: #9da9b9;
    font-size: 13px;
}

.form-group label {
    font-size: 13px;
    font-weight: 500;
    cursor: default;
}

.form-group .btn-group.pills {
    /* margin-top: 0; */
}

/*Success*/

.success-row {
    margin-top: 10px;
}

.success-badge {
    margin-right: 2px;
}

.rank-short-success {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rank-row {
    display: inline-block;
}

.rank-name {
    display: none;
}

.rank-pin {
    cursor: pointer;
}

.rank-pin > p {
    position: relative;
    border: 1px solid #e1e7ec;
    border-radius: 500px;
    width: 60px;
    height: 60px;
    margin: 0 auto;
}

.rank-row .gallary-container {
    height: auto;
}

.success-progress-bar {
    margin-top: 5px;
    height: 25px;
    border-radius: 8px;
    background-color: #f0f0f0;
}

.success-progress-bar-inner {
    transition: all 400ms ease 0ms;
    height: 25px;
    border-radius: 8px;
    background-color: #0da9ef;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    position: relative;
    bottom: 20px;
}

.leg-choice {
    margin-left: 10px;
    margin-right: 10px;
}

.success-table-container {
    margin-left: -5px;
    margin-right: -5px;
}

.status-page.months-select li {
    display: inline-block;
    padding: 0 20px;
}

.status-button-commission {
    background: none;
    border: 0px;
    border-bottom: 2px solid #f0f0f0;
    padding: 0px 10px;
    cursor: pointer;
}

.status-button-commission.active {
    border-bottom-color: #48a8e9;
}

.btn-leg {
    padding-bottom: 23px;
    height: 20px;
    color: white;
    text-decoration: none;
    border-radius: 7px;
    position: relative;
    top: 2px;
    cursor: pointer;
    border: 0px;
    right: 5px;
    width: 30px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding-top: 5px;
}

.align-stuff {
    text-align: center;
}

.rank-badges .slick-prev:before {
    font-family: 'FontAwesome';
    content: '\f104' !important;
}

.rank-badges .slick-next:before {
    font-family: 'FontAwesome';
    content: '\f105' !important;
}

.btn-leg:hover {
    color: #cccccc;
}

.btn-leg.ov {
    background-color: #bdd5fc;
}

.btn-leg.leg1 {
    background-color: rgb(80, 198, 233);
}

.btn-leg.leg2 {
    background-color: rgb(255, 183, 79);
}

.btn-leg.leg3,
.btn-leg.leg3hc,
.btn-leg.ov_leg3hc {
    background-color: rgb(255, 82, 82);
}

.btn-leg.apt {
    background-color: #a6ca72;
}

.col-11.success-fix-width {
    flex: 0 0 95%;
    max-width: 95%;
    padding-right: 0;
}

.col-1.btn-fix-width {
    flex: 0 0 5%;
    max-width: 5%;
}

.dateMargin {
    margin-top: 20px;
}

.id-label {
    display: block;
    position: absolute;
    top: 48px;
    right: 33px;
    height: 26px;
    padding: 0 12px;
    color: #606975;
    font-size: 12px;
    line-height: 26px;
}

/** Forms */

.form-control-number {
    width: 50px;
    height: 44px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 5px 3px;
    border: 1px solid #dbe2e8;
    border-radius: 22px;
    background-color: #fff;
    color: #606975;
    font-family: Prompt, sans-serif;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.input-group .form-control:not(:last-child) {
    border-bottom-right-radius: 22px;
    border-top-right-radius: 22px;
}

.getinfomation-form .input-group .form-control:not(:last-child) {
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    height: 50px;
}

.getinfomation-form .form-control {
    border-radius: 7px;
}

.input-group .form-control {
    width: 100%;
}

.feedback-box-single {
    width: 100%;
    text-align: left;
    padding: 8px 8px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color: #dfdfdf;
    font-size: 14px;
    resize: none;
    transition: border 0.5s;
}

.feedback-box-single:focus {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #0da9ef;
    transition: border 0.25s;
    outline: none;
}

.feedback-box {
    width: 100%;
    height: 150px;
    text-align: left;
    padding: 8px 8px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color: #dfdfdf;
    font-size: 14px;
    resize: none;
    transition: border 0.5s;
}

.feedback-box:focus {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #0da9ef;
    transition: border 0.25s;
    outline: none;
}

.input-box {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    text-align: left;
    justify-content: center;
    padding-right: 80px;
    padding-left: 10px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color: #dfdfdf;
    font-size: 14px;
    resize: none;
}

/** Spinkit */

.sk-circle {
    width: 60px;
    height: 60px;
    position: relative;
    color: #48a8e9;
    top: 122px;
    z-index: 6;
    left: 45px;
}

.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #48a8e9;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.arrow {
    text-align: center;
}

.bounce {
    -webkit-animation-name: bounce;
    -moz-animation-name: bounce;
    -o-animation-name: bounce;
    animation-name: bounce;
}

.animated {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-fill-mode: both;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    -ms-animation-duration: 2s;
    -o-animation-duration: 2s;
    animation-duration: 2s;
}

@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
    }
}

@-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
    }

    40% {
        -moz-transform: translateY(-30px);
    }

    60% {
        -moz-transform: translateY(-15px);
    }
}

@-o-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -o-transform: translateY(0);
    }

    40% {
        -o-transform: translateY(-30px);
    }

    60% {
        -o-transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.sk-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0da9ef;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes sk-foldCubeAngle {
    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

/** End of Spinkit */

.remove-review {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    border: none;
    background: transparent;
}

.snapshot-btn {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #5d6976;
}

/* New Slider */

.slider-mobile button {
    -webkit-appearance: none;
    appearance: none;
    -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, 0.1));
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    /*-webkit-transition: all 0.5s ease;*/
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    /*-webkit-transition: all 0.5s ease;*/
    /*transition: all 0.5s ease;*/
    border: none;
    background: #0d9fed;
    border-radius: 5px;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 10px 30px;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
    color: white;
}

.wrapper {
    max-width: 1335px;
    box-sizing: border-box;
    padding: 0 20px;
    margin: auto;
}

.wrapper a {
    display: inline-block;
    margin: 5px;
}

.hidden-sm {
    display: block;
}

.visible-sm {
    display: none;
}

.productFilter .visible-sm.show {
    display: block;
    max-height: 3000px;
    opacity: 1;
}

.productFilter .mobileFilter {
    opacity: 0;
    max-height: 0;
}

@media (max-width: 720px) {
    .hidden-sm {
        display: none;
    }

    .visible-sm {
        display: block;
    }

    .mt-autoship-3x {
        margin-top: 30px;
    }
}

.wrapper a:first-child {
    margin-left: 0;
}

.slider-wrapper-new {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.slider-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, .1));
    -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1)); */
    /* filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1)); */
    border: none;
    background: #fb8c00;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 10px 0px;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
    color: #fff;
    width: 150px;
}

.slide {
    height: 60vh;
    background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    /*background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));*/
    bottom: 0;
    left: 0;
}

.previousButton,
.nextButton {
    -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, 0.1));
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    -webkit-transition: all 0.2s ease;
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 10;
    background: url('../assets/slideArrow.svg') no-repeat center center / 16px;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
    background: url('../assets/slideArrow.svg') no-repeat center center / 16px;
}

.previousButton {
    left: 0;
    -webkit-transform: rotate(180deg) translate3d(0px, calc(50% + 0px), 0px);
    transform: rotate(180deg) translate3d(0px, calc(50% + 0px), 0px);
}

.previousButton:hover {
    left: -5px;
}

.nextButton {
    right: 0;
}

.nextButton:hover {
    right: -5px;
}

.slider-content {
    text-align: center;
    will-change: contents;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    /* -webkit-transition-duration: 1s ease-in-out; */
}

.slider-content .inner {
    /*padding: 0 70px;*/
    /*box-sizing: border-box;*/
    position: absolute;
    /*width: 100%;*/
    top: 30%;
    left: 65%;
    text-align: center;
    margin-right: 10%;
    /*-webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);*/
    /*will-change: contents;*/
}

.slider-content .inner button {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #ffffff;
    font-size: 64px;
    line-height: 1;
}

.slider-mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%);
}

.slider-mobile p {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #ffffff;
    font-size: 64px;
    /*line-height: 0.7;*/
}

.slider-content .inner p {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    /*margin: 20px auto 30px;*/
    max-width: 640px;
    font-weight: 500;
}

h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #ffffff;
    font-size: 64px;
    line-height: 1;
}

.slider-content .inner p {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    /*margin: 20px auto 30px;*/
    max-width: 640px;
    font-weight: 500;
}

.custom-file-label::after {
    min-width: 72px;
    text-align: center;
}

.custom-file-input:lang(vn) ~ .custom-file-label::after {
    content: 'chọn';
}

@media (max-height: 600px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}

@media (max-width: 640px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}

@media screen and (max-width: 639px) {
    .global-connected-button-right,
    .global-connected-button-left {
        padding: 5px 20px;
    }
}

/* Slider Animations */

/*.slide img {
    transition: all 0.6s ease;
    -webkit-transform: translate3d(0px, -20px, 0px);
    transform: translate3d(0px, -20px, 0px);
    opacity: 0;
    will-change: contents;
}

.slide button {
    transition: all 0.6s ease;
    -webkit-transform: translate3d(0px, 20px, 0px);
    transform: translate3d(0px, 20px, 0px);
    opacity: 0;
    will-change: contents;
}

.slide p {
    transition: all 0.6s ease;
    -webkit-transform: translate3d(0px, 20px, 0px);
    transform: translate3d(0px, 20px, 0px);
    opacity: 0;
    will-change: contents;
}

.slide section * {
    transition: all 0.6s ease;
}

.slide.animateIn.previous img,
.slide.current img,
.slide.animateIn.next img,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-transition-delay: .9s;
    transition-delay: .9s;
    opacity: 1;
    will-change: contents;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
    will-change: contents;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateOut img {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
    will-change: contents;
}

.slide.animateOut p {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
    will-change: contents;
}

.slide.animateOut section span {
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}

.slide.animateOut section span strong {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}*/

/* Slick */

.dummyDescription {
    text-align: justify;
    margin-top: 20px;
    margin-right: 25px;
}

.productDescription {
    text-align: justify;
    margin-top: 20px;
    margin-right: 25px;
}

.addressBtn {
    background-color: #3cb0fd;
    -moz-border-radius: 5x;
    -webkit-border-radius: 5px;
    border-radius: 30px;
    border: 1px solid #3cb0fd;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px;
    text-decoration: none;
}

.address-btn-sm {
    background-color: #3cb0fd;
    -moz-border-radius: 5x;
    -webkit-border-radius: 5px;
    border-radius: 30px;
    border: 1px solid #3cb0fd;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 10px;
    text-decoration: none;
}

.addressBtn span {
    margin-right: 5px;
}

.addressBtn[disabled],
.addressBtn.disabled {
    cursor: not-allowed;
    opacity: 0.55;
    pointer-events: none;
}

.addressBtn:hover {
    background: #57bafc;
    text-decoration: none;
    border: 1px solid #57bafc;
}

.addressBtnBack {
    background-color: #ffffff;
    -moz-border-radius: 5x;
    -webkit-border-radius: 5px;
    border-radius: 30px;
    border: 1px solid #e1e7ec;
    display: inline-block;
    cursor: pointer;
    color: #6c757d;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px;
    text-decoration: none;
}

.shopprofileBtn {
    background-color: #3cb0fd;
    -moz-border-radius: 5x;
    -webkit-border-radius: 5px;
    border-radius: 30px;
    border: 1px solid #3cb0fd;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px;
    text-decoration: none;
}

.shopprofileBtn:hover {
    background: #57bafc;
    text-decoration: none;
    border: 1px solid #57bafc;
}

.shopprofileCancelBtn {
    border-radius: 0px;
    cursor: pointer;
    border-color: transparent;
    transition: all 0.3s ease;
    background-color: transparent;
    box-shadow: 0px 0px 0px;
    border: 1px solid transparent;
    color: #2e8fcf;
}

.addressCancelBtn {
    border-radius: 0px;
    cursor: pointer;
    border-color: transparent;
    transition: all 0.3s ease;
    background-color: transparent;
    box-shadow: 0px 0px 0px;
    border: 1px solid transparent;
    color: #2e8fcf;
}

.shopprofileBtn {
    background-color: #3cb0fd;
    -moz-border-radius: 5x;
    -webkit-border-radius: 5px;
    border-radius: 30px;
    border: 1px solid #3cb0fd;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px;
    text-decoration: none;
}

.shopprofileBtn:hover {
    background: #57bafc;
    text-decoration: none;
    border: 1px solid #57bafc;
}

.shopprofileCancelBtn {
    border-radius: 0px;
    cursor: pointer;
    border-color: transparent;
    transition: all 0.3s ease;
    background-color: transparent;
    box-shadow: 0px 0px 0px;
    border: 1px solid transparent;
    color: #2e8fcf;
}

.autoshipBtn {
    background-color: #3cb0fd;
    -moz-border-radius: 5x;
    -webkit-border-radius: 5px;
    border-radius: 30px;
    border: 1px solid #3cb0fd;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 50px;
    text-decoration: none;
}

.autoshipBtn:hover {
    background: #57bafc;
    text-decoration: none;
    border: 1px solid #57bafc;
}

.autoshipCancelBtn {
    border-radius: 0px;
    cursor: pointer;
    border-color: transparent;
    transition: all 0.3s ease;
    background-color: transparent;
    box-shadow: 0px 0px 0px;
    border: 1px solid transparent;
    color: #2e8fcf;
}

.suggested-slider .slick-dots {
    bottom: 100px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
}

.suggested-slider-mobile .slick-dots {
    bottom: 100px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
}

.suggested-slider .slick-dots li {
    margin: 0px !important;
}

.suggested-slider .slick-dots li button::before {
    color: grey !important;
    line-height: 30px !important;
}

.suggested-slider-mobile .slick-dots li {
    margin: 0px !important;
}

.suggested-slider-mobile .slick-dots li button::before {
    color: grey !important;
    line-height: 30px !important;
}

.slick-arrow {
    z-index: 99;
}

.slick-dots {
    width: 100px !important;
    bottom: 0px !important;
    left: calc(50% - 50px) !important;
    height: 45px !important;
    background-color: #606975;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    margin: 0px !important;
}

.slick-dots li button::before {
    color: white !important;
    line-height: 30px !important;
}

.gallary-container {
    height: 580px;
}

/** Toast Notification */

.s-alert-box {
    width: 300px;
    z-index: 999 !important;
}

.s-alert-box::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 8px 10px -4px rgba(0, 0, 0, 0.2);
    content: '';
    z-index: -1;
}

.s-alert-wrapper i {
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    display: table;
    font-size: 20px;
    line-height: 20px;
    margin-top: -11px;
    margin-left: 10px;
}

.s-alert-box-inner td,
.s-alert-box-inner th {
    padding: 8px;
}

.s-alert-box-inner strong,
.s-alert-box-inner p {
    margin-left: 20px !important;
}

.s-alert-close {
    top: 35% !important;
}

.s-alert-box:first-child {
    top: 10px;
}

.s-alert-box.s-alert-success {
    border: 1px solid rgba(67, 217, 163, 0.3) !important;
    background-color: #dcf8ee !important;
    color: #2bd396 !important;
}

.s-alert-success .s-alert-close::before,
.s-alert-success .s-alert-close::after {
    background: #2bd395 !important;
}

.s-alert-box.s-alert-warning {
    border: 1px solid rgba(255, 183, 79, 0.3) !important;
    background-color: #fff6e8 !important;
    color: #ffb140 !important;
}

.s-alert-warning .s-alert-close::before,
.s-alert-warning .s-alert-close::after {
    background: #ffb140 !important;
}

.s-alert-box.s-alert-info {
    border: 1px solid rgba(80, 198, 233, 0.3) !important;
    background-color: #e6f7fc !important;
    color: #39bfe6 !important;
}

.s-alert-info .s-alert-close::before,
.s-alert-info .s-alert-close::after {
    background-color: #39bfe6 !important;
}

.s-alert-box.s-alert-error {
    border: 1px solid rgba(255, 82, 82, 0.3) !important;
    background-color: #ffebeb !important;
    color: #ff5151 !important;
}

.s-alert-error .s-alert-close::before,
.s-alert-error .s-alert-close::after {
    background-color: #ff5252 !important;
}

/** Footer */

.hover-regis {
    color: white;
}

.footer-hover-quicklink:visited {
    text-decoration: none;
    font-weight: 700;
    color: white;
}

.footer-hover-quicklink {
    text-decoration: none;
    font-weight: 700;
    color: white;
    transition: 0.3s;
}

.footer-hover-quicklink-btn {
    text-decoration: none;
    font-weight: 700;
    color: white;
    background-color: transparent;
    padding: 0px;
    border: none;
}

.footer-hover-quicklink-btn:hover {
    cursor: pointer;
    color: #0da9ef;
    text-decoration: none !important;
}

.footer-hover-quicklink:hover {
    color: #0da9ef;
    cursor: pointer;
    background-position: center;
    text-decoration: none;
    /* background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; */
}

.hover-quicklink:hover {
    color: #0da9ef !important;
    cursor: pointer;
    background-position: center;
    /* background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; */
}

.cat-shop {
    padding-bottom: 16px;
}

.item-left {
    text-align: left;
}

.site-footer {
    padding-top: 30px;
    /* margin-top: 30px; */
    clear: both;
    background-color: #03274c;
}

.footer-social {
    margin-right: 8px;
}

.footer-social img {
    width: 35px;
}

.footer-social .hover {
    display: none;
}

.footer-social:hover .hover {
    display: inherit;
}

.footer-social:hover .non-hover {
    display: none;
}

.layout-back-to-top {
    display: flex;
    justify-content: flex-end;
}

.footer-back-to-top {
    cursor: pointer;
    animation: fadeIn 0.3s;
}

.footer-back-to-top img {
    width: 35px;
}

.footer-back-to-top .hover {
    display: none;
}

.footer-back-to-top:hover .hover {
    display: inherit;
}

.footer-back-to-top:hover .non-hover {
    display: none;
}

.layout-app-list {
    display: flex;
}

.layout-app-list .left-side-app-list {
    margin-right: 6px;
}

.app-list {
    text-align: left;
    padding-bottom: 20px;
}

.app-list:last-child {
    padding: unset;
}

.app-list img {
    width: 60%;
}

.widget li:last-child .market-button {
    margin-right: 0;
}

.form-control + .input-group-addon:not(:first-child) {
    border: 0px;
    padding-top: 12px;
}

.input-group .form-control {
    padding-left: 45px;
}

.payments {
    background-image: url('../assets/paymnet_method_new.png');
    background-size: cover;
    background-position: -150px 0px;
    background-repeat: no-repeat;
    height: 60px;
    padding-left: 10%;
    width: 320px;
}

/** News */

.news {
    padding-top: 25px;
}

li.news {
    padding-top: 0px;
}

.img-news {
    display: block;
    position: relative;
    width: 100%;
    border-radius: 7px;
    overflow: hidden;
}

.reward #wrapper table tbody td {
    min-width: 150px;
    vertical-align: top;
}

.gallery-item {
    padding-bottom: 30px;
}

.x {
    border: 0px;
}

/** Burger Menu */

/* Hides everything pushed outside of it */

.site-wrap .wrap-layout .pwblock {
    width: 100%;
    height: 100%;
}

.wrap-layout,
.offcanvas-menu {
    -webkit-transition: all 0.1s ease 0ms;
    transition: all 0.1s ease 0ms;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.show-cart {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    max-height: 100vh;
}

.show-cart .wrap-layout {
    -webkit-transform: translate3d(-360px, 0, 0) !important;
    -moz-transform: translate3d(-360px, 0, 0) !important;
    -ms-transform: translate3d(-360px, 0, 0) !important;
    -o-transform: translate3d(-360px, 0, 0) !important;
    transform: translate3d(-360px, 0, 0) !important;
    -webkit-transition: all 0.1s ease 0ms !important;
    transition: all 0.1s ease 0ms !important;
}

.show-cart .slide-out {
    -webkit-transform: translate3d(360px, 0, 0);
    transform: translate3d(360px, 0, 0);
    -webkit-transform: translate3d(360px, 0, 0);
    transform: translate3d(360px, 0, 0);
    -webkit-transition: all 0.1s ease 0ms;
    transition: all 0.1s ease 0ms;
}

.show-cart .slide-in {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    right: 0px;
    -webkit-transition: all 0.1s ease 0ms;
    transition: all 0.1s ease 0ms;
}

.show-nav .offcanvas-menu,
.show-nav .push-wrap,
.show-nav .pwblock {
    -webkit-transform: translate(250px);
    transform: translate(250px);
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
    transition: all 0.1s ease 0ms;
}

.offcanvas-menu,
.push-wrap,
.pwblock {
    -webkit-transition: all 0.1s ease 0ms;
    transition: all 0.1s ease 0ms;
    -webkit-transform: translate(0);
    transform: translate(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.close-nav .pwblock {
    -webkit-transition: all 0.1s ease 0ms;
    transition: all 0.1s ease 0ms;
    -webkit-transform: translate(0);
    transform: translate(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

/* .show-cart #sidebar-nav {
    left: -250px;
} */

.account-link {
    margin-bottom: 0px !important;
    display: block;
    padding: 10px 10px;
    transition: background-color 0.3s;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #313b47;
    color: #fff !important;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.account-link .rank {
    font-size: 9px;
}

#sidebar-nav {
    width: 250px;
    height: 100%;
    position: fixed;
    padding-left: 0;
    left: -250px;
    top: 0;
    background: #374250;
    -webkit-box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
    box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.1s ease 0ms;
}

#sidebar-nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#sidebar-nav ul li > span {
    display: block;
    padding: 15px 20px;
    transition: color 0.3s;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-decoration: none;
}

#sidebar-nav ul li.active > span,
#sidebar-nav ul li .active {
    color: #0da9ef;
}

#sidebar-nav ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    width: 250px;
}

#sidebar-nav ul li.back-btn {
    border-bottom: 0px;
}

#sidebar-nav ul li > div:first-child {
    display: inline-block;
    vertical-align: middle;
    /* transition: color 0.3s; */
    /* color: #fff; */
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
    height: 40px;
}

#sidebar-nav.PH ul li > span {
    display: block;
    padding: 15px 20px;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.12);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-decoration: none;
}

#sidebar-nav ul li > div > div.offset-menu-title {
    padding-left: 20px;
    height: 100%;
    width: 190px;
    display: inline-block;
}

#sidebar-nav ul li > div > div.offset-menu-title > div {
    height: 100%;
    padding-top: 1.25rem;
}

#sidebar-nav ul li.active > div,
#sidebar-nav ul li .active {
    color: #0da9ef;
}

.lock-scroll-bar {
    overflow-y: hidden !important;
}

#sidebar-nav ul li.has-children .sub-menu-toggle {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    height: calc(100% - 1px);
    transition: background-color 0.3s;
    color: transparent;
    cursor: pointer;
    z-index: 1;
    border-left: 0px;
}

.offcanvas-menu ul li.has-children .sub-menu-toggle {
    height: 100%;
}

.sub-menu-toggle.has-icon::before {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
}

.has-icon {
    display: inline-block !important;
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
    z-index: 1;
    width: 60px !important;
    border-left: 1px solid hsla(0, 0%, 100%, 0.12);
    height: 50px;
    vertical-align: middle;
    padding-top: 1.25rem;
}

.has-icon.lang-switch {
    width: 200px !important;
}

.offcanvas-menu ul li.back-btn > div::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 8px;
    border-top: 0.32em solid transparent;
    border-right: 0.37em solid;
    border-bottom: 0.32em solid transparent;
    content: '';
    opacity: 0.75;
}

#sidebar-nav.PH ul li.has-children .sub-menu-toggle:hover {
    background-color: transparent;
}

#sidebar-nav.PH ul li.has-children .sub-menu-toggle::before {
    display: block;
    position: absolute;
    top: 50%;
    right: 25px;
    width: 0;
    height: 0;
    margin-top: -5px;
    border-top: 0.35em solid transparent;
    border-bottom: 0.35em solid transparent;
    border-left: 0.4em solid;
    content: '';
}

#sidebar-nav.PH .offcanvas-submenu {
    position: absolute;
    top: 0;
    right: -100%;
    width: 100%;
    height: auto;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

#sidebar-nav.PH .offcanvas-submenu.in-view {
    transform: translate3d(0, 0, 0);
}

.offcanvas-menu > ul {
    width: 250px;
}

.offcanvas-menu ul li {
    display: inline-block;
}

.offcanvas-menu ul li.back-btn > p {
    background-color: #313b47;
    color: #fff;
}

.offcanvas-menu ul li p {
    display: block;
    padding: 15px 20px;
    transition: color 0.3s;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-decoration: none;
}

.offcanvas-menu ul li.back-btn > p::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 8px;
    border-top: 0.32em solid transparent;
    border-right: 0.37em solid;
    border-bottom: 0.32em solid transparent;
    content: '';
    opacity: 0.75;
}

.offcanvas-menu ul li.has-children > div {
    display: block;
    width: 100%;
}

.offcanvas-menu li {
    display: block;
}

.btn-group > .dropdown-toggle:not(:first-child) {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
}

.site-menu > ul > li.has-megamenu {
    color: #0da9ef !important;
}

.logo-gradient {
    background-image: linear-gradient(
        -90deg,
        #5468ad 12%,
        #448ed7 55%,
        #30acea 100%
    );
}

header ul li.list-group-item.active {
    cursor: pointer !important;
}

header .logout,
#profile-side .logout {
    position: relative;
    width: 100%;
    border-top: 1px solid #e1e7ec;
    height: 40px;
    justify-content: center;
    text-align: left;
    padding-left: 15px;
    cursor: pointer;
}

header .logout-sm {
    position: relative;
    width: 100%;
    /*border-top: 1px solid #e1e7ec;*/
    height: 25px;
    justify-content: center;
    text-align: left;
    padding-left: 15px;
    cursor: pointer;
}

header .logout-sm:hover {
    position: relative;
    width: 100%;
    /*border-top: 1px solid #e1e7ec;*/
    height: 25px;
    justify-content: center;
    text-align: left;
    padding-left: 15px;
    cursor: pointer;
    color: #0da9ef;
}

header .logout:hover,
#profile-side .logout:hover {
    position: relative;
    width: 100%;
    border-top: 1px solid #e1e7ec;
    height: 40px;
    justify-content: center;
    text-align: left;
    cursor: pointer;
    color: #0da9ef;
}

header .logout-label,
#profile-side .logout-label {
    top: 0px;
    position: relative;
    font-weight: 500;
    cursor: pointer;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

.stuff-transform {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

header .logout.active,
#profile-side .logout.active {
    border-color: #0da9ef;
    background-color: #0da9ef;
    color: #fff;
}

.rank-pin {
    padding-bottom: 0%;
}

.rank-badges .slick-slide.slick-active .rank-pin > p {
    border-color: rgb(13, 169, 239);
    color: rgb(13, 169, 239);
}

.gallary-slides.success-page {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
}

.rank-badges .slick-dots {
    top: 88px;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
}

.rank-badges .slick-dots li button::before {
    color: black !important;
}

.rank-badges .slick-dots li button {
    background: none;
}

.rank-badges .slick-dots li button:hover {
    cursor: pointer;
}

.rank-badges .slick-prev {
    left: 0px;
}

.rank-badges .slick-prev:before,
.rank-badges .slick-next:before {
    color: rgb(13, 169, 239);
    font-size: 40px;
}

.rank-badges .slick-next {
    right: 10px;
}

.col-11.success-fix-width {
    flex: 0 0 90%;
    max-width: 90%;
}

.new-letter-desktop {
    display: block;
}

.new-letter-mobile {
    display: none;
}

.widget-custom-new {
    margin-bottom: 12px;
}

/** All about media query */

@media (max-width: 991px) {
    .new-letter-desktop {
        display: none;
    }

    .new-letter-mobile {
        display: flex;
    }
}

@media (max-width: 767px) {
    .clearfix {
        justify-content: flex-start;
    }

    .new-letter-desktop-2 {
        display: none;
    }
}

@media (min-width: 768px) {
    .app-list a {
        min-width: 158px;
    }

    .border-sm-right {
        border-right: 1px solid #dee2e6 !important;
    }

    .productFilter .visible-sm {
        display: block;
    }

    .productFilter .mobileFilter {
        opacity: 1;
    }

    .form-warranty-hr {
        width: 50% !important;
        margin: 0 auto !important;
        margin-top: 20px !important;
    }
}

.container {
    width: 100% !important;
}

@media (min-width: 1367px) {
    .redirect-loading-container-success {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 80vh;
        width: 80vw;
    }

    .left-cell-row {
        min-width: 470px;
        width: 100% !important;
    }
}

@media (max-width: 767px) {
    .card-form-center {
        width: 100% !important;
        position: relative;
        margin: 0 auto;
        padding-bottom: 10px !important;
    }

    .side-table-custom-seminar {
        display: none;
    }

    .update-form-center {
        width: 100% !important;
    }

    .update-form-center-title {
        padding-top: 20px;
    }

    .mobile-justify-content-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
        margin-top: 23px !important;
    }

    .padding15 {
        padding: 15px;
        padding-top: 0px;
    }

    .marginTop0 {
        margin-top: 0px !important;
    }

    .customer-details {
        margin-top: 30px;
    }

    .padding-title-look-forward {
        padding-top: 4px !important;
    }

    .form-warranty-hr {
        width: 100% !important;
        margin: 0 auto !important;
        margin-top: 20px !important;
    }

    .form-warranty-center {
        width: 100% !important;
    }

    .alert-marginb {
        margin-bottom: 20px;
        width: 100% !important;
    }

    .alert-padding {
        padding: 0px !important;
    }

    .label-dsk-mb {
        text-align: left !important;
    }

    .custom-checkbox-dsk {
        margin-left: 16px !important;
        width: 100% !important;
    }

    .custom-checkbox-dska {
        margin-left: 5px !important;
        width: 320px !important;
    }

    .custom-checkbox-dska-label {
        padding-left: 24px !important;
        text-align: left;
    }

    .dropdown-healthcheck {
        width: 100% !important;
    }

    .top-rank-table-seminar {
        display: none;
    }
}

@media (max-width: 1280px) {
    .promotion {
        text-align: center;
    }

    .promotion > img {
        object-fit: contain;
    }

    .slider-button {
        padding: 10px 15px;
    }
}

@media (max-width: 1200px) {
    .sliderImg {
        width: auto;
        height: 315px;
        overflow: hidden;
        z-index: 2;
        background-size: cover;
        position: relative;
        top: 27px;
    }

    .container#cat-sort {
        max-width: fit-content !important;
    }
}

@media screen and (max-width: 2560px) {
    .sliderImg {
        width: auto;
        height: 315px;
        overflow: hidden;
        z-index: 2;
        background-size: cover;
        position: relative;
        top: 27px;
    }
}

@media only screen and (min-width: 1024px) {
    .smaller-text {
        font-size: 13px;
        height: 33px;
        max-width: 84px;
        padding: 5px 10px;
    }

    .global-connected-button-left {
        margin-left: 10px;
    }

    .left-cell-row {
        min-width: 380px;
    }
}

@media only screen and (min-width: 1539px) {
    .left-cell-row {
        min-width: 474px !important;
    }
}

@media only screen and (min-width: 1025px) {
    .smaller-text {
        font-size: 13px;
        height: 33px;
        max-width: 120px;
        padding: 5px 20px;
    }

    .col-11.success-fix-width {
        flex: 0 0 93%;
        max-width: 93%;
    }

    .left-cell-row {
        width: 100% !important;
    }
}

@media screen and (max-width: 1024px) {
    .left-cell-row {
        min-width: 320px;
        width: 100% !important;
    }

    .sliderImg {
        width: auto;
        height: 315px;
        overflow: hidden;
        z-index: 2;
        background-size: cover;
        position: relative;
        top: 27px;
    }

    .slick-list {
        position: initial !important;
    }

    .topbar.route-wrapper {
        padding-right: 15px;
        padding-left: 15px;
    }

    header .site-menu {
        display: block !important;
    }

    .success-badge {
        margin-right: 0px;
    }

    .gallary-container {
        height: auto;
    }

    td.product-button {
        width: 25% !important;
        padding: 0px;
    }
}

/* @media screen and (min-width: 1680px) {
    .slick-slide {
        height: 645px !important;
    }
} */

@media screen and (min-width: 2100px) {
    .QtyDetail input {
        position: relative;
        bottom: 0px;
    }

    .detail-image {
        margin-left: 21px;
    }
}

@media screen and (min-width: 2300px) {
    .QtyDetail input {
        position: relative;
        bottom: 0px;
    }
}

@media (max-width: 992px) {
    .hipster-dude {
        margin-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .site-menu {
        display: block !important;
        position: unset;
    }

    .gallary-cells {
        align-items: center;
        justify-content: flex-end;
    }

    .gallary-cell-bottom {
        justify-content: flex-start !important;
    }

    .gallary-cell-bottom-image {
        margin-top: 20px;
    }

    .s-alert-box {
        width: 100%;
        max-width: unset !important;
        left: 0 !important;
        right: 0 !important;
    }

    /*.widget.widget-light-skin {
         text-align: center!important
    }*/
    .app-list img {
        width: 60% !important;
    }

    main .site-menu {
        display: block !important;
        height: 40px;
    }

    main .site-menu > ul > li > a {
        display: block;
    }

    .qs-row {
        margin-right: 70px;
    }

    .col-11.success-fix-width {
        flex: 0 0 95%;
        max-width: 85%;
    }

    .sort-by-ov {
        width: 52%;
    }

    .product-card .product-thumb {
        display: block;
        width: 100%;
        padding: 24px;
    }

    .slick-initialized .slick-slide .product-card .product-thumb {
        height: 200px;
    }

    .sp-mb {
        padding-left: 0px;
        padding-right: 0px;
    }

    #section {
        padding: 0px 15px !important;
    }

    #section-sm {
        padding: 0px !important;
    }
}

@media screen and (min-width: 421px) and (max-width: 640px) {
    .global-connected-button-right,
    .global-connected-button-left {
        padding: 5px 20px !important;
    }
}

@media screen and (min-width: 640px) {
    .toolbar {
        right: 15px;
    }

    .detail-btn {
        margin-bottom: 15px;
        white-space: nowrap;
    }
}

@media screen and (min-width: 500px) {
    .gene-search {
        width: 100%;
    }

    .global-connected-button-right,
    .global-connected-button-left {
        padding: 5px 20px;
    }
}

@media screen and (max-width: 480px) {
    .productDescription {
        text-align: justify;
        margin-top: 20px;
        margin-right: 25px;
        margin-left: 21px;
    }

    .report-btn {
        padding: 6px;
        padding-right: 10px;
        text-align: center;
    }

    .shopping-cart-footer > .btn-success {
        margin-right: 0;
    }

    .payments {
        width: 320px;
        background-position-x: -122px;
    }

    .lang-currency-switcher-wrap {
        margin-right: 0px;
        margin-left: 0px;
    }

    .lang-currency-switcher-wrap.login-wrap .dropdown-menu {
        position: fixed;
        top: 225%;
        left: 34%;
        margin-top: -50px;
        margin-left: -100px;
        width: 320px;
    }

    .qs-quicknav {
        width: 25px;
    }

    .qs-row {
        margin-right: 30px;
    }

    .small-no-pad {
        padding: 0px;
        padding-right: 5px;
    }

    .rank-row {
        position: relative;
        top: -43px;
        left: 0px;
    }

    .rank-row.new-line {
        top: -20px;
    }

    .rank-badges .slick-slide.slick-active {
        top: 43px;
        position: relative;
        left: 0px;
    }

    .font-size-genealogy-screen {
        font-size: 1.5rem;
    }

    #qs-row-mob {
        margin-right: 15px !important;
    }

    .qs-row {
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 414px) {
    .QtyDetail button > i {
        position: relative;
        right: 5px;
        bottom: 2px;
    }

    .suggested-slider .slick-dots {
        bottom: 45px !important;
        width: auto !important;
        left: 15% !important;
        right: 15%;
        background: none;
        cursor: pointer;
    }

    .suggested-slider-mobile .slick-dots {
        bottom: 45px !important;
        width: auto !important;
        left: 15% !important;
        right: 15%;
        background: none;
        cursor: pointer;
    }

    .button-product-detail {
        margin-left: 20px;
    }

    .single-product-title {
        font-size: 30px;
        color: black;
        margin-left: 20px;
    }

    .single-product-price {
        font-size: 30px;
        color: black;
        font-weight: 500;
        margin-left: 20px;
    }

    .detail-image {
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .sliderImgBios {
        width: auto;
        height: 150px;
        overflow: hidden;
        z-index: 2;
        background-size: cover;
        position: relative;
        top: 55px;
    }

    .sliderImgUni {
        width: auto;
        height: 155px;
        overflow: hidden;
        z-index: 2;
        background-size: cover;
        position: relative;
    }

    .rank-row {
        position: relative;
        top: -43px;
        left: 0px;
    }

    .rank-badges .slick-slide.slick-active {
        top: 43px;
        position: relative;
        left: 0px;
    }

    .profile-pic-side {
        height: 60px;
        width: 60px;
        border-radius: 14px;
        vertical-align: middle;
        padding: 1px;
    }

    .success-position {
        position: relative;
        right: 25px;
    }

    .sliderImg {
        position: relative;
        height: 270px;
    }

    .sort-by-ov {
        width: 52%;
    }

    .genealogy-page-wrapper .btn-xs {
        padding: 5px 30px;
    }

    .wordWrap {
        word-wrap: break-word;
        width: 140px;
    }

    .col-11.success-fix-width {
        flex: 0 0 90%;
        max-width: 87%;
    }

    .t-sm {
        font-size: 79%;
    }
}

@media screen and (max-width: 375px) {
    .QtyDetail button > i {
        position: relative;
        right: 5px;
        bottom: 2px;
    }

    .suggested-slider .slick-dots {
        bottom: 65px !important;
        width: auto !important;
        left: 15% !important;
        right: 15%;
        background: none;
        cursor: pointer;
    }

    .suggested-slider-mobile .slick-dots {
        bottom: 65px !important;
        width: auto !important;
        left: 15% !important;
        right: 15%;
        background: none;
        cursor: pointer;
    }

    .button-product-detail {
        margin-left: 20px;
    }

    .single-product-title {
        font-size: 30px;
        color: black;
        margin-left: 20px;
    }

    .single-product-price {
        font-size: 30px;
        color: black;
        font-weight: 500;
        margin-left: 20px;
    }

    .detail-image {
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .sliderImgBios {
        width: auto;
        height: 100px;
        overflow: hidden;
        z-index: 2;
        background-size: cover;
        position: relative;
        top: 55px;
    }

    .sliderImgUni {
        width: auto;
        height: 135px;
        overflow: hidden;
        z-index: 2;
        background-size: cover;
        position: relative;
    }

    .rank-row {
        position: relative;
        top: -43px;
        left: 0px;
    }

    .rank-badges .slick-slide.slick-active {
        top: 43px;
        position: relative;
        left: 0px;
    }

    .profile-pic-side {
        height: 60px;
        width: 60px;
        border-radius: 14px;
        vertical-align: middle;
        padding: 1px;
    }

    .success-position {
        position: relative;
        right: 25px;
    }

    .sliderImg {
        width: auto;
        height: 270px;
        overflow: hidden;
        z-index: 2;
        background-size: cover;
        position: relative;
    }

    .sort-by-ov {
        width: 47%;
    }

    .genealogy-page-wrapper .btn-xs {
        padding: 5px 33px;
    }

    .wordWrap {
        word-wrap: break-word;
        width: 120px;
    }

    .EN.order-deadline {
        width: 75%;
    }
}

@media screen and (max-width: 360px) {
    .modal-content {
        min-width: 0px !important;
    }

    .offcanvas-toggle {
        width: 40px;
    }

    .block-nav-menu-icon {
        width: 40px;
    }

    .sort-by-ov {
        width: 45%;
    }

    .genealogy-page-wrapper .btn-xs {
        padding: 5px 30px;
    }
}

@media screen and (max-width: 320px) {
    /* .offcanvas-toggle {
        width: 40px;
    } */
    .topbar .topbar-column:first-child > a:not(.social-button),
    .topbar .topbar-column:first-child > span,
    .topbar .topbar-column:first-child > p {
        margin-right: 15px;
    }

    .sort-by-ov {
        width: 37%;
    }

    .genealogy-page-wrapper .btn-xs {
        padding: 5px 20px;
    }

    .genealogy-page-wrapper button.hidden-md-up.btn {
        padding: 0 13px;
    }

    .info-btn {
        /* padding: 8px 13px */
    }

    .status-button {
        padding: 5px;
    }
}

@media screen and (max-width: 346px) {
    .QtyDetail button > i {
        position: relative;
        right: 5px;
        bottom: 2px;
    }

    .refreshBtn {
        margin-bottom: 10px;
    }

    .detail-image {
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .single-product-title {
        font-size: 30px;
        color: black;
        margin-left: 20px;
    }

    .single-product-price {
        font-size: 30px;
        color: black;
        font-weight: 500;
        margin-left: 20px;
    }

    .button-product-detail {
        margin-left: 20px;
    }
}

@media screen and (max-width: 320px) {
    .QtyDetail button > i {
        position: relative;
        right: 5px;
        bottom: 2px;
    }

    .suggested-slider .slick-dots {
        bottom: 100px !important;
        width: auto !important;
        left: 15% !important;
        right: 15%;
        background: none;
        cursor: pointer;
    }

    .suggested-slider-mobile .slick-dots {
        bottom: 100px !important;
        width: auto !important;
        left: 15% !important;
        right: 15%;
        background: none;
        cursor: pointer;
    }

    .detail-image {
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .single-product-title {
        font-size: 30px;
        color: black;
        margin-left: 20px;
    }

    .single-product-price {
        font-size: 30px;
        color: black;
        font-weight: 500;
        margin-left: 20px;
    }

    .button-product-detail {
        margin-left: 10px;
    }

    .sliderImgUni {
        width: auto;
        height: 106px;
        overflow: hidden;
        z-index: 2;
        background-size: cover;
        position: relative;
    }

    .topbar .topbar-column {
        width: auto;
    }

    /*.topbar .topbar-column-left {
        width: 60%;
    }*/
    /*.topbar .topbar-language-column {
        width: 40%;
        float:right;
    }*/
    .sliderImg {
        position: relative;
        height: 250px;
    }

    .drop-login {
        right: 100px;
        width: 250px;
    }

    .drop-login a {
        font-size: 14px;
    }

    .topbar.route-wrapper {
        padding-right: 15px;
        padding-left: 15px;
    }

    .lang-currency-switcher-wrap {
        margin-left: 8px;
    }

    .lang-currency-switcher-wrap .dropdown-menu {
        width: 200px;
        left: -120px;
    }

    .language.lang-currency-switcher-wrap .dropdown-menu {
        width: 100px;
    }

    .slick-list {
        top: 120px;
    }

    .img-container {
        top: -50px;
    }

    .scroll-to-top-btn {
        width: 44px;
        height: 44px;
        right: 32px !important;
    }

    .scroll-to-top-btn.visible {
        bottom: 40px;
        opacity: 0.7;
    }

    .page-title {
        padding: 15px 10px;
    }

    .menu-container,
    #menu-container {
        width: 250px;
    }

    .enroll-form .quick-shop {
        margin-top: 80px;
    }

    .widget-categories > ul > li {
        margin-left: -15px;
    }

    .quick-shop .widget-categories > ul > li > ul {
        margin-left: 10px;
    }

    .home-row-buttons .global-connected-button-left,
    .home-row-buttons .global-connected-button-right,
    .home-row-buttons .global-connected-button-middle,
    .home-row-buttons .global-connected-button-active {
        padding: 2px 10px;
    }

    .app-list img {
        width: 80%;
    }

    .toolbar {
        right: 5px;
    }

    .payments {
        width: 100% !important;
        background-position-x: -155px;
    }

    .app-list {
        text-align: left;
        display: block;
        padding-bottom: 10px;
    }

    .lang-currency-switcher-wrap.login-wrap .dropdown-menu {
        position: fixed;
        top: 225%;
        left: 34%;
        margin-top: -50px;
        margin-left: -100px;
        width: 300px;
    }

    .rank-row {
        position: relative;
        top: -43px;
        left: 0px;
    }

    .rank-badges .slick-slide.slick-active {
        top: 43px;
        position: relative;
        left: 0px;
    }

    .checkout h5 {
        font-size: 16px;
    }

    .qtyplusminusbtn {
        padding: 0px 16px;
    }

    .wordWrap {
        word-wrap: break-word;
        width: 100px;
    }
}

@media screen and (max-width: 550px) {
    .detail-image {
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .single-product-title {
        font-size: 30px;
        color: black;
        margin-left: 20px;
    }

    .single-product-price {
        font-size: 30px;
        color: black;
        font-weight: 500;
        margin-left: 20px;
    }

    .genealogy-table {
        width: 300%;
    }

    .genealogy-table2 {
        width: 300%;
    }

    .genealogy-table > thead > tr > th {
        padding: 0px;
    }

    .genealogy-table2 > thead > tr > th {
        padding: 0px;
    }

    .genealogy-table > tbody > tr > td {
        padding: 0px;
    }

    .genealogy-table2 > tbody > tr > td {
        padding: 0px;
    }
}

.promotion {
    min-height: 480px;
}

@media screen and (max-width: 550px) {
    .promotion {
        min-height: 100px;
    }
}

.edit-button {
    color: #0c9fed;
    background-color: white;
}

.edit-button-hover {
    color: #097cb7;
    background-color: white;
}

/* Shop Profile */

.shopprofile-tab .padding-top-1x {
    padding-top: 20px !important;
}

/* referral link */

.referral-link-tab .padding-top-1x {
    padding-top: 125px !important;
}

/* BIR styles  */

.bir-link-tab .padding-top-1x {
    padding-top: 125px !important;
}

/* .genealogy-page-wrapper .btn:hover,
.genealogy-page-wrapper .btn:focus,
.genealogy-page-wrapper .btn:active {
    background-color: #83d1f2;
    box-shadow: none;
    border-color: #83d1f2
} */

.genealogy-page-wrapper .btn-outline-secondary:hover {
    background-color: #f5f5f5;
}

.genealogy-page-wrapper .btn-info {
    background-color: #2bb8f4;
}

.text-small.btn.btn-info.btn-show-all-less.bordershow-all-less,
.text-small.btn.btn-info.btn-show-all-less {
    background-color: #2bb8f4 !important;
}

.genealogy-page-wrapper .btn-secondary:active,
.genealogy-page-wrapper .btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
    background-color: #f5f5f5;
    border-color: transparent;
    box-shadow: none;
    color: #606975;
}

.genealogy-page-wrapper .btn-secondary:hover {
    border-color: #c9cccf;
}

.genealogy-page-wrapper .btn-info:hover {
    border-color: #2bb8f4;
}

.genealogy-page-wrapper .btn-info:active,
.genealogy-page-wrapper .btn-info:focus,
.btn-info:not(:disabled):not(.disabled):active:focus {
    background-color: #2bb8f4;
    border-color: transparent;
    box-shadow: none;
}

.dropdown-menu::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(#3bb3e8),
        to(#28abe4)
    );
    background-image: -webkit-linear-gradient(bottom, #3bb3e8 0%, #28abe4 100%);
    background-image: linear-gradient(to top, #3bb3e8 0%, #28abe4 100%);
}

@media (max-width: 767px) {
    .referral-link-tab .padding-top-1x {
        padding-top: 15px !important;
    }

    .bir-link-tab {
        padding-top: 15px !important;
    }
}

@media (max-width: 725px) {
    .autoship .cart-img {
        width: auto;
    }

    .mob-cart-img {
        display: block;
    }

    .mob-cart-img img {
        width: auto;
        height: auto;
        max-height: 115px;
        max-width: 135px;
    }

    .shopping-cart .product-button label {
        margin: 0px;
        font-family: Prompt, sans-serif;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .genealogy-page-wrapper .btn-xs {
        padding: 5px 20px;
    }
}

@media (min-width: 1280px) {
    .genealogy-page-wrapper .btn-xs {
        padding: 5px 30px;
    }

    .gene-search {
        max-width: 180px !important;
    }

    .gene-search-button {
        max-width: 100% !important;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .genealogy-page-wrapper .btn-xs {
        padding: 5px 10px;
    }

    .gene-search {
        max-width: 180px !important;
    }

    .gene-search-button {
        max-width: 100% !important;
    }
}

@media (max-width: 1334px) {
    .autoship .cart-img {
        /* width: auto; */
    }

    .mob-cart-img {
        display: block;
    }

    .mob-cart-img img {
        width: auto;
        height: auto;
        max-height: 115px;
        max-width: 135px;
    }

    .shopping-cart .product-button label {
        margin: 0px;
        font-family: Prompt, sans-serif;
    }
}

/* enroll */

.enrollment-page .dropdown-menu {
    min-width: 15rem;
}

.enrollment-page .disabled.dropdown-item:hover {
    color: #000000 !important;
}

@media (max-width: 897px) and (min-width: 321px) {
    .enrollment-page .qs-add-to-cart,
    .enrollment-page .qs-out-of-stock {
        font-size: 11px;
        padding: 5px 5px;
    }
}

@media (max-width: 330px) {
    .enrollment-page .qs-add-to-cart,
    .enrollment-page .qs-out-of-stock {
        padding: 3px 5px;
    }
}

@media (max-width: 860px) and (min-width: 767px) {
    .enrollment-page .qs-add-to-cart,
    .enrollment-page .qs-out-of-stock {
        font-size: 9px;
    }

    .shopping-cart .product-button {
        width: 21% !important;
    }

    .order-deadline.EN.t-sm {
        font-size: 87%;
    }
}

.enroll-qs-row .container {
    width: auto;
}

.enroll-qs-row {
    margin-right: 30px;
    padding-bottom: 5px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
    .enroll-qs-row {
        margin-right: 70px;
    }
}

@media screen and (max-width: 480px) {
    .enroll-qs-row {
        margin-right: 30px;
        margin-left: 0;
    }
}

.enroll-profile-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 300px;
    box-shadow: 0 1px 8px 0 rgba(56, 56, 56, 0.2);
}

.view-ba.profileCard {
    width: auto;
}

@media (max-width: 330px) {
    #btn-leave-enroll-cancel,
    #btn-leave-enroll-ok {
        font-size: 10px;
    }
}

.enroll-summary {
    line-height: 1.8;
}

.enroll-summary h5 {
    padding-left: 5px;
}

.enroll-summary table {
    width: auto;
}

.enroll-summary td {
    vertical-align: top;
    font-size: inherit;
    padding: 0px 0 0 5px;
}

.enroll-summary tr td:first-child {
    /* white-space: nowrap; */
}

.enroll-summary .info-field-title {
    /* width: 130px; */
}

.enroll-summary .contact-field-title {
    /* width: 85px; */
}

.co-info td.company {
    width: 125px;
}

td.text-muted.spouse {
    min-width: 121px;
}

@media screen and (max-width: 767px) {
    .enroll-summary td {
        min-width: 135px;
        word-break: break-word;
    }

    .enroll-summary .enroll-summary-price_col td {
        min-width: 125px;
    }

    .enroll-summary.warranty td {
        min-width: 145px;
        word-break: break-word;
    }

    .maintainLabel {
        font-size: 11vw;
    }

    ::-webkit-scrollbar {
        display: none;
    }
}

body.show-nav ::-webkit-scrollbar,
body.show-cart ::-webkit-scrollbar {
    display: none;
}

.youtube-foreground {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /*transform: translate(-50%, -50%);*/
    background: rgba(0, 0, 0, 0.001) url(../assets/screen.png);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.button-center {
    display: flex;
    align-items: center;
}

.filter {
    font-size: 24px;
    position: absolute;
    padding-bottom: 20px;
    right: 30px;
    color: #999999;
    text-decoration: none;
    -webkit-appearance: button;
    background: none;
    border: 0px;
}

.filter-on {
    font-size: 24px;
    position: absolute;
    padding-bottom: 20px;
    right: 30px;
    color: #25b0f3;
}

.filter-off {
    font-size: 24px;
    position: absolute;
    padding-bottom: 20px;
    right: 30px;
    color: #999999;
}

.maintenance {
    font-family: Prompt, sans-serif;
}

.maintainanceBg {
    background-image: url('../assets/coming_soon.jpg');
}

.mobileBannerContent {
    text-align: right;
    margin-bottom: 8%;
    margin-right: 10%;
}

.mobileFilter {
    opacity: 0;
    max-height: 0;
}

.show {
    opacity: 1;
    /*max-height: 3000px;*/
}

/*Sathya UI - II*/

.fb_dialog
    .fb_dialog_mobile
    .fb_customer_chat_bubble_animated_no_badge
    .fb_customer_chat_bubble_pop_in {
    bottom: 50pt !important;
}

.bottom-50 .slick-dots {
    bottom: 50px !important;
}

.no-border-radius {
    border-radius: 0px !important;
}

.no-padding-imp {
    padding: 0px !important;
}

.no-margin-imp {
    margin: 0px !important;
}

.list-pills:first-child {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.spinner-align {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
}

.display-none {
    display: none !important;
}

/** Youtube Loader CSS **/

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.lds-css {
    left: calc(50% - 37px);
    top: calc(48% - 37px);
    position: absolute;
}

.lds-spinner {
    position: relative;
}

.lds-spinner div {
    left: 96px;
    top: 42px;
    position: absolute;
    -webkit-animation: lds-spinner linear 1s infinite;
    animation: lds-spinner linear 1s infinite;
    background: #ffffff;
    width: 8px;
    height: 36px;
    border-radius: 20%;
    -webkit-transform-origin: 4px 58px;
    transform-origin: 4px 58px;
}

.lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-delay: -0.916666666666667s;
    animation-delay: -0.916666666666667s;
}

.lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-animation-delay: -0.833333333333333s;
    animation-delay: -0.833333333333333s;
}

.lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-delay: -0.75s;
    animation-delay: -0.75s;
}

.tab-text-desktop {
    font-size: 14px !important;
}

.tab-text-mobile {
    font-size: 11px !important;
    padding: 10px 12px !important;
}

.lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation-delay: -0.666666666666667s;
    animation-delay: -0.666666666666667s;
}

.lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
    -webkit-animation-delay: -0.583333333333333s;
    animation-delay: -0.583333333333333s;
}

.lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-delay: -0.416666666666667s;
    animation-delay: -0.416666666666667s;
}

.lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
    -webkit-animation-delay: -0.333333333333333s;
    animation-delay: -0.333333333333333s;
}

.lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
}

.lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-animation-delay: -0.166666666666667s;
    animation-delay: -0.166666666666667s;
}

.lds-spinner div:nth-child(11) {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
    -webkit-animation-delay: -0.083333333333333s;
    animation-delay: -0.083333333333333s;
}

.lds-spinner div:nth-child(12) {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.lds-spinner {
    width: 75px !important;
    height: 75px !important;
    -webkit-transform: translate(-37.5px, -37.5px) scale(0.375)
        translate(37.5px, 37.5px);
    transform: translate(-37.5px, -37.5px) scale(0.375)
        translate(37.5px, 37.5px);
}

/** Youtube Loader CSS Ends**/

.youtube-logo {
    bottom: 17px;
    right: 17px;
    position: absolute;
    width: 108px;
}

/* Show/Hide Password*/

.showPass {
    float: right;
    margin-top: -30px;
    margin-right: 10px;
    font-size: 1.2em;
}

/** Firefox specific CSS **/

@-moz-document url-prefix() {
    .enrollment-page .dropdown-toggle::after {
        margin-top: -23px !important;
        float: right !important;
    }

    .floatLeft {
        float: left;
    }
}

.currency {
    padding: 4px 0 4px 12px;
    border-left: 1px solid #e1e7ec;
    font-size: 14px;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.custom-control.custom-checkbox {
    display: inline-block;
}

.loaderLogin .modal-content {
    background-color: transparent !important;
    border: none !important;
}

.tbTrip > table > tbody > tr > th {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
}

th.thTitle {
    background-color: #374250 !important;
}

th.thMonth {
    background-color: #838a92 !important;
}

.tbTrip {
    /* Rectangle: */
    border: 0.5px solid #e1e7ec;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    perspective: 1px;
    border-radius: 5px;
    border-collapse: separate;
}

.download-btn {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    height: 5px;
    line-height: 5px;
    color: #ffffff;
    width: 100px;
    font-size: 16px;
    font-weight: 100;
    padding: 15px;
    background-color: #0da9ef;
    border: solid #0da9ef 1px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}

.downloadRow {
    border: 0.2px solid #e1e7ec;
    background: #ffffff;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1),
        0 2px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1),
        1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    -webkit-perspective: 1px;
    perspective: 1px;
    border-radius: 5px;
    border-collapse: separate;
}

.seminar-btn {
    /* Rectangle 4: */
    background: #37a0e2;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 94.5px;
}

.tbTrip > table > tbody > tr > td {
    font-size: 18px;
    font-weight: bold;
    color: #606975;
}

.th-commission-dropdown {
    min-width: 155px;
    border-radius: 5px;
}

.th-shipping-dropdown {
    min-width: 160px;
    border-radius: 5px;
}

.th-commission-dropdown .icon-circle-check,
.th-shipping-dropdown .icon-circle-check {
    margin-bottom: 5px;
    padding-right: 3px;
}

/************ SWITCH ***********/

.switchButton {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    /* top: 10px; */
    margin-right: 15px;
}

.switchButton input {
    display: none;
}

/************ SWITCH BUTTON SMALL***********/

.switchButtonSmall {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 19px;
    /* top: 10px; */
    margin-right: 5px;
}

.switchButtonSmall input {
    display: none;
}

.sliderButtonSmall {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.sliderButtonSmall:before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .sliderButtonSmall {
    background-color: #2196f3;
}

input:focus + .sliderButtonSmall {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderButtonSmall:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

.sliderButtonSmall.round {
    border-radius: 34px;
}

.sliderButtonSmall.round:before {
    border-radius: 50%;
}

input:checked + .sliderButtonSmall:after {
    font-family: feather;
    font-size: 17px;
    content: '';
    padding-left: initial;
    top: 2px;
    position: absolute;
    left: 9px;
    color: white;
}

.switch-confirm-container td {
    padding: 0px;
}

@media (max-width: 414px) {
    .switch-confirm-container {
        text-align: left;
        padding-left: 0px;
    }

    .switch-confirm-container .custom-control {
        padding-left: 0px !important;
    }
}

.sliderButton {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.sliderButton:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .sliderButton {
    background-color: #2196f3;
}

input:focus + .sliderButton {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderButton:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.sliderButton.round {
    border-radius: 34px;
}

.sliderButton.round:before {
    border-radius: 50%;
}

/* input:checked+.sliderButton:after {
    font-family: feather;
    font-size: 17px;
    content: "\e116";
    padding-left: initial;
    top: 2px;
    position: absolute;
    left: 9px;
    color: white;
} */

.dropdown-button .btn-primary:hover {
    background-color: #0da9ef;
    border-color: #e1e7ec;
}

.dropdown-button .btn-outline-secondary:hover {
    background-color: white;
    border-color: #e1e7ec;
}

p.limit_age {
    padding-top: 40px;
    padding-left: 8px;
}

.confirm-box p {
    min-height: 85px;
}

@media (max-width: 576px) {
    p.limit_age {
        padding-top: 0;
    }

    .confirm-box p {
        min-height: auto;
    }
}

@media (min-width: 577px) {
    .border-sx-right {
        border-right: 1px solid #dee2e6;
    }
}

.cart-button {
    align-items: center;
    display: flex;
}

.thMonth {
    background-color: #838a92 !important;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:focus {
    box-shadow: none;
}

.btn-danger:not(:disabled):not(.disabled):active {
    border-color: #bd2230;
}

.checkout-page.page-title {
    margin-bottom: 0;
}

.checkout-page.page-title .column:first-child {
    padding-right: 0;
}

.alert-primary {
    color: #606975;
}

.alert-primary span {
    cursor: pointer;
}

#frmPaymentShopping .form-control {
    font-size: 13px;
}

.expiry-date .dropdown-menu {
    min-width: auto;
    text-align: center;
    width: 87%;
}

.expiry-date .btn-outline-primary {
    width: 100%;
    text-align: center;
    font-weight: normal;
}

.expiry-date .btn-outline-primary {
    color: #606975;
    border: 1px solid #dbe2e8;
}

.expiry-date .btn-outline-primary:not(:disabled):not(.disabled):active,
.expiry-date .btn-outline-primary:hover {
    color: #606975;
    background-color: rgb(255, 255, 255);
    border-color: #dbe2e8;
}

.expiry-date .btn-outline-primary:focus {
    box-shadow: none;
}

.expiry-date .pills {
    margin-top: 0;
}

.expiry-date .month .pills {
    margin-left: 0;
}

/*** OTP ***/

.request-otp-link-disabled {
    border: none;
    background: none;
}

.request-otp-link-enabled {
    border: none;
    color: #0097fa;
    text-decoration: underline;
    cursor: pointer;
    background: none;
}

.resend-otp-done {
    color: #0097fa;
}

.pay-bankwire {
    width: 600px;
    margin: 0 auto;
}

.maxWidth100 {
    max-width: 100% !important;
}

.posRelative {
    position: relative;
}

.bottomMargin16 {
    margin: 0px 0px 16px 0px;
    right: 0;
}

.timeGeneogy {
    text-align: center;
    padding-bottom: 20px !important;
    font-weight: bold;
}

.iconGeneogy {
    text-align: center !important;
}

.btn-show-all-less {
    height: 32px;
    padding: 0px 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.bordershow-all-less {
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
}

.bordershow-all-less-small-font {
    font-size: 1.5vw;
}

.btnF {
    float: none !important;
}

.tableSe {
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid #dddddd;
}

.tableSe:nth-last-child(1) {
    border-bottom: none;
}

.expand-iconGeo {
    font-size: 15px;
    padding-right: 10px;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
    .iconGeneogy {
        text-align: right !important;
    }

    .timeGeneogy {
        text-align: left;
        padding-bottom: 0px;
        font-weight: normal;
    }

    .tableSe {
        padding-bottom: 5px;
        padding-top: 5px;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
    }

    /*.tableSe:nth-child(1){
        border-top: none;
    }
    .tableSe:nth-child(2){
        border-top: none;
    }*/
    .tableSe:nth-last-child(2n) {
        border-right: none !important;
    }

    .tableSe:nth-last-child(2n + 1) {
        border-right: none !important;
    }
}

/* // Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .timeGeneogy {
        text-align: left;
        padding-bottom: 0px;
        font-weight: normal;
    }

    .iconGeneogy {
        text-align: right !important;
    }

    .padding-title-look-forward {
        padding-top: 24px !important;
    }

    .top-rank-table-seminar-dsk {
        display: none;
    }
}

.fa-4 {
    font-size: 1.2em;
}

.embed-shadow {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
}

.embed-shadow :hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0), 2px 5px 0 rgba(0, 0, 0, 0) !important;
    border-radius: 10px !important;
}

.bar:hover: {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
}

.embed-responsive .embed-responsive-16by9 .embed-shadow :hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
}

.shadow-em {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
}

/* .user-info-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: -1px;
    padding-top: 90px;
    padding-bottom: 0px;
    border: 1px solid #e1e7ec !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
} */

.MenuCard {
    /* border: 1px solid #CCC; */
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(56, 56, 56, 0.2);
}

.logout-b {
    position: relative;
    width: 100%;
    height: 40px;
    justify-content: center;
    text-align: left;
    padding-left: 5px !important;
    cursor: pointer;
    border-top: 1px solid #ccc !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px !important;
    border-bottom: 0px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.logout-b:nth-child(2n + 1) {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 0px 5px;
    justify-content: center;
    text-align: left;
    cursor: pointer;
    border-top: 0px solid #ccc !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom: 1px solid #ccc;
}

.logout-b.active {
    /* border-color: #0da9ef; */
    background-color: #0da9ef;
    color: #fff;
    border-top-left-radius: 7px !important;
    border-bottom: 1px solid #ccc !important;
    border-top-right-radius: 7px !important;
}

.logout-b:nth-last-child(even) {
    position: relative;
    width: 100%;
    height: 40px;
    justify-content: center;
    text-align: left;
    padding: 0px 5px;
    cursor: pointer;
    border-top: 0px solid #ccc !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px !important;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.logout-b.active:last-child {
    /* border-color: #0da9ef; */
    background-color: #0da9ef;
    color: #fff;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

@media only screen and (min-width: 750px) and (max-width: 980px) {
    .seminar-side-tabs {
        height: 60px !important;
    }

    .logout-b:nth-last-child(odd) {
        position: relative;
        width: 100%;
        height: 60px;
        padding: 0px 5px;
        justify-content: center;
        text-align: left;
        cursor: pointer;
        border-top: 0px solid #ccc !important;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px !important;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }
}

.sp-desk {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.menu-child .logout-b:nth-last-child(odd) {
    position: relative;
    width: 100%;
    height: 40px;
    justify-content: center;
    text-align: left;
    cursor: pointer;
    border-top: 0px solid #ccc !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px !important;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: none !important;
}

.sem-hide {
    display: none !important;
}

.tbTrip-table {
    border: 0px solid #e1e7ec !important;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-perspective: 1px;
    perspective: 1px;
    border-radius: 5px;
    border-collapse: separate;
}

.cart-img-shopprofile {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.logout-n:first-child {
    border-top: 0px solid #e1e7ec !important;
}

.join-date-group > div {
    display: inline;
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: block !important;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle;
}

.cursor-none {
    cursor: none !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-progress {
    cursor: progress !important;
}

.transition-100ms {
    transition: all 0.1s;
}

.transition-250ms {
    transition: all 0.25s;
}

.transition-500ms {
    transition: all 0.5s;
}

.transition-1s {
    transition: all 1s;
}

.transition-2s {
    transition: all 2s;
}

.radio-btn-c {
    margin: 0 auto !important;
    padding-top: 20px;
}

/* .nav-link {
    display: block;
    padding: 0px !important;
  } */

.btn .btn-outline-primary .dropdown-menu {
    border-color: #e1e7ec;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2);
}

.btn-outline-primary {
    border-color: #0da9ef !important;
    background-color: transparent !important;
    color: #0da9ef !important;
}

.btn-outline-primary:hover {
    border-color: #0da9ef !important;
    background-color: #0da9ef !important;
    color: #fff !important;
}

.btn-outline-secondary {
    border-color: #e1e7ec;
}

.btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
}

.nav-link {
    display: block;
    padding: 0px !important;
}

.nav-item {
    margin-right: 12px;
}

.nav-tabs .nav-link {
    border: 0px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin-bottom: 10px;
}

/* .icon-circle-check {
    content: "" !important;
  } */

span .icon-circle-check {
    content: '\e042' !important;
}

.dropdown-menu {
    margin: 0.1rem !important;
    border-color: #e1e7ec !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2) !important;
    width: 96%;
}

select option {
    margin: 0.4rem !important;
    border-color: #e1e7ec !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2) !important;
}

.scrollable {
    overflow: auto;
    white-space: nowrap;
    margin: 0 -15px;
}

.icon-circle-check {
    display: none !important;
}

.active .icon-circle-check {
    display: inline-block !important;
}

.btn-group {
    width: 100%;
}

.dropdown-menu {
    border-color: #e1e7ec;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2);
}

.dropdown-menu-g {
    border-color: #e1e7ec;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2);
    width: 100% !important;
}

.dropdown-menu-g {
    border-color: #e1e7ec;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2);
}

.dropdown-menu-g .dropdown-item {
    padding-right: 20px;
    padding-left: 20px;
    transition: color 0.3s;
    color: #606975;
    text-decoration: none;
}

.dropdown-menu-g .dropdown-item:hover,
.dropdown-menu-g .dropdown-item.active,
.dropdown-menu-g .dropdown-item:focus,
.dropdown-menu-g .dropdown-item:active {
    background: 0;
}

.dropdown-menu-g .dropdown-item:hover {
    color: #0da9ef;
}

.dropdown-menu-g .dropdown-item.active {
    color: #0da9ef;
}

.dropdown-menu-g a.dropdown-item {
    font-weight: 500;
}

.success-card .dropdown-toggle::after {
    border: 0px;
}

.dropdown-toggle::after {
    margin-top: 1px;
    margin-left: 0.3em;
    vertical-align: 0.2em;
}

.btn.dropdown-toggle::after {
    vertical-align: 0.2em;
    right: 14px;
    position: absolute;
    top: 20px;
    bottom: 0;
}

.pills .btn.dropdown-toggle {
    position: relative;
}

.show .dropdown-menu-g {
    -webkit-animation: dropdown-show 0.25s;
    animation: dropdown-show 0.25s;
}

.site-menu ul > li > a {
    padding: 0 15px !important;
    transition: color 0.3s;
    color: #606975;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}

.custom-nav-link {
    margin-bottom: 0px !important;
    height: 34px;
    padding: 4px 10px !important;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav li .show {
    background: #0097fa !important;
    color: white !important;
    padding: 0px 10px 0px 10px !important;
    border-color: #4e555b !important;
}

.nav li .th-shipping-dropdown .show .btn {
    background: #0097fa !important;
    color: white !important;
    width: 230px !important;
    max-width: 100%;
    border-radius: 5px !important;
}

.selected {
    background: #0da9ef !important;
    color: white !important;
}

.nav.save-cart li.th-shipping-dropdown {
    width: 180px !important;
    font-size: 12px;
}

.th-shipping-dropdown {
    width: 179px !important;
    border-radius: 5px !important;
}

.autoship .th-shipping-dropdown {
    width: auto !important;
}

.th-shipping-dropdown-vn-default {
    width: 200px !important;
    border-radius: 5px !important;
}

.th-shipping-dropdown-vn {
    width: 260px !important;
    border-radius: 5px !important;
}

.btn-rank-slider {
    width: 100px !important;
    border-radius: 5px !important;
    transition: all 0.15s linear;
}

.btn-rank-slider-desktop {
    width: 80px !important;
    border-radius: 5px !important;
    margin-bottom: 2px;
    transition: all 0.15s linear;
    margin: 5px 10px;
}

@media (max-width: 768px) {
    .offcanvas-wrapper {
        min-height: 90vh !important;
    }
}

/* Build cart SG */

@media (min-width: 767px) {
    .form-control-sg {
        display: block;
        width: 50% !important;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin: 0 auto !important;
        border-radius: 20px;
    }

    .lsb-colum {
        width: 153px;
    }
}

@media (min-width: 991px) {
    .form-control-sg {
        display: block;
        width: 75% !important;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin: 0 auto !important;
        border-radius: 20px;
    }
}

@media (max-width: 767px) {
    .form-control-sg {
        display: block;
        width: 100% !important;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin: 0 auto !important;
        border-radius: 20px;
    }
}

.btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    padding-right: 12px !important;
    padding-left: 12px !important;
}

.shopping-cart-footer.checkout-footer.enroll {
    display: block;
}

.shopping-cart-footer.checkout-footer.mobile {
    display: none;
}

.shopping-cart-footer.checkout-footer .btn-outline-secondary {
    padding: 0 22px !important;
}

/* @media (max-width: 414px) {
    .scrollable-position-m {
        margin: auto 0 !important;
        text-align: center;
    }
 } */

.react-datepicker {
    font-size: 1.3rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
    margin: 0.7rem !important;
}

@media (max-width: 769px) {
    .shopping-cart-footer.checkout-footer.enroll {
        display: none;
    }

    .shopping-cart-footer.checkout-footer.mobile {
        display: block;
    }
}

@media (max-width: 767px) {
    .bottom-click-button-copy-jpn {
        top: 0px !important;
        right: 50px !important;
    }

    .hidden-alert-lg {
        display: none !important;
    }

    .drp-m {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media (min-width: 767px) {
    .bottom-click-button-copy-jpn {
        top: 0px !important;
    }

    .ref-align {
        text-align: center !important;
    }

    .hidden-alert-xs {
        display: none !important;
    }
}

.btn-pricelevel-width {
    width: 100% !important;
    text-transform: capitalize;
}

#admin_cal_list {
    width: 100% !important;
}

.custom-map-card {
    border-radius: 0px 0px 7px 7px !important;
    border-top-width: 0px !important;
}

.cus-icon {
    font-family: FontAwesome !important;
}

.margin-left-3 {
    margin-left: -3px;
}

.disable-selection {
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer */
    -khtml-user-select: none;
    /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none;
    /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none;
    /* Disable Android and iOS callouts*/
}

.fab {
    font-family: 'FontAwesome' !important;
    font-weight: 900;
}

@media screen and (max-width: 480px) {
    .small-no-pad {
        padding: 0 10px 0 0;
    }
}

.height-250 {
    height: 250px !important;
}

.smaller-dropdown {
    height: 250px !important;
    overflow: scroll;
}

@media only screen and (min-width: 780px) and (max-width: 2560px) {
    .absolute-middle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.padding-left-8x {
    padding-left: 8px !important;
}

.checkbox-label-font-top {
    font-weight: bold !important;
    margin-top: -1px !important;
    padding-left: 10px !important;
}

.pills-full-width {
    width: '100%';
    text-align: 'left';
}

.pills-minimum-height {
    width: '100%';
    text-align: 'left';
}

.facebookMessenger {
    bottom: 45px;
    display: block;
    position: fixed;
    right: 32px;
    width: 44px;
    height: 44px;
    transition: bottom 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
        opacity 0.3s, background-color 0.3s, border-color 0.3s;
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
    line-height: 39px;
    opacity: 1;
    z-index: 2000;
    text-align: center;
    text-decoration: none;
}

.delivery-method {
    margin: 0 auto !important;
}

#admin_cal_list-th {
    width: 90% !important;
}

.carddiv-img-top {
    min-height: 190px;
    display: flex;
    vertical-align: middle;
    height: 100%;
}

.iframe-placeholder {
    background: url('https://www.huelva.es/portalCiudadano/portal/images/loading.gif')
        0px 0px no-repeat;
    background-size: 10%;
    background-position: center center;
    background-color: #000 !important;
}

/* MKUNG */

.quickenroll-page {
    margin-top: 30px;
    min-height: 50vh;
    margin-bottom: 30px;
}

.quickenrolltablepro {
    white-space: nowrap;
}

.btnSelect {
    height: 40px !important;
    line-height: 40px !important;
    cursor: pointer;
}

.qs-row-header {
    background-color: #ffffff;
    border-bottom-color: #ffffff;
}

.qeCard {
    margin-bottom: 8px !important;
    margin-left: 0px !important;
    min-height: 80px;
}

.qecollapse {
    margin-top: 20px;
}

@media (min-width: 480px) {
    .qeCard {
        min-height: 85px !important;
    }

    .qecollapse {
        margin-top: 15px;
    }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
    .qeCard {
        min-height: 65px !important;
    }
}

@media (min-width: 1024px) {
    .card-img-top {
        /* max-height: 178px !important;
            height: 178px; */
    }

    .carddiv-img-top {
        min-height: 270px;
        overflow: hidden;
    }
}

.qecollapse {
    border-top: 0px solid #e1e7ec;
}

.qe-title {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
}

.qenroll-summary {
    border-top: 1px solid rgb(225, 231, 236);
    padding-top: 20px;
}

.qenroll-summary {
    line-height: 1.8;
}

.qenroll-summary h5 {
    padding-left: 5px;
}

.qenroll-summary table {
    width: auto;
}

.qenroll-summary td {
    vertical-align: top;
    font-size: inherit;
    padding: 0px 0 0 5px;
}

.qenroll-summary tr td:first-child {
    white-space: nowrap;
}

.exsIMG {
    margin-bottom: 30px;
    text-align: center;
}

.signature-wrapper {
    position: relative;
    min-height: 170px;
}

.signature-pad {
    position: absolute;
    left: 0;
    top: 0;
    width: 550px;
    border: 1px solid #eeeeee;
}

.failedPage {
    margin-bottom: 45px;
}

/* END MKUNG */

.accordion .quickenroll[data-toggle='collapse']::after {
    content: none !important;
    border: none !important;
    display: none;
}

.accordion .quickenroll[data-toggle='collapse'].collapsed::after {
    content: none !important;
    border: none !important;
    display: none;
}

.quickenroll-arrow-parent {
    position: relative;
    color: rgb(220, 220, 220);
    cursor: pointer;
    font-size: 25px;
    margin: 0px;
    padding: 0px;
    margin-left: -10px;
    margin-right: 10px;
}

.quickenroll-arrow-child {
    position: absolute;
    top: 75%;
    left: 50%;
    margin: -15% 0 0 -25%;
    height: 30%;
    width: 50%;
}

.quickenroll p {
    margin-top: 0;
}

.quickenroll-button {
    position: relative;
    top: 0%;
    left: 0%;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .quickenroll-button {
        position: relative;
        top: 25%;
        left: 0%;
        width: 100%;
    }
}

/* UNIPOWER PAGE */

.unipower-container {
    text-align: center;
    margin: 0 auto;
}

/* GENEALOGY NEW TABLE */

.td-in-table {
    vertical-align: middle !important;
    max-width: 100px;
    overflow: hidden;
    height: 50px;
    border-bottom: 0px solid #e1e7ec;
    border-top: 1px solid #e1e7ec !important;
    width: 70px;
    text-align: center;
}

.table-genealogy {
    table-layout: fixed;
}

.table-genealogy::-webkit-scrollbar {
    width: 0 !important;
}

.table-genealogy {
    overflow: -moz-scrollbars-none;
}

.table-genealogy {
    -ms-overflow-style: none;
}

.table-genealogy td {
    overflow: hidden !important;
}

.full-genealogy-table-wrapper {
    background: #fff;
}

.full-genealogy-table-wrapper table {
    margin: 0px !important;
}

/* .full-genealogy-table-wrapper::-webkit-scrollbar {
    width: 0 !important;
    height: 0!important
} */

.table-genealogy-balist {
    overflow: -moz-scrollbars-none;
}

.table-genealogy-balist {
    -ms-overflow-style: none;
}

.table-genealogy td {
    overflow: hidden !important;
}

.table-genealogy > tbody > tr > td:first-child {
    width: 30%;
    overflow: hidden !important;
    border-bottom: 0 !important;
}

.table-genealogy > tbody > tr > td:last-child {
    width: 70%;
    overflow: hidden;
}

.table-genealogy {
    margin-bottom: 0 !important;
}

.table-genealogy th {
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.table-genelogy-ba-column {
    border: 0px !important;
    border-bottom: 1px solid rgb(225, 231, 236) !important;
}

.table-genelogy-ba-column tbody {
    border: 0px !important;
}

.table-genelogy-point-column th {
    border-bottom: 1px solid rgb(225, 231, 236) !important;
}

.table-genelogy-ba-column,
.table-genelogy-ba-column td,
.table-genealogy > tbody > tr > td:last-child .table-genelogy-point-column,
.table-genelogy-point-column td,
.table-genealogy td {
    margin-bottom: 0 !important;
}

.table-genelogy-point-column th,
.table-genelogy-point-column {
    vertical-align: top !important;
}

.table-genelogy-point-column td {
    vertical-align: middle !important;
    text-align: center;
}

.table-genelogy-point-column {
    border-bottom: 1px solid rgb(225, 231, 236) !important;
}

.table-genealogy-balist {
    max-width: 100%;
    overflow: scroll hidden;
    border: 0px !important;
}

.table-genealogy-balist:first-child {
    overflow-x: auto !important;
}

.table-genealogy-balist > table {
    width: 500%;
}

.table-genealogy-pointlist {
    max-width: 100%;
    overflow: scroll hidden;
}

.table-genealogy-pointlist > table {
    width: auto !important;
}

.table-genealogy-month-period {
    background: #dddddd;
    color: black;
    border: 0px !important;
    vertical-align: middle !important;
    text-align: center;
}

.scroll-to-top-btn:hover {
    background-color: transparent;
    color: #fff;
}

.no-border {
    border: 0 !important;
}

.no-border td {
    border-left: 0 !important;
    border-right: 0 !important;
}

.no-border-top {
    border-top: 0px !important;
}

.no-shadow {
    box-shadow: none !important;
}

.table-genealogy-period-header {
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    height: 50px !important;
    min-height: 50px;
}

.table-genealogy-month-period {
    height: 50px !important;
    min-height: 50px;
}

.table-genealogy-ba-name:first-child > td {
    border-top: 1px solid #dee2e6 !important;
}

.table-genealogy-ba-name span:hover {
    cursor: pointer;
    color: rgb(42, 184, 245);
}

.table-genealogy-ba-name span {
    vertical-align: middle;
    padding-right: 20px;
    padding-left: 10px;
}

.table-genealogy-balist-inner {
    margin-bottom: 0px;
}

.table-genealogy-balist-inner td {
    border-bottom: 1px solid #dee2e6 !important;
}

.table-genealogy-balist-inner tr:last-child td {
    border-bottom: 0px solid #dee2e6 !important;
}

.table-arrow {
    font-size: 30px;
}

.show-detail {
    color: grey;
    display: inline-block;
    color: red;
    text-align: left;
    width: 100%;
    margin-top: 10px;
    padding-left: 150px;
}

.show-detail-arrow {
    color: grey;
    display: inline-block;
    text-align: left;
    width: 100%;
    padding-left: 150px;
}

.table-layout-fixed {
    table-layout: fixed !important;
}

.table-layout-auto {
    table-layout: auto !important;
}

.table-genealogy-period-header-vn {
    text-align: center;
}

.table-genelogy-point-column th {
    vertical-align: middle !important;
}

.table-genelogy-point-column td {
    height: 50px;
    min-height: 50px;
}

.before-table-genelogy-ba-column {
    vertical-align: top !important;
}

.table-genealogy-period-header > div {
    width: 100% !important;
    text-align: center !important;
}

.show-landscape,
.show-portrait {
    display: none;
}

.topbar .topbar-column:first-child > a:not(.social-button),
.topbar .topbar-column:first-child > span,
.topbar .topbar-column:first-child > p {
    margin-right: 15px;
}

.topbar-sticky {
    top: 0px;
    position: fixed !important;
}

.topbar-stuck .navbar-sticky {
    position: fixed;
}

.topbar-stuck main.wrap-layout {
    margin-top: 120px !important;
}

.topbar-scroll-stuck .topbar.topbar-scroll-sticky {
    position: fixed;
}

.topbar-scroll-stuck .topbar.topbar-scroll-sticky.nav-up {
    position: fixed;
    top: -40px;
}

.topbar-scroll-stuck .topbar.topbar-scroll-sticky.nav-down {
    position: fixed;
}

.topbar-scroll-stuck .nav-down {
    top: 0px !important;
}

.navbar.navbar-sticky-both-when-scroll.nav-down {
    top: 40px !important;
}

.navbar-sticky-both-when-scroll {
    position: fixed;
    top: 40px !important;
}

.navbar-sticky-both-when-scroll.nav-up {
    top: -78px !important;
}

.navbar-sticky-both-when-scroll.nav-down {
    top: 78px;
    position: fixed;
}

.topbar-scroll-stuck main {
    position: relative;
}

.navbar-sticky-when-scroll,
.navbar-sticky-when-scroll.nav-up {
    position: static;
}

.navbar-sticky-when-scroll.nav-down {
    position: fixed;
}

@media only screen and (max-device-width: 1024px) and (max-device-height: 800px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .table-genealogy > tbody > tr > td:first-child {
        width: 250px;
        max-width: 250px;
    }

    .table-genealogy-period-header {
        width: 65px !important;
    }

    .table-genealogy-period-header-vn:first-child {
        width: 80px !important;
        text-align: center;
    }

    .show-portrait {
        display: none;
    }
}

@media only screen and (max-device-width: 823px) and (max-device-height: 375px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .table-genealogy > tbody > tr > td:first-child {
        width: 250px;
        max-width: 250px;
    }

    .table-genealogy-period-header {
        width: 65px !important;
    }

    .table-genealogy-period-header-vn:first-child {
        width: 90px !important;
    }

    .show-portrait {
        display: none;
    }

    .show-landscape {
        display: inline-block;
    }
}

@media only screen and (max-device-height: 1366px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .show-portrait {
        display: inline-block;
    }

    .show-landscape {
        display: none;
    }
}

@media only screen and (max-device-height: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .show-portrait {
        display: none;
    }

    .show-landscape {
        display: inline-block;
    }
}

@media only screen and (max-device-height: 1024px) and (max-device-width: 768px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .show-portrait {
        display: inline-block;
    }

    .show-landscape {
        display: none;
    }
}

@media only screen and (max-device-height: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .show-portrait {
        display: none;
    }

    .show-landscape {
        display: inline-block;
    }
}

@media only screen and (max-device-height: 823px) and (max-device-width: 411px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .show-portrait {
        display: inline-block;
    }

    .show-landscape {
        display: none;
    }
}

@media only screen and (max-device-height: 411px) and (max-device-width: 823px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .show-portrait {
        display: none;
    }

    .show-landscape {
        display: inline-block;
    }
}

@media only screen and (max-device-height: 736px) and (max-device-width: 414px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .show-landscape {
        display: none;
    }

    .show-portrait {
        display: inline-block;
    }
}

@media only screen and (max-device-height: 414px) and (max-device-width: 736px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .show-landscape {
        display: inline-block;
    }

    .show-portrait {
        display: none;
    }
}

@media only screen and (max-device-height: 812px) and (max-device-width: 375px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .font-size-genealogy-screen-small {
        font-size: 2vh;
    }

    .show-landscape {
        display: none;
    }

    .show-portrait {
        display: inline-block;
    }
}

@media only screen and (max-device-width: 812px) and (max-device-height: 375px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .font-size-genealogy-screen-small {
        font-size: 2vw;
    }

    .show-portrait {
        display: none;
    }

    .show-landscape {
        display: inline-block;
    }
}

@media only screen and (max-device-width: 667px) and (max-device-height: 375px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .table-genealogy > tbody > tr > td:first-child {
        width: 200px !important;
        max-width: 200px !important;
    }

    .table-genealogy-period-header {
        width: 50px !important;
    }

    .show-portrait {
        display: none;
    }

    .show-landscape {
        display: inline-block;
    }
}

@media only screen and (max-device-width: 568px) and (max-device-height: 320px) {
    .table-genealogy > tbody > tr > td:first-child {
        width: 150px !important;
        max-width: 150px !important;
    }

    .table-genealogy > tbody > tr > td:last-child {
        width: 75%;
    }

    .table-genealogy-month-period {
        width: auto !important;
    }
}

@media only screen and (max-width: 823px) {
    .table-genealogy > tbody > tr > td:last-child {
        overflow: auto !important;
        max-width: 1000px;
    }

    .table-genealogy-period-header {
        width: 60px !important;
    }

    .table-genealogy-period-header-vn {
        width: 75px !important;
    }

    .table-genealogy-month-period {
        width: 250px;
    }

    .table-genealogy > tbody > tr > td div:first-child {
        width: fit-content;
    }

    .show-detail,
    .show-detail-arrow {
        float: left;
        padding-left: 0px;
    }
}

@media only screen and (max-width: 812px) {
    .table-genelogy-point-column {
        table-layout: fixed;
    }

    .table-genealogy > tbody > tr > td:last-child {
        overflow: auto !important;
        max-width: 1000px;
    }

    .table-genealogy-period-header {
        width: 70px !important;
    }

    .table-genealogy-period-header-vn {
        width: 75px !important;
    }

    .table-genealogy-month-period {
        width: 250px;
    }
}

@media only screen and (max-width: 568px) {
    .table-genealogy-balist {
        width: 400px;
    }

    .table-genelogy-ba-column {
        width: 174px;
    }

    .lsb-colum {
        width: 130px !important;
    }
}

@media only screen and (max-width: 414px) {
    .table-genealogy-balist {
        width: 300px;
    }

    .table-genelogy-ba-column i {
        margin-right: 20px;
    }
}

@media only screen and (max-width: 375px) {
    .font-size-genealogy-screen {
        font-size: 2vh;
    }

    .table-genelogy-ba-column {
        width: 120px;
    }

    .table-genelogy-ba-column i {
        margin-right: 0px !important;
    }
}

@media only screen and (max-width: 320px) {
    .font-size-genealogy-screen {
        font-size: 2.5vh;
    }

    .table-genelogy-ba-column {
        width: 100px;
    }

    .topbar .topbar-column:first-child > a:not(.social-button),
    .topbar .topbar-column:first-child > span,
    .topbar .topbar-column:first-child > p {
        margin-right: 10px;
    }
}

.widget ul a {
    margin-bottom: 12px;
    text-decoration: none !important;
}

.item-left a {
    text-decoration: none !important;
}

.shopprofile-ta .form-control-number {
    padding: 0;
    font-family: 'Prompt';
}

button {
    font-family: 'Prompt', sans-serif;
}

.switch-shop-title {
    padding: 10px 4px !important;
    margin: 0;
}

.slider-banner-learn-more {
    font-weight: bold;
    align-items: center;
    text-align: center;
    margin-right: 0px;
    white-space: nowrap;
}

.seminar-tab-center-span {
    display: table-cell;
    vertical-align: middle;
    top: 0px;
}

.seminar-display-table {
    display: table !important;
    height: 40px !important;
}

.table.table-striped.full-genealogy-table th,
.table.table-striped.full-genealogy-table td {
    vertical-align: middle !important;
    text-align: center;
}

.full-genealogy-table {
    table-layout: auto;
}

.full-genealogy-table td {
    width: fit-content;
    min-width: 40px;
}

.full-genealogy-table tbody {
    border-bottom: 1px solid #dee2e6;
}

.full-genealogy-table-fix-width {
    text-align: left !important;
}

.full-genealogy-table-fix-width > div {
    width: max-content;
}

.text-bold {
    font-weight: bold;
}

.genealogy-breadcrumbs .breadcrumbs {
    text-align: left;
}

.genealogy-breadcrumbs .breadcrumbs li {
    color: #333333;
    font-size: 1.5rem;
    font-family: 'Prompt', sans-serif !important;
}

.genealogy-breadcrumbs .breadcrumbs > li:not(:first-child)::before {
    content: ' > ';
    color: #d0d0d0 !important;
}

.genealogy-breadcrumbs .breadcrumbs > li:last-child::after {
    content: '';
}

.checkout-footer {
    display: table;
    width: 100%;
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid #e1e7ec;
    border-radius: 7px;
    table-layout: fixed;
}

@media (max-width: 576px) {
    .page-title {
        margin-bottom: 40px;
    }
}

.table-fixed-left {
    border-right: 1px solid #ddd;
}

.table-fixed-left > table,
.table-fixed-right > table {
    border-collapse: collapse;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0px;
}

.table-fixed-right td,
.table-fixed-right th,
.table-fixed-left td,
.table-fixed-left th {
    padding: 5px 5px;
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    height: 50px;
}

.table-fixed-left th,
.table-fixed-right td,
.table-fixed-right th {
    border-bottom: 1px solid #ddd;
}

.table-fixed-left th,
.table-fixed-right th {
    border-top: 1px solid #ddd;
}

.table-fixed-left th {
    text-align: left !important;
}

.table-fixed-right th {
    background: black;
    color: white;
    text-align: center;
    font-weight: bold;
}

.table-fixed-left {
    width: 300px;
    float: left;
    overflow-x: auto;
    white-space: nowrap;
    text-align: left;
    z-index: 2;
    overflow: -moz-scrollbars-none;
}

.table-fixed-right {
    overflow-x: auto;
    white-space: nowrap;
    overflow: -moz-scrollbars-none;
}

.table-fixed-right td,
.table-fixed-right th {
    padding: 5px 10px;
}

.table-fixed-left::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
}

.table-fixed-right::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
}

.arrow-class {
    position: relative;
    top: 3px;
    left: 150px;
}

@media screen and (max-width: 900px) {
    .table-fixed-left {
        width: 120px;
    }

    .arrow-class {
        position: relative;
        top: 3px;
        left: 50px;
    }
}

.margin-left-24 {
    margin-left: -24px;
}

.lsb-col {
    border: 1px solid #e1e7ec;
    border-radius: 7px !important;
    margin-bottom: 5px;
}

.lsb-balance {
    font-size: 18px;
    font-weight: 400;
    color: rgb(96, 105, 117);
}

.lsb-balance-sum {
    font-size: 14px;
    font-weight: 700;
}

.seminar-cursor a {
    cursor: pointer !important;
}

.genealogyFixWidth60 {
    width: 60px !important;
}

.reset-btn {
    border-color: #ccc !important;
    color: #2bb8f4 !important;
    margin-right: 5px !important;
    line-height: 0 !important;
}

.genealogy-icon-button-active {
    background: rgb(42, 184, 245) !important;
    border-color: rgb(42, 184, 245) !important;
}

.genealogy-icon-button-active-no-bg {
    background: rgb(42, 184, 245) !important;
    border-color: rgb(42, 184, 245) !important;
}

.genealogy-icon-button-active-no-bg > i {
    color: #fff !important;
}

.genealogy-icon-button-active > i {
    background: #fff !important;
    color: #fff !important;
}

.products-category-section {
    list-style: none;
}

.products-category-section li {
    display: inline-block;
    color: #606975;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#cat-sort span.active {
    color: rgb(13, 169, 239) !important;
}

#cat-sort span > i {
    margin-left: 15px;
}

.products-filter-off {
    font-size: 25px;
    margin: 0 auto;
    position: relative;
    top: 8px;
    color: #a5a5a5 !important;
    cursor: pointer;
}

.products-filter-on {
    font-size: 25px;
    margin: 0 auto;
    position: relative;
    top: 8px;
    color: black !important;
    cursor: pointer;
}

.products-qtyinput .qtyInput {
    width: max-content !important;
    margin: auto !important;
}

.products-menu > div {
    display: table-cell;
    vertical-align: middle;
    padding-right: 20px;
    margin: 20px 0px;
}

.fb-messenger {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    color: #606975 !important;
}

.top-bar-facebook-messenger {
    width: 13px !important;
    height: auto !important;
    color: #606975 !important;
    margin-top: -2px !important;
}

.top-bar-facebook-messenger-link div a:hover {
    color: #0da9ef !important;
}

.modal-backdrop {
    background: #333 !important;
}

.padding-log-menu {
    padding-top: 10px !important;
}

.th-login-btn {
    margin-bottom: 0px !important;
}

.padding-loginform {
    padding-bottom: 10px !important;
}

.form-warranty-center {
    width: 50%;
    margin: 0 auto;
}

.product-item > .row {
    height: 100%;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border: 1px solid #dddddd;
    border-right: 0px !important;
    border-left: 0px !important;
}

.padding-warranty {
    margin-top: 15px !important;
}

#table-head-lsb {
    text-align: center !important;
    color: #606975 !important;
    font-size: 13px !important;
    vertical-align: middle !important;
    box-shadow: 0px 0px 0px 0px #a3a3a3 !important;
    /* box-shadow:0 0.06rem 0 0 rgba(44, 43, 63, 0.1); */
}

#headerBar .search-box {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    top: 76px;
    height: 52px;
    background: white;
    border-bottom: 1px solid #e1e7ec;
    margin: 0 auto;
    text-align: center;
}

.search-box .site-search {
    opacity: 1 !important;
    visibility: visible !important;
}

.tag.warning {
    color: red !important;
}

.qtyplusminusbtn.borderRight.disabled,
.qtyplusminusbtn.borderLeft.disabled,
.form-control-number-new.disabled,
.form-control-number-new.disabled.apple {
    color: #ccc !important;
}

.toolbar .search {
    margin-left: 0px !important;
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
    .border-radius-promotional-pic {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    .look-forward {
        padding: 15px 15px 15px 1px !important;
    }

    .border-radius-left-top-bottom {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
}

.products-table .table thead th {
    border-bottom: 1px !important;
}

.pill-form-center {
    margin: 0 auto !important;
}

.tag.more-info {
    color: #0da9ef !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .hide-only-ipad img {
        position: absolute;
        height: 101% !important;
    }
}

.notification-label-link {
    width: 100%;
    height: 30px;
    line-height: 30px;
}

.error-img-container {
    margin: 30px auto 0px;
    width: 150px;
}

/* customlink css */

.customlink thead th {
    padding-top: 17px;
    padding-bottom: 17px;
    border-width: 1px;
}

.customlink .table thead th,
.table td,
.table th {
    border-color: #e9edf0bf;
}

.cart-item-qty {
    margin: auto auto auto 0px;
    justify-content: center;
    align-items: center;
}

.country-flag-icon {
    width: 20px !important;
    margin-right: 10px;
}

.country-flag-margin {
    margin-top: 10px;
    margin-bottom: 10px;
}

.alert-marginb {
    margin-bottom: 20px;
    width: 51%;
}

.alert-marginb-easy-shop {
    margin-bottom: 20px;
    width: 50%;
}

.toolbar .cart-header .toolbar-dropdown {
    margin-top: 4px !important;
    right: 0;
    left: auto;
    width: 320px;
    padding-right: 18px !important;
    padding-left: 18px !important;
}

.toolbar .cart-header.show .toolbar-dropdown {
    display: block !important;
    margin-top: 4px !important;
}

.cart-modal {
    top: 0;
    min-height: 300px;
}

.toolbar .cart-header {
    display: inline-flex;
    position: relative;
    width: 44px;
    height: 44px;
    margin-left: 6px;
    transition: background-color 0.35s;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    color: #606975;
    font-size: 14px;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
}

.toolbar .cart-header {
    width: auto;
    padding: 0 16px 0 16px;
    border-radius: 22px;
}

.toolbar .cart-header.show {
    background-color: #f5f5f5;
}

.cart-header.disabled {
    border-color: transparent !important;
    color: #9ea9b9 !important;
    cursor: default !important;
}

.cart-header.disabled img {
    opacity: 0.7 !important;
}

.seminar-tab-container {
    overflow: hidden;
    position: relative;
    height: auto !important;
    min-height: 40px;
}

.seminar-tab-container .seminar-text {
    overflow: hidden;
    position: relative;
}

.seminar-text {
    height: auto;
    top: 0px;
    display: inline-flex;
    align-items: center;
    min-height: 40px;
}

.seminar-ab-container::after {
    position: absolute;
    content: '...';
    bottom: 0;
    right: 0;
    padding: 0 0.3em;
    background: inherit;
}

.seminar-ab-container span:after {
    content: '\0000a0';
    position: absolute;
    width: 1000px;
    z-index: 1;
    background: white;
}

.seminar table tr td {
    vertical-align: top !important;
}

.btn-pagination.btn-outline-secondary {
    border-color: #e1e7ec !important;
}

.btn-pagination.btn-outline-secondary.active {
    background-color: #0da9ef !important;
    color: #fff !important;
}

/*MKUNG*/

.scroll_list_wrap {
    margin-bottom: -5px;
}

.scroll_list {
    display: table;
    padding: 12px 14px 0;
}

.scroll_list_wrap.ol,
.scroll_list_wrap.ul {
    list-style: none;
}

.scroll_list .item {
    display: table-cell;
    text-align: center;
}

.scroll_list .item + .item {
    padding-left: 20px;
}

.scroll_list_wrap {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.cardGenealogy {
    text-align: left;
    display: block;
    position: relative;
    max-width: 300px;
    padding: 18px;
    border: 1px solid #e1e7ec;
    border-radius: 7px;
    background-color: #fff;
    white-space: nowrap;
    cursor: pointer;
    height: 375px;
}

#myWorkContent {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

#myWorkContent div {
    display: inline-block;
    width: 100%;
}

.infoTb {
    width: 200px;
}

@media (min-width: 480px) {
}

@media (min-width: 768px) {
    .infoTb {
        width: 220px;
    }
}

.btn-pagination {
    margin: 10px 0px;
    padding: 0px 10px;
    border-radius: 0px;
}

.btn-pagination.active {
    border: 1px solid #e1e7ec;
    color: #0da9ef;
}

.cart-items {
    text-align: center;
    display: table;
    width: 100%;
    vertical-align: middle;
    align-content: center;
}

.cart-items > td {
    text-align: center;
}

.dropdown-product-remove {
    cursor: pointer;
}

.checkbox-upd {
    position: relative;
    top: 2px !important;
}

.checkbox-re {
    position: relative;
    left: -6px;
}

.custom-checkbox-dsk {
    margin-left: 5px;
    width: 456px;
    margin: 0 auto;
}

.label-dsk-mb {
    text-align: center;
}

.postbackenroll-img {
    margin-top: 30px;
    margin: 0px auto;
    width: 150px;
    padding-top: 20px;
}

.custom-checkbox-dska {
    margin-left: 5px;
    width: 100%;
}

.lsb-table-header {
    border-bottom: 0.5px solid #e1e7ec !important;
}

.order-detail-table > tbody > tr:hover {
    background: rgba(255, 255, 255, 0.425) !important;
}

svg {
    color: currentColor;
}

.first-box-genealogy {
    border: 1px solid #e8c629;
}

.first-boxdef-genealogy {
    border: 1px solid #e1e7ec;
}

.card-body-genealogy {
    padding: 10px;
}

.genealogy-card {
    padding-left: 5px;
    padding-right: 5px;
}

table.genealogyCard {
    font-family: 'Prompt', sans-serif !important;
}

table.genealogyCard tr th {
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
}

table.genealogyCard tr td {
    text-align: center;
    background-color: #ffffff;
}

table.genealogyCard tr td:first-child {
    padding-right: 0px;
}

.trip-border-st img {
    border-radius: 7px !important;
}

table.shopprofile-item td {
    border: 0 !important;
    padding: 4px !important;
    font-size: 14px !important;
}

table.shopprofile-item td:first-child {
    font-weight: bold;
}

.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.collapse-expand::before {
    transition: all 0.12s ease-in-out;
    transform: scale(2);
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f107';
    width: 30px;
    text-align: center;
    margin: auto;
    padding-top: 1.25px;
}

a[aria-expanded='true'] > .collapse-expand::before {
    transition: all 0.12s ease-in-out;
    transform: scale(2) rotate(180deg);
}

.nav-warranty li .show {
    background: #0097fa !important;
    color: white !important;
    padding: 0px 10px 0px 10px !important;
    border-radius: 5px !important;
}

.nav-left::first-child:not(:last-child):not(.dropdown-toggle) {
    margin-right: -1px !important;
    padding-right: 12px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}

.btn-group .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    margin-right: -1px;
    padding-right: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.nav-warrant li .show {
    background: #0097fa !important;
    color: white !important;
    padding: 0px 30px 0px 10px !important;
    border-radius: 0px !important;
}

.nav-left {
    padding: 10px 0px !important;
    margin-right: 20% !important;
}

.nav-right {
    padding: 10px 0px !important;
    width: 178px !important;
}

.nav-left {
    padding: 10px 0px !important;
    margin: 0px !important;
    width: 178px !important;
}

.nav-right {
    padding: 10px 0px !important;
    margin: 0px !important;
}

.nav-left a {
    margin: 0px !important;
    width: 178px !important;
}

.nav-right a {
    margin: 0px !important;
    width: 178px !important;
}

.nav-left a:active {
    margin: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.nav-right a:active {
    margin: 0px !important;
    border-top-right-radius: 0px !important;
}

.nav-tab-warranty {
    margin: 0 auto !important;
    width: 100% !important;
}

#nav-border-warranty {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.btn-warranty {
    background-color: #f5f5f5 !important;
    border-color: #e1e7ec !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: 100% !important;
}

.btn-warranty:active {
    background-color: #f5f5f5 !important;
    border-color: #e1e7ec !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: 100% !important;
}

.btn-warranty-l {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.tab-warranty {
    margin-left: 20% !important;
}

.nav-a li .show:nth-child(2n) {
    background: #0097fa !important;
    color: white !important;
    padding: 0px 10px 0px 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    width: 100% !important;
}

/* .btn-warranty:active {
    background-color: #f5f5f5 !important;
    border-color: #e1e7ec !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: 100% !important;
} */

.shopprofile-card.card {
    border: 1px solid #e1e7ec !important;
    border-radius: 7px !important;
}

.shopprofile-card a {
    display: block;
    height: 100%;
    margin: 8px auto auto 8px;
}

.shopprofile-card a[data-toggle='collapse']::after {
    content: none !important;
    border: none !important;
    display: none;
}

.shopprofile-card .card-header .h6 {
    color: #606975 !important;
}

.shopprofile-card .card-header {
    height: 40px;
    border: 0px !important;
    border-radius: 6px !important;
    margin: auto 0 auto 0;
    color: #606975 !important;
    background: #f5f5f5 !important;
    padding: 0px !important;
}

.form-control-number-shopprofile {
    color: blue;
}

.fa-icon-all {
    margin-top: -4px;
    font-size: 25px;
}

.agree-terms-text {
    font-weight: 400;
    display: inline !important;
}

.cart-menu.list-group li {
    padding-top: 0px;
    padding-bottom: 0px;
}

.cart-menu.list-group li:last-child {
    margin-top: 20px;
}

.credit-card-center {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.cart-alert {
    position: fixed;
    right: -500px;
    transition: all 0.025s ease-in-out;
    top: 40px;
    padding: 20px;
}

.slide-to-left {
    right: 0;
    left: 0;
}

.move-products-to-left {
    position: fixed;
    right: 500px;
    transition: all 0.025s ease-in-out;
    margin: 10px 20px;
    margin-top: 20px;
    display: none;
}

.lock-panel {
    overflow-y: hidden;
}

/* Shared */

.loginBtn {
    box-sizing: border-box;
    position: relative;
    /* width: 13em;  - apply for fixed size */
    margin: 0;
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 14px;
    color: #fff;
    width: 100% !important;
}

.loginBtn:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
}

.loginBtn:focus {
    outline: none;
}

.loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */

.loginBtn--facebook {
    border-radius: 22px;
    background-color: #4c69ba;
    background-image: linear-gradient(#4c69ba, #3b55a0);
    text-shadow: 0 -1px 0 #354c8c;
}

.loginBtn--facebook-unlink {
    border-radius: 22px;
}

.loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png')
        11px 9px no-repeat;
    background-size: 50%;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
    background-color: #5b7bd5;
    background-image: linear-gradient(#5b7bd5, #4864b1);
}

.loginBtn--facebook.disabled {
    background-color: #abbae5;
    background-image: none;
    text-shadow: none;
    color: #fff;
}

.loginBtn--facebook.disabled:before {
    border-right: rgb(158, 169, 199) 1px solid !important;
}

.custom-link-summary-table .custom-link-summary-table-value {
    width: 120px;
}

.custom-link-summary-table .custom-link-summary-table-unit {
    width: 45px;
}

.btn-genealogy-icon {
    height: 33px;
    width: 33px;
    border: 1px solid lightgray !important;
    border-radius: 50%;
    background-color: transparent;
}

.btn-facebook-messenger {
    background-color: #0078ff;
}

.btn-facebook-messenger:hover {
    background-color: rgb(41, 141, 255);
}

.btn-facebook-messenger-close {
    border: 0 !important;
    outline: none !important;
    background-color: #a3a3a3 !important;
}

.no-hover {
    cursor: default !important;
    pointer-events: none !important;
}

.no-pointer-events {
    pointer-events: none !important;
}

.useless-dropdown {
    font-weight: normal !important;
    border: 1px solid #dbe2e8 !important;
}

.useless-dropdown.dropdown-toggle:after {
    content: none !important;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.width-33 {
    width: 33px !important;
}

.rotate-gen-icon-reset {
    -ms-transform: rotate(22deg);
    /* IE 9 */
    -webkit-transform: rotate(22deg);
    /* Safari 3-8 */
    transform: rotate(22deg);
}

.btn-link {
    color: #0da9ef;
}

.h-0 {
    height: 0px !important;
}

.ol-numeric ol,
.ol-numeric ul {
    list-style-type: decimal;
}

.cancel-link {
    cursor: pointer !important;
    font-size: 20px !important;
    color: #0da9ef !important;
    text-decoration: underline !important;
}

.label-drop {
    font-size: 13px;
    font-weight: 500;
    cursor: default;
}

.text-white {
    color: #ffffff !important;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.row-equal-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.row.row-equal-height [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.getinfomation-form .input-group {
    float: left;
    width: calc(100% - 50px);
    padding-right: 15px;
}

.getinfomation-form .btn {
    margin: 0;
    float: right;
    border-radius: 7px;
    width: 50px;
    height: 50px;
    padding: 0;
}

.getinfomation-form .btn > i {
    margin-top: -6px;
    font-size: 1.5em;
}

.getinfomation-form .form-text {
    padding-top: 12px;
}

.whitefade {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 999;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
}

.topsclicked {
    display: block;
    position: fixed;
    right: 16px;
    bottom: -88px;
    width: 44px;
    height: 44px;
    transition: bottom 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
        opacity 0.3s, background-color 0.3s, border-color 0.3s;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 18px;
    line-height: 39px;
    opacity: 0;
    z-index: 2000;
    text-align: center;
    text-decoration: none;
}

.topsclicked:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
}

.topsclicked:focus,
.topsclicked:active {
    color: #fff;
}

.topsclicked.visible {
    bottom: 14px;
    opacity: 1;
}

@media (max-width: 768px) {
    .topsclicked {
        bottom: -72px;
        width: 36px;
        height: 36px;
        line-height: 31px;
    }
}

.showbtn {
    display: inline-block;
    margin-top: 5px;
    margin-right: 18px;
    margin-bottom: 5px;
    transition: color 0.3s;
    color: #606975;
    font-size: 13px;
    text-decoration: none;
    vertical-align: middle;
}

.showbtn.shape-circle,
.showbtn.shape-rounded,
.showbtn.shape-square {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border: 1px solid #e1e7ec;
    line-height: 35px;
    text-align: center;
}

.showbtn.shape-circle {
    border-radius: 50%;
}

.showbtn.shape-rounded {
    border-radius: 5px;
}

.showbtn:focus {
    text-decoration: none;
}

.text-center .showbtn {
    margin-right: 9px;
    margin-left: 9px;
}

.text-center .showbtn.shape-circle,
.text-center .showbtn.shape-rounded,
.text-center .showbtn.shape-square {
    margin-right: 4px;
    margin-left: 4px;
}

.text-right .showbtn {
    margin-right: 0;
    margin-left: 18px;
}

.text-right .showbtn.shape-circle,
.text-right .showbtn.shape-rounded,
.text-right .showbtn.shape-square {
    margin-right: 0;
    margin-left: 8px;
}

[class*=' showing-']:before,
[class^='showing-']:before,
[data-icon]:before {
    font-family: socicon !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.showing-fb:before {
    content: '\e041';
}

.showing-yt:before {
    content: '\e051';
}

.showing-ig:before {
    content: '\e057';
}

.topicons {
    display: none;
}

.offcanvas-toggle-layout {
    height: 100%;
    display: inline-table;
    vertical-align: middle;
}

.offcanvas-toggle-layout .offcanvas-toggle {
    top: -2px;
}

.genealogy-breadcrumbs {
    border: 1px solid #e4e4e4 !important;
    margin-top: 20px !important;
}

.menu-active {
    position: relative;
    width: 100%;
    margin-bottom: -1px;
    padding-top: 90px;
    padding-bottom: 0px;
    border: 1px solid #e1e7ec;
    border-radius: 7px !important;
    background: #48a8e9 !important;
    color: white !important;
}

.logout-c {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 0px 5px;
    justify-content: center;
    text-align: left;
    cursor: pointer;
    border-top: 0px solid #ccc !important;
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    border-bottom: 1px solid #ccc;
}

.single-seminar {
    padding-left: 10px !important;
}

.padding-10 {
    padding: 10px !important;
}

.dropdown-healthcheck2 {
    font-size: 12px !important;
}

.dropdown-healthcheck3 {
    font-size: 12px !important;
}

#controlang-TH .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'เลือกไฟล์เอกสาร' !important;
}

.auto-margin {
    margin: 0 auto;
}

.carddiv-img-top-profile.user-avatar {
    overflow: hidden;
    min-height: 270px;
    width: auto;
    border-radius: 0;
    vertical-align: middle;
}

.carddiv-img-top-profile.user-avatar .overlay {
    border-radius: 0px;
}

.profile-loader-in-card.sk-circle {
    top: 50%;
    left: 50%;
    position: absolute;
    margin-left: -30px;
}

.card-img-top-profile {
    max-height: 500px;
    overflow-y: hidden;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

/*** Customize Checkbox ***/

.container-checkbox {
    display: table;
    margin: auto;
}

.checkbox-label {
    display: block;
    position: relative;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 2.25rem;
    padding-right: 0.5rem;
}

/* Hide the browser's default checkbox */

.checkbox-label input {
    position: relative;
    vertical-align: top;
    opacity: 0;
    cursor: default;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 1.1rem;
    left: 0;
    height: 14px;
    width: 14px;
    margin-top: -0.75rem;
    background-color: #dee2e6;
    border-radius: 0.25rem;
}

/* On mouse-over, add a grey background color */

.checkbox-label:hover input ~ .checkmark {
    background-color: #dee2e6;
}

/* When the checkbox is checked, add a blue background */

.checkbox-label input:checked ~ .checkmark {
    background-color: #0da9ef;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: inline-block;
}

/* Show the checkmark when checked */

.checkbox-label input:checked ~ .checkmark:after {
    left: 4px;
    top: 2px;
    width: 5px;
    height: 8px;
    opacity: 1;
    transition: 0.05s all ease-in;
}

/* Style the checkmark/indicator */

.checkbox-label .checkmark:after {
    left: 6px;
    top: 8px;
    width: 1px;
    height: 1px;
    opacity: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: 0.05s all ease-in;
}

/*** End - Customized Checkbox ***/

.lh-0 {
    line-height: 0 !important;
}

.h-1rem {
    height: 1rem !important;
}

.h-2rem {
    height: 2rem !important;
}

.semirar-custom-tbl {
    box-shadow: 0px 5px 20px -10px #a3a3a3;
}

.side-table-custom-seminar {
    border: 1px solid #e1e7ec;
    background: #ffffff;
    /* box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10), 0 2px 5px 0 rgba(0, 0, 0, 0.10); */
    -webkit-perspective: 1px;
    perspective: 1px;
    border-radius: 5px !important;
    border-collapse: separate;
}

.side-table-custom-seminar-rank {
    border: 1px solid #e1e7ec;
    background: #ffffff;
    /* box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10), 0 2px 5px 0 rgba(0, 0, 0, 0.10); */
    -webkit-perspective: 1px;
    perspective: 1px;
    border-radius: 5px !important;
    border-collapse: separate;
}

.default-seminar-st {
    border: 1px solid #fff !important;
}

.info-share-cart {
    /* position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%; */
    background: #fff;
}

.address-share-cart {
    /* position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%; */
    background: #fff;
}

.react-datepicker-popper {
    z-index: 999 !important;
}

.form-share-cart label small {
    color: #bbb;
}

.share-block-01 {
    position: relative;
}

.share-block-02 {
    position: relative;
}

.tab-cart {
}

.share-this-cart-wrapper {
    border-top: 1px dashed #dee2e6;
    position: relative;
    padding-top: 20px;
    position: relative;
}

.row-block-share {
    margin-top: -20px;
    margin-bottom: 20px;
    padding-top: 20px;
}

.row-block-share + .row-block-share {
    display: none;
}

.block-share-middle {
    width: 100%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
}

.block-share-left {
    width: 38%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
}

.block-share-right {
    width: 60%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: right;
}

.pull-top-hr {
    margin-top: -28px !important;
}

.cart-actions-custom {
    /* padding-right: 0px !important; */
}

.border-actions-part {
    border-top: 1px solid #dee2e6;
    padding-top: 20px;
}

.no-autofill,
.no-autofill:-webkit-autofill {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -moz-box-shadow: 0 0 0 30px white inset !important;
}

.no-autofill:disabled {
    box-shadow: 0 0 0 30px #f5f5f5 inset !important;
    -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
    -moz-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
}

.card .order-detail-table > thead {
    border-top: 0px !important;
    border-bottom: 1px solid #dee2e6;
    background-color: white;
    color: #606975;
    padding-top: 10px;
    padding-bottom: 10px;
}

.card .order-detail-table > tbody > tr {
    border-bottom: 1px solid #dee2e6;
    border-right: 0px solid #dee2e6;
    border-left: 0px solid #dee2e6;
    border-top: 0px solid #dee2e6;
}

.card .order-detail-table > tbody > tr > td {
    border-right: 1px solid #dee2e6;
    text-align: center;
    border-right: 0px solid #dee2e6;
    border-left: 0px solid #dee2e6;
    border-top: 0px solid #dee2e6;
    white-space: nowrap;
}

.card .address-table > thead > tr > th {
    color: #606975;
}

#profile-main-address .input-group .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 0px;
}

.order-history-accordion .card-header h4 button:after {
    -webkit-transform: scale(1);
    transform: scale(1);
    font-family: Font awesome\5 Free;
    font-weight: 900;
    content: '\f107';
    width: 30px;
    text-align: center;
    margin: auto;
    padding-top: 1.25px;
    float: right;
}

.order-history-accordion .card-header h4 button[aria-expanded='true']:after {
    -webkit-transform: scale(1) rotate(180deg);
    transform: scale(1) rotate(180deg);
}

.order-history-accordion .card-header h4 button:after,
.order-history-accordion .card-header h4 button[aria-expanded='true']:after {
    -webkit-transition: all 0.12s ease-in-out;
    transition: all 0.12s ease-in-out;
}

.order-history-accordion .card-header h4 button:focus {
    border: 0px;
    box-shadow: none;
    outline: none;
}

.div-loading {
    opacity: 0.4;
}

.border-radius-7px {
    border-radius: 7px !important;
}

.margin-5 {
    margin: 5px !important;
}

.maintenance_desc3 {
    padding-top: 0px;
}

#maintenance_lang {
    color: #fff;
    font-size: 18px;
}

.maintenance_langb span a:nth-child(even):before {
    content: '|';
}

.form-share-cart .form-control {
    text-align: center;
}

.form-share-cart .form-control::-webkit-input-placeholder {
    /* Edge */
    text-align: center !important;
}

.form-share-cart .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    text-align: center !important;
}

.form-share-cart .form-control::placeholder {
    text-align: center !important;
}

.share-card-header {
    border-radius: 7px 7px 7px 7px !important;
}

.without-btn {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.text-strikethrough {
    text-decoration: line-through !important;
}

.social-upd a {
    text-decoration: none !important;
}

.maintenance-desc {
    font-weight: bold !important;
}

.maint-read-more-link {
    padding-top: 20px;
}

.maint-read-more-link a {
    text-decoration: none !important;
}

.maint-read-more-link-txt {
    font-size: 17px;
}

.update-form-center-title {
    padding-top: 20px;
}

.update-form-field {
    display: inline-block;
}

.card-form-center {
    width: 50%;
    position: relative;
    margin: 0 auto;
    padding-bottom: 30px;
}

.update-form-padding {
    padding-top: 20px;
    padding-right: 0;
    padding-left: 0;
}

.spinn-space {
    padding-left: 5px !important;
}

.healtcheck-form-font {
    font-family: Prompt, sans-serif !important;
}

.border-1px-solid-bit-grey-7-radius {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 7px;
    border-color: #e1e7ec;
}

.rank-badges .align-stuff {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .card-body-genealogy #rank-slider {
        margin-right: 0px;
        margin-left: 5px;
    }

    .rank-badges .align-stuff {
        padding-left: 0px;
        padding-right: 0px;
    }

    .btn-rank-slider-desktop {
        margin: 5px 5px;
    }
}

.modal-healthcheck-top {
    padding-top: 15px !important;
}

.healthcheck-popup-header {
    font-size: 18px;
    padding-bottom: 10px;
}

.healthcheck-popup-dsc {
    padding-bottom: 12px;
}

.modal-dialog.share-social {
    width: 410px !important;
}

.modal-dialog.share-social .close {
    font-size: 48px;
    font-weight: 100;
    margin-bottom: -35px;
    margin-top: -16px;
}

.modal-dialog.share-social .share-icon button {
    border-radius: 50px;
    margin: 0 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3a5998;
}

.modal-dialog.share-social .share-icon {
    margin-top: 25px;
}

.modal-dialog.share-social .share-icon button.fbBTshareicon {
    background-color: #3a5998 !important;
}

.modal-dialog.share-social .share-icon button.twBTshareicon {
    background-color: #00acec !important;
}

.modal-dialog.share-social .share-icon button.whatsappBTshareicon {
    background-color: #2ab741 !important;
}

.modal-dialog.share-social .share-icon button.whatsappBTshareicon svg {
    width: 25px;
    height: 25px;
}

.modal-dialog.share-social .share-icon button.emailBTshareicon {
    background-color: #2e80ec !important;
}

.modal-dialog.share-social .share-icon button.emailBTshareicon svg rect {
    fill: #2e80ec !important;
}

.modal-dialog.share-social .share-icon iframe {
    border-radius: 50px;
    margin: 0 8px;
    width: 40px !important;
    height: 40px !important;
    padding-top: 5px;
    padding-left: 5px;
    background-color: #02be01;
}

.modal-dialog.share-social .referral-link {
    width: 270px;
    height: 40px;
    border-radius: 5px;
    background: #f5f5f5;
    padding: 10px 10px;
    text-align: center;
    font-size: 12px;
}

.h6i {
    font-size: 16px !important;
}

.card.card-not-downline {
    background: #0da9ef;
    border-color: #0da9ef;
    color: #ffffff;
}

.card.card-not-downline span.text-muted {
    color: #cccccc !important;
}

.card.card-not-downline h4 {
    color: #ffffff;
}

.card.card-not-downline .btn-link {
    color: #ffffff;
    opacity: 0.75;
}

.card.card-not-downline .card-footer {
    background: #ffffff;
}

.btn-add-share-this-cart {
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 6px 10px;
    color: #0da9ef;
    background: transparent;
}

/* .credit-balance {
    color:#0da9ef !important;
} */

.ar-table-rank div:nth-child(1) {
    /* padding-top:8px !important; */
    padding-bottom: 10px !important;
}

.ar-table-rank div:nth-child(2) {
    /* padding-top:24px !important; */
    padding-bottom: 10px !important;
}

.ar-table-rank div:nth-child(3) {
    /* padding-top:22px !important; */
    padding-bottom: 10px !important;
}

.ar-table-rank div:nth-child(4) {
    /* padding-top:24px !important; */
    padding-bottom: 10px !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

.th-font-correction {
    font-family: Prompt, sans-serif !important;
}

.no-margin-no-padding {
    margin: 0 !important;
    padding: 0 !important;
}

/* ** Start ** New Material Style for Form Input. */

.form-container {
    margin: 40px 0 0 0;
}

.form-container .form-group {
    padding: 0;
    margin-bottom: 30px !important;
}

.form-container .form-group label {
    position: relative;
    top: -60px;
    margin: 0 auto;
    padding: 0;
    float: left;
    font-size: 14px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
    transition: all 0.15s ease-out;
}

.form-container .form-group .btn-profile-edit {
    position: relative;
    top: -60px;
    margin: 0;
    padding: 0;
    float: right;
    color: #0c9fed;
    background-color: white;
    border: 0;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
    transition: all 0.15s ease-out;
}

.form-container .form-group .btn-pills {
    position: relative;
    margin: 0;
    padding: 0;
    float: right;
    color: #0c9fed;
    background-color: white;
    border: 1px solid #dbe2e8;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    border-radius: 0 !important;
    top: 10px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
    transition: all 0.15s ease-out;
}

.form-container .form-group .btn-pills:disabled {
    color: #a0a0a0 !important;
    background-color: white !important;
}

.form-container .form-group .dropdown-menu {
    top: 20px !important;
    overflow-x: hidden !important;
    height: 160px;
}

.form-container .form-group .btn-pills:hover {
    background: transparent !important;
    color: #0c9fed !important;
}

.form-container .form-group button:hover {
    color: #6bcbff;
}

.form-container .form-group input {
    color: #606975;
    border: 1px solid #dbe2e8;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    padding: 0;
    margin: 6px 0 0 0;
    transition: all 0.15s ease-out;
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -moz-box-shadow: 0 0 0 30px white inset !important;
}

.form-container .form-group input:disabled {
    background: white !important;
    color: #a0a0a0 !important;
    border-bottom: 1px solid #f6f6f6 !important;
    padding: 0;
    margin: 6px 0 0 0;
    transition: all 0.15s ease-out;
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -moz-box-shadow: 0 0 0 30px white inset !important;
}

.form-container .form-group input:focus {
    border-bottom: 1px solid #0da9ef;
    transition: border 0.25s ease-out;
}

.form-container .form-group input:focus + label {
    color: #0da9ef !important;
    transition: color 0.15s ease-out;
}

/* ** End ** New Material Style for Form Input. */

.pointer-events-all {
    pointer-events: all !important;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
}

.background-none {
    background: none !important;
    background-color: none !important;
}

.w-35 {
    width: 35%;
}

.card table {
    margin: 0px;
}

.fixheight-spinner {
    width: 100%;
    height: 100%;
}

.fixheight-spinner .container {
    height: 100%;
}

.fixheight-spinner .container div {
    width: 100%;
    height: 100%;
}

.fixheight-spinner .container div .loadingImage {
    vertical-align: middle;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.btn-default.focus,
.btn-default:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}

.modal-480 {
    width: 480px !important;
}

.modal-640 {
    width: 640px !important;
}

.modal-480 .modal-content {
}

.modal-480 .modal-content .modal-header {
}

.modal-480 .modal-content .modal-body {
}

.modal-480 .modal-content .modal-footer {
}

.help-wrapper {
    width: 100%;
    height: 100%;
}

.help-wrapper .card {
    height: 100%;
    border: none !important;
}

.help-download {
    margin-bottom: 10px;
    background: url('https://d351kq7iyhnk3y.cloudfront.net/THA/banner/Banner-for-Desktop-01.jpg')
        center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #e1e7eb;
    border-radius: 5px;
    min-height: 170px;
    overflow: hidden;
    position: relative;
}

.help-download::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    content: '';
    opacity: 0;
    z-index: 1;
    transition: opacity 0.3s;
}

.help-download::after {
    display: block;
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    margin-top: -19px;
    -webkit-transform: translateY(15px);
    -ms-transform: translateY(15px);
    transform: translateY(15px);
    transition: all 0.35s;
    color: #fff;
    font-family: 'Glyphicons Halflings';
    font-size: 36px;
    text-align: center;
    content: '\e166';
    opacity: 0;
    z-index: 5;
}

.help-wrapper .card:hover .help-download::before {
    opacity: 0;
}

.help-wrapper .card:hover .help-download::after {
    opacity: 0;
}

.dont-have-shopping-cart {
    border: 1px solid #e1e7eb;
    border-radius: 5px;
    min-height: 170px;
    text-align: center;
    font-size: 16px;
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
}

.dont-have-shopping-cart p {
    margin-bottom: 10px !important;
}

.dont-have-shopping-cart button {
    margin-top: 0px;
}

.paying-back {
    margin-bottom: 10px !important;
    margin-top: -25px !important;
}

.link-table-cell {
    color: #0da9ef;
}

.text-checkbox {
    width: 100%;
    float: left;
    word-break: word-wrap;
}

.text-checkbox .custom-checkbox .custom-control-label {
    margin-top: -3px;
}

.currency-symbol {
    display: inline;
    margin-right: 3px;
}

.pvpont-text {
    display: inline;
    margin-right: 5px;
}

.doesnt_have_share_cart {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    padding: 0px;
}

@media (max-width: 768px) {
    .modal-480,
    .modal-640 {
        width: auto !important;
    }
}

@media (max-width: 767px) {
    .commlsb-mobile .card {
        margin: 10px;
    }

    .commlsb-mobile .card {
        margin: 10px;
    }

    .lsb-col {
        border: 1px solid #e1e7ec;
        border-radius: 7px !important;
        margin-bottom: 0px !important;
    }

    .table-comm-lasb-mob {
        padding: 0px 0px !important;
    }

    .modal-dialog.share-social {
        max-width: 90% !important;
    }

    .modal-dialog.share-social .modal-body {
        padding-left: 0;
        padding-right: 0;
    }

    .modal-dialog.share-social .referral-link {
        width: 256px;
    }

    .modal-dialog.share-social .share-icon button,
    .modal-dialog.share-social .share-icon iframe {
        margin: 0 6px;
    }

    .modal-dialog.share-social canvas {
        height: 160px !important;
        width: 160px !important;
    }
}

.custom-alert {
    display: block;
    border: 1px solid rgba(255, 82, 82, 0.12);
    border-radius: 5px;
    color: #ff5252;
    background-color: rgba(255, 82, 82, 0.12);
    margin-bottom: 25px;
    font-size: 13px;
}

.custom-alert > ul {
    padding: 6px;
    border: 4px solid white;
    border-radius: 5px;
    text-align: center;
    list-style: none;
}

.text-hard-grey {
    color: #4e4e4e;
}

.table-seminar {
}

.table-seminar thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
    font-size: 14px;
    padding-top: 0px;
    border-top: 0px;
    color: #606975;
}

.table-seminar td {
    border: none !important;
    font-size: 14px;
}

.seminar table tr td {
    vertical-align: middle !important;
}

.card-body .pv-cost {
    margin: 0 0 8px;
}

.card-body .media-body .text-danger {
    display: block;
    clear: both;
}

.card-header-with-border-bottom {
    border-bottom: 1px solid #e1e7ec;
}

.font-size-12px {
    font-size: 12px !important;
}

.font-size-14px {
    font-size: 14px !important;
}

.font-size-16px {
    font-size: 16px !important;
}

.font-key-title-grey {
    color: #696075;
    font-weight: 500;
}

.font-weight-500 {
    font-weight: 500;
}

.btn-ranking {
    cursor: default !important;
    color: #000000;
}

.profile-view-ba {
    padding: 1.25rem;
}

.profile-view-ba .row {
    margin-bottom: 10px;
}

.table.genealogyCard.ba-info tr:first-child > td {
    border-top: 0px;
}

.text-rank {
    color: #000000 !important;
}

.text-month {
    color: #9da9b9;
}

.hide-seminar-result {
    display: none;
}

.show-more-months-comm {
    color: black;
}

.lsb-table-sum {
    margin-top: 14px !important;
}

.floating-icon-cart {
    border: 1px solid red;
    width: auto;
    height: 100%;
    display: table-cell;
    padding: 20px;
    border-radius: 40px;
    position: relative;
    font-size: 2rem;
}

span > sup {
    left: 2px;
}

.order-history-loader .loadingImage {
    height: 285px;
}

.page-title .column:first-child {
    padding-right: 0;
}

.site-menu > ul > li.active > a,
.active .nav-link,
.active .nav-link:hover {
    color: #0da9ef !important;
}

.site-menu ul > li > a {
    color: #606975 !important;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.no-padding-top {
    padding-top: 0px !important;
}

.info-button-seminar > img {
    border: 1px solid #e1e7ec;
    border-radius: 16px;
    width: 15px;
    height: 15px;
    color: rgba(0, 0, 0, 0.85);
    padding: 2px;
    position: inherit;
}

.card-body-genealogy .table-responsive::-webkit-scrollbar {
    display: none;
}

.card-body-genealogy .table-responsive {
    -ms-overflow-style: none;
}

.mb-125rem {
    margin-bottom: 1.25rem !important;
}

.footer-address2 {
    margin-bottom: 0px !important;
}

.footer-address2-b {
    margin-bottom: 14px !important;
}

.padding-lr-125rem {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.link-color {
    color: #0da9ef !important;
}

.highlight-table {
    background: #e4f2f9;
}

#profile-side .ant-skeleton-content {
    padding: 5px 15px;
}

/* ----------------- */

.numeric-font {
    font-family: 'Roboto' !important;
}

.contact tbody > tr td:nth-child(2) {
    text-align: right;
}

.information tbody > tr td:nth-child(2) {
    text-align: right;
}

.credit-balance tbody > tr td:nth-child(2) {
    text-align: right;
}

/* ----------------- */

.w-max-content {
    width: max-content;
}

/* Hide scrollbar for Chrome, Safari and Opera */

.table-responsive::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */

.table-responsive {
    -ms-overflow-style: none;
}

#cart-actions .ant-statistic.numeric.monospace .ant-statistic-content-value-int,
#cart-actions
    .ant-statistic.numeric.monospace
    .ant-statistic-content-value-decimal {
    font-size: 16px !important;
}

#cart-actions .ant-statistic.numeric.monospace .ant-statistic-content-suffix {
    color: #9da9b9;
}

.qr-code-th {
    cursor: pointer;
    padding: 0 !important;
}

.qr-code-th img {
    border-radius: 7px;
    width: 120px;
}

.footer-block-bottom {
    margin: 0;
}

.footer-block-bottom .partner-logo-layout {
    margin-bottom: 30px;
}

.footer-block-bottom .partner-logo-layout:first-child {
    text-align: right;
    padding-right: 12.5px;
}

.footer-block-bottom .partner-logo-layout:last-child {
    text-align: left;
    padding-left: 12.5px;
}

.footer-block-bottom .partner-logo-layout .partner-logo {
    height: 51px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .footer-block-bottom {
        padding: 0 55px;
    }

    .footer-block-top .col-md-6 {
        padding: 0 60px;
    }

    .footer-block-top .col-md-6:nth-of-type(odd) {
        padding-right: 50px;
    }

    .footer-block-top .col-md-6:nth-of-type(even) {
        padding-left: 50px;
    }

    .site-footer .hr-light {
        margin-left: 45px;
        margin-right: 45px;
    }

    /* .footer-block-bottom .col-md-6 {
        padding: 0 55px;
        padding-right: 20px;
    } */
    .footer-block-bottom .col-md-5 {
        padding: 0;
    }

    .footer-block-bottom .getinfomation-form .form-text {
        padding-left: 0;
    }

    .footer-block-bottom .partner-logo-layout {
        text-align: center;
    }
}

@media only screen and (max-width: 415px) {
    .footer-block-bottom .partner-logo-layout {
        margin-bottom: 20px;
        padding: 0;
    }

    .footer-block-bottom .partner-logo-layout .partner-logo {
        height: 31.36px;
    }

    .footer-block-bottom .lang-zone {
        padding: 0;
    }

    .footer-block-bottom .partner-logo-layout:first-child {
        padding: 0;
        text-align: center;
    }

    .footer-block-bottom .partner-logo-layout:last-child {
        padding: 0;
        margin-bottom: 30px;
        text-align: center;
    }
}

@media only screen and (max-width: 414px) {
    .site-footer {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media only screen and (max-width: 376px) {
    .site-footer {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.extra-page-title {
    display: table;
    margin: 0 auto;
    color: black;
}

.extra-page-title h1 {
    color: black;
    font-size: 20px;
    font-weight: 400;
}

.card-container {
    position: relative;
    /*cursor: pointer;*/
}

.card-container.flipped .front {
    transform: rotateY(180deg);
}

.card-container.flipped .back {
    transform: rotateY(0deg);
}

.card-container .front,
.card-container .back {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: block;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: -webkit-transform ease 500ms;
    transition: transform ease 500ms;
}

.card-container .front {
    z-index: 2;
    transform: rotateY(0deg);
    /* front tile styles go here! */
}

.card-container .front .front-detail {
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.card-container .front .front-detail p span.titlec {
    color: #43b8ea;
    font-weight: bold;
    font-size: 20px;
}

.card-container .front .front-detail p span.detailc {
    /* font-weight: bold; */
    font-size: 20px;
}

.card-container .front .front-detail p,
.card-container .back .back-detail p {
    padding-left: 40px;
    padding-right: 40px;
}

.card-container .front .front-detail p span.detailc-baid {
    /* font-weight: bold; */
    font-size: 30px;
}

.card-container .front .front-detail p span.detailc-name {
    /* font-weight: bold; */
    font-size: 15px;
}

.card-container .front .front-detail p span.detailc-since {
    color: #9ea9b9;
    font-size: 13px;
}

.card-container .front .front-detail .flip-image,
.card-container .back .back-detail .flip-image {
    width: 12%;
}

.card-container .back {
    transform: rotateY(-180deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    /* back tile styles go here! */
}

.card-container .back .back-detail {
    color: #fff;
    position: absolute;
    top: 35%;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.card-container .back .image-container img.backc-image {
    object-fit: cover;
}

.card-container .back .back-detail .table-backcard {
    width: 90%;
    margin: 0 auto;
}

.card-container .back .back-detail p span.txt_title1 {
    font-size: 12px;
}

.card-container .back .back-detail p span.txt_title2 {
    font-size: 18px;
    letter-spacing: 5px;
}

.card-container .back .back-detail p span.txt_title3 {
    font-size: 15px;
}

.card-container .back .back-detail p span.txt_title4 {
    font-size: 12px;
    color: #9ea9b9;
}

.card-container .back .back-detail .txt_start_share {
    font-size: 16px;
    color: #9ea9b9;
    padding-top: 5px;
}

.card-container .back .back-detail .cardQrEnroll {
    background-color: #ffffff;
    margin-left: 40px;
    border-radius: 14px;
}

.card-container .back .back-detail .cardQrShop {
    background-color: #ffffff;
    margin-right: 40px;
    border-radius: 14px;
}

.card-container .back .back-detail .cardQrEnroll .txt_cardqr,
.card-container .back .back-detail .cardQrShop .txt_cardqr {
    color: #000000;
    white-space: nowrap;
    letter-spacing: 1px;
    font-size: 18px;
    padding-top: 10px;
    margin-bottom: 0px;
}

.card-container .back .back-detail .cardQrEnroll p,
.card-container .back .back-detail .cardQrShop p {
    padding: 0;
}

.card-container .back .back-detail .txt_qr img.dgmQRimg {
    border-radius: 14px;
}

.dgmModal .modal-content {
    background: none;
    border: none;
}

.customlink .table thead th,
.table td.tbDGCT {
    border: none;
    white-space: nowrap;
    font-weight: bold;
    padding-bottom: 0px;
    color: #9ea9b9;
}

.customlink .table thead th,
.table td.tbDGC {
    border: none;
    padding-bottom: 0px;
}

.customlink .table thead th,
.table td.tbDGC span {
    display: inline-block;
    height: 18px;
    overflow: hidden;
}

/* .card-container .front .image-container img.user-image{
    border-radius: 25px;
    width: auto;
    object-fit: cover;
} */

.card-container .front .image-container img.frontc-image {
    /* position: absolute;
    width: auto; */
    object-fit: cover;
    /* left: 0;
    right: 0;
    margin: 0 auto; */
}

.iconFlipCard {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 12%;
}

.card-container .back .back-detail .cardQrEnroll:hover,
.card-container .back .back-detail .cardQrShop:hover {
    background-color: #9ea9b9;
    cursor: pointer;
}

@media (min-width: 320px) and (max-width: 374.98px) {
    .card-container .front,
    .card-container .back {
        max-height: 435px;
        overflow: hidden;
        max-width: 290px;
        margin: 0 auto;
    }

    .card-container .front .image-container img.frontc-image,
    .card-container .back .image-container img.backc-image {
        height: 435px;
    }

    .card-container .back .back-detail .dgmQR img.dgmQRimg {
        width: 100px;
        border-radius: 10px;
    }

    .card-container .front .front-detail {
        top: 60%;
    }

    .card-container .front .front-detail p.baname {
        /* font-weight: bold; */
        height: 20px;
        overflow: hidden;
    }

    .card-container .back .back-detail p {
        margin-bottom: 10px;
    }

    .card-container .back .back-detail {
        color: #fff;
        position: absolute;
        top: 31%;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }

    .card-container .back .back-detail p {
        padding-left: 20px;
        padding-right: 20px;
    }

    .card-container .back .back-detail .cardQrEnroll {
        margin-left: 35px;
    }

    .card-container .back .back-detail .cardQrShop {
        margin-right: 35px;
    }

    .card-container .back .back-detail .cardQrEnroll .txt_cardqr,
    .card-container .back .back-detail .cardQrShop .txt_cardqr {
        padding-top: 5px;
        font-size: 13px;
    }

    .card-container .back .back-detail .txt_start_share {
        font-size: 14px;
    }

    .card-container .back .back-detail .flip-image {
        width: 11%;
    }
}

/* Extra small devices (portrait phones, less than 576px)*/

@media (min-width: 375px) and (max-width: 413.98px) {
    .card-container .front,
    .card-container .back {
        max-height: 520px;
        overflow: hidden;
        max-width: 345px;
        margin: 0 auto;
    }

    .card-container .front .image-container img.frontc-image,
    .card-container .back .image-container img.backc-image {
        height: 520px;
    }

    .card-container .back .back-detail .dgmQR img.dgmQRimg {
        width: 150px;
        border-radius: 10px;
    }

    .card-container .front .front-detail {
        top: 65%;
    }

    .card-container .back .back-detail .txt_start_share {
        font-size: 14px;
    }

    .card-container .front .front-detail p.baname {
        /* font-weight: bold; */
        height: 20px;
        overflow: hidden;
    }

    /* .card-container .back  .back-detail p{
        margin-bottom: 5px;
    } */
    .card-container .back .back-detail {
        color: #fff;
        position: absolute;
        top: 32%;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }
}

/* Small devices (landscape phones, 576px and up)*/

@media (min-width: 414px) and (max-width: 767.98px) {
    .card-container .front,
    .card-container .back {
        max-height: 580px;
        overflow: hidden;
        max-width: 384px;
        margin: 0 auto;
    }

    .card-container .front .image-container img.frontc-image,
    .card-container .back .image-container img.backc-image {
        height: 580px;
    }

    .card-container .back .back-detail .dgmQR img.dgmQRimg {
        width: 180px;
        border-radius: 10px;
    }

    .card-container .front .front-detail {
        top: 65%;
    }

    .customlink .table thead th,
    .table td.tbDGCT,
    .customlink .table thead th,
    .table td.tbDGC {
        padding-bottom: 5px;
        font-size: 13px;
    }

    .card-container .front .front-detail p.baname {
        /* font-weight: bold; */
        height: 20px;
        overflow: hidden;
    }

    .card-container .back .back-detail p {
        margin-bottom: 15px;
    }

    .card-container .back .back-detail {
        color: #fff;
        position: absolute;
        top: 35%;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }
}

/* Medium devices (tablets, 768px and up)*/

@media (min-width: 768px) and (max-width: 991.98px) {
    .card-container .front,
    .card-container .back {
        max-height: 580px;
        overflow: hidden;
        max-width: 384px;
        margin: 0 auto;
    }

    .card-container .front .image-container img.frontc-image,
    .card-container .back .image-container img.backc-image {
        height: 580px;
    }

    .card-container .back .back-detail .dgmQR img.dgmQRimg {
        width: 180px;
        border-radius: 10px;
    }

    .card-container .front .front-detail {
        top: 65%;
    }

    .customlink .table thead th,
    .table td.tbDGCT,
    .customlink .table thead th,
    .table td.tbDGC {
        padding-bottom: 5px;
        font-size: 13px;
    }

    .card-container .back .back-detail p {
        margin-bottom: 15px;
    }

    .card-container .back .back-detail {
        color: #fff;
        position: absolute;
        top: 34%;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }
}

/* Large devices (desktops, 992px and up)*/

@media (min-width: 992px) and (max-width: 1199.98px) {
    .card-container .front {
        float: right;
    }

    .card-container .back {
        float: left;
    }

    .card-container .front,
    .card-container .back {
        max-height: 580px;
        overflow: hidden;
        max-width: 384px;
        margin: 0 auto;
    }

    .card-container .front .image-container img.frontc-image,
    .card-container .back .image-container img.backc-image {
        height: 580px;
    }

    .card-container .back .back-detail .dgmQR img.dgmQRimg {
        width: 180px;
        border-radius: 10px;
    }

    .card-container .front .front-detail {
        top: 65%;
    }

    .customlink .table thead th,
    .table td.tbDGCT,
    .customlink .table thead th,
    .table td.tbDGC {
        padding-bottom: 8px;
        padding-top: 8px;
        font-size: 13px;
    }

    .card-container .back {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
        position: relative;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1200px) {
    .card-container .front {
        float: right;
    }

    .card-container .back {
        float: left;
    }

    .card-container .front,
    .card-container .back {
        max-height: 580px;
        overflow: hidden;
        max-width: 384px;
        margin: 0 auto;
    }

    .card-container .front .image-container img.frontc-image,
    .card-container .back .image-container img.backc-image {
        height: 580px;
    }

    .card-container .back .back-detail .dgmQR img.dgmQRimg {
        width: 220px;
        border-radius: 10px;
    }

    .card-container .front .front-detail {
        top: 66%;
    }

    .customlink .table thead th,
    .table td.tbDGCT,
    .customlink .table thead th,
    .table td.tbDGC {
        padding-bottom: 8px;
        padding-top: 8px;
        font-size: 13px;
    }

    .card-container .back {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
        position: relative;
    }
}

.boxButtonBackDMC {
    width: 250px;
    margin: 0 auto;
}

/* .slider-carousel-home .slick-slide {
    height: auto !important;
} */

.slider-carousel-home .slick-prev {
    /* left: -71px !important; */
    top: 50% !important;
}

.slider-carousel-home .slick-next {
    /* right: -71px !important; */
    top: 50% !important;
}

.slider-carousel-home .slick-dots {
    display: none !important;
}

.disclaimer-content {
    background: #f5f5f5;
    font-family: 'Noto Sans', 'Noto Sans Thai', sans-serif !important;
}

.disclaimer-content .title-disclaimer {
    font-family: 'Noto Sans', 'Noto Sans Thai', sans-serif !important;
    font-weight: bold;
}

.unicity-description {
    text-align: center;
    padding-top: 62px;
}

.unicity-description .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 40px;
}

.unicity-description .sub-title {
    font-weight: bold;
    font-size: 24px;
    color: #384250;
}

.slider-carousel-home .slick-dots {
    background: none;
}

.slider-carousel-home .slick-dots li button {
    border-radius: 11px;
    background: #9ea9b9;
    width: 12px;
    height: 10px;
}

.slider-carousel-home .slick-dots li.slick-active button {
    background: #22aaec;
    width: 25px;
    height: 10px;
    transition: 0.2s;
}

.slider-carousel-home .slick-dots li button::before {
    color: #9ea9b9 !important;
    opacity: 1;
    /* content: url(../assets/icons/svg/dot.svg); */
    content: none !important;
}

.slider-carousel-home .slick-dots {
    bottom: -101px !important;
    width: 100% !important;
    left: 0px !important;
    height: 45px !important;
}

.slider-carousel-home .slick-dots li {
    margin: 0 4px !important;
    width: auto;
    height: auto;
}

.disclaimer-content .img-fluid {
    width: 80px;
    height: 80px;
}

@media (max-width: 1024px) {
    .unicity-disclaimer {
        padding: 0px 144px;
    }

    .unicity-description {
        padding-right: 64px;
        padding-left: 64px;
    }
}

@media (max-width: 768px) {
    .unicity-description {
        padding-top: 56px;
    }
}

@media (max-width: 767px) {
    .unicity-description {
        padding: 0px 20px;
        padding-top: 60px;
    }

    .unicity-description .title {
        font-size: 34px;
        margin-bottom: 30px;
    }

    .unicity-description .sub-title {
        padding: 0px 8px;
        font-size: 20px;
    }

    .unicity-disclaimer {
        padding: 46px 20px !important;
    }

    .disclaimer-content .img-fluid {
        width: 65px;
        height: 65px;
    }
}

@media (max-width: 320px) {
    .unicity-description .title {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .unicity-description .sub-title {
        font-size: 18px;
    }

    .unicity-disclaimer {
        padding: 0px 20px;
    }
}

.page-spin {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
    min-height: 100% !important;
}

@media screen and (max-width: 1200px) {
    .app-padding-sc-1200 {
        padding-bottom: 15px;
    }
}

@media screen and (min-width: 375px) and (max-width: 411px) {
    .download-sc-375 {
        padding-right: 10px;
    }

    .download-sc-375:last-child {
        padding-left: 10px;
    }

    .text-sc-375 {
        margin: 0;
        width: 100%;
    }

    .layout-3-app-jp-footer {
        padding-left: 0px;
        padding-right: 0px;
    }

    .app-jp-375 {
        width: 95%;
    }

    .pic-autoship {
        width: 335px !important;
        /* height: 464px; */
    }
}

@media screen and (min-width: 412px) and (max-width: 430px) {
    .layout-3-app-jp-footer {
        padding-left: 15px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 1024px) {
    .display-none-375-1024 {
        display: none;
    }

    .display-block-375-1024 {
        display: block;
    }
}

@media screen and (min-width: 1439px) {
    .display-block-375-1024 {
        display: none;
    }
}

.active_country {
    color: #fff;
    font-weight: normal;
}

.sign-country {
    padding: 0px 5px;
    opacity: 0.2;
}

.none_active {
    font-weight: normal;
    opacity: 0.5;
}

.none_active:hover {
    font-weight: normal;
    opacity: 1;
}

.country_inline {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    color: white;
}

.badge-status-login {
    position: absolute;
    width: 100%;
    left: -8px;
    top: 17px;
}

.text-country {
    cursor: pointer;
}

.text-country .sign {
    opacity: 0.2;
}

.order-details-read-tc {
    color: #22aaec;
    cursor: pointer;
    margin-left: -6px;
}

.catproduct-item-plus-and-min path {
    fill: rgb(158, 169, 185);
}

.download-pcc {
    background-color: #f5f5f5 !important;
    padding-bottom: 100px;
}

.download-pcc .download-pcc-header {
    background-color: #f5f5f5;
    font-size: 26px;
    color: black;
    padding-top: 35px;
    font-weight: bold;
}

.download-pcc-header-div {
    text-align: center;
    padding: 20px;
}

.download-pcc .download-pcc-content {
    background-color: #f5f5f5;
    margin-top: 20px;
}

.menu-language {
    padding: 0;
    text-align: center;
    border-radius: 7px;
}

.menu-language .lang {
    padding: 12px 0px;
    color: #9ea9b9;
}

.menu-language .lang:hover {
    background: none;
    color: #384250;
    transition: none;
}

.menu-language .lang:first-child:hover {
    border-radius: 7px 7px 0px 0px;
}

.menu-language .lang:last-child:hover {
    border-radius: 0px 0px 7px 7px;
}

.menu-language .lang .scorp-spinner {
    position: relative;
}

.menu-language .lang .scorp-spinner .spinner-position {
    position: absolute;
    top: -6px;
    left: -39px;
    width: 100%;
}

/* cart new design */

.footer-cart {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    font-weight: normal;
    line-height: 14px;
    font-size: 12px;
}

.btn-checkout-layout {
    display: flex;
    justify-content: center;
}

.remove-item-cart {
    width: 35%;
    position: absolute;
    top: 10px;
    left: 15px;
    height: auto;
    width: 12px;
}

.remove-item-cart path {
    fill: #9ea9b9;
}

.remove-item-cart path:hover {
    fill: #fe5d7c;
}

.remove-item-fast-cart {
    width: 35%;
    position: absolute;
    top: 10px;
    right: 15px;
    height: auto;
    width: 12px;
}

.remove-item-fast-cart path {
    fill: #9ea9b9;
}

.remove-item-fast-cart path:hover {
    fill: #fe5d7c;
}

.dialog-delete {
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 100%;
    display: flex;
    align-items: center;
}

.dialog-delete .ant-drawer-body {
    height: 100% !important;
}

.center-content-mobile {
    display: flex;
    align-items: center;
}

.font-size-add-product {
    font-size: 15px;
}

@media only screen and (min-width: 1023px) and (max-width: 1920px) {
    .toolbar-cart {
        background: #ffffff;
        height: 80px;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .padding-item-cart {
        padding-top: 30px;
    }

    .margin-between-item-cart {
        margin-bottom: 30px;
    }

    .btn-checkout {
        width: 250px;
        height: 40px;
    }

    .padding-between-hr {
        background-color: #e3e8ed;
        position: relative;
        padding-top: 30px;
        padding-bottom: 60px;
    }

    .cart-empty {
        margin-top: 60px;
        margin-bottom: 30px;
    }

    .add-product-cart-empty {
        margin-top: 60px;
    }

    .size-img-cart-empty {
        width: 30%;
    }
}

@media only screen and (min-width: 415px) and (max-width: 1024px) {
    .toolbar-cart {
        background: #ffffff;
        /* background: red; */
        height: 80px;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .padding-item-cart {
        padding-top: 20px;
    }

    .margin-between-item-cart {
        margin-bottom: 30px;
    }

    .btn-checkout {
        width: 250px;
        height: 40px;
    }

    .padding-between-hr {
        background-color: #e3e8ed;
        position: relative;
        padding-top: 30px;
        padding-bottom: 51px;
    }

    .cart-empty {
        margin-top: 60px;
        margin-bottom: 30px;
    }

    .add-product-cart-empty {
        margin-top: 60px;
    }

    .size-img-cart-empty {
        width: 30%;
    }
}

@media only screen and (min-width: 1px) and (max-width: 414px) {
    .toolbar-cart {
        background: #ffffff;
        /* background: red; */
        height: 70px;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .padding-item-cart {
        padding-top: 20px;
    }

    .margin-between-item-cart {
        margin-bottom: 10px;
    }

    .btn-checkout {
        width: 150px;
        height: 40px;
    }

    .padding-between-hr {
        background-color: #e3e8ed;
        position: relative;
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .cart-empty {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .add-product-cart-empty {
        margin-top: 30px;
    }

    .size-img-cart-empty {
        width: 80%;
    }
}

/* end cart new design */

.ant-skeleton.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(30%, #f6f9fd),
        color-stop(37%, #d8dde3),
        color-stop(63%, #f6f9fd)
    );
    background: linear-gradient(90deg, #f6f9fd 30%, #d8dde3 37%, #f6f9fd 63%);
    background-size: 400% 100%;
}

.app-store-title {
    display: block;
    font-size: 1.85rem;
    white-space: pre;
    color: #fff;
}

.app-store-subtitle {
    display: block;
    font-size: 1.15rem;
    line-height: 1;
    white-space: pre;
    color: #fff;
    opacity: 0.6;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(25%, #d8dde3),
        color-stop(50%, #f6f9fd),
        color-stop(60%, #d8dde3)
    );
    background: linear-gradient(90deg, #d8dde3 25%, #f6f9fd 50%, #d8dde3 60%);
    background-size: 600% 100%;
    border-radius: 7px;
}

#profile-side.bioslifeair {
    min-height: auto;
}

/* Seminars  V2 */

.text-leadership_seminars {
    /* font-family: Maven Pro; */
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.text-header-card-abs {
    font-size: 18px;
    font-weight: bold;
}

#profile-side.auto-height {
    min-height: auto;
}

/* AutoShip Japan new design */

.display-detail-autoship {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.autoship-margin-bottom-between-card .ant-card .ant-card-body {
    padding: 20px;
}

.autoship-margin-bottom-between-card-empty .ant-card .ant-card-body {
    padding: 0px;
}

.text-align-end {
    word-break: break-all;
    text-align: end;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
}

.head-card-autoship {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.detail-card-autoship {
    margin-top: 5px;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #9ea9b9;
    padding-right: 0px;
}

.accordion .ant-card .ant-card-body {
    padding: 20px;
}

.autoship-product-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.autoship-head {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 21px;
}

.accordion [data-toggle='collapse'].collapsed::after {
    /* display: none; */
    color: white;
}

.accordion [data-toggle='collapse']::after {
    /* display: none; */
    color: white;
}

.autoship-show-detail {
    width: 20px;
    height: 3px;
    background: #9ea9b9;
    border-radius: 6px;
}

.autoship-edit-delete {
    display: flex;
    justify-content: center;
}

.card-autoship-shadow {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    border: 0px;
}
.card-autoship-shadow .ant-card-body {
    height: 100%;
}

.card-autoship-shadow .ant-card-body {
    padding: 0px;
}

.card-autoship-shadow-empty {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    border: 0px;
}
.card-autoship-shadow-empty .ant-card-body {
    height: 100%;
}

.card-autoship-shadow-empty .ant-card-body {
    padding: 0px !important;
}

.add-autoship {
    margin: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

.add-autoship-have-items {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

.card-seminars-layout {
    padding-bottom: 30px;
}

.seminars td,
th {
    text-align: center;
}

@media only screen and (min-width: 1023px) and (max-width: 1920px) {
    .marginbot-seminars-v2 {
        margin-bottom: 30px;
    }
    .padding-btn-2-box {
        padding-top: 100px;
    }
    .btn-seminars-2-box-width {
        width: 212px;
    }
    .width-box-btn-2-box {
        width: 80%;
    }
    .img-autoship-empty {
        margin-top: 40px;
    }
    .pic-autoship {
        width: 816px;
        /* height: 644px; */
    }
}

@media only screen and (min-width: 415px) and (max-width: 1024px) {
    .marginbot-seminars-v2 {
        margin-bottom: 30px;
    }
    .padding-btn-2-box {
        padding-top: 100px;
    }
    .btn-seminars-2-box-width {
        width: 158px;
    }
    .width-box-btn-2-box {
        width: 90%;
    }
    .img-autoship-empty {
        margin-top: 40px;
    }
    .pic-autoship {
        width: 714px;
        /* height: 690px; */
    }
}

@media only screen and (min-width: 1px) and (max-width: 415px) {
    .marginbot-seminars-v2 {
        margin-bottom: 20px;
    }
    .padding-btn-2-box {
        padding-top: 100px;
    }
    .btn-seminars-2-box-width {
        width: 159px;
    }
    .width-box-btn-2-box {
        width: 90%;
    }
    .img-autoship-empty {
        margin-top: 30px;
    }
    .pic-autoship {
        width: 374px;
        /* height: 464px; */
    }

    .add-autoship {
        margin: 40px 0px;
    }
}

/* end Seminars  V2 */

.td-text-and-width-top {
    width: 45%;
    color: #9ea9b9;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-top: 10px;
}

.td-text-and-width-bot {
    width: 50%;
    color: #9ea9b9;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.hr-autoship {
    border: 1px solid #384250;
    margin-top: 0px;
    margin-bottom: 0px;
    background: rgb(56, 66, 80);
}

.hr-autoship-grey {
    border: 1px solid #e3e8ed;
    margin-top: 0px;
    margin-bottom: 0px;
    background: #e3e8ed;
}

.total-autoship {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.modal-delete-autoship .modal-header span {
    font-size: 40px;
}

.border-in-table {
    border-bottom: 2px solid #e3e8ed !important;
}

.modal-button-position-delete .ant-btn-loading-icon {
    display: flex;
}

.autoship-edit-delete .ant-btn-loading-icon {
    display: flex;
}

.add-autoship .ant-btn-loading-icon {
    display: flex;
}

/* .border-bottom-item-autoship {
    border-bottom: 2px solid #E3E8ED;
}

.border-bottom-item-autoship :last-child {
    border-bottom: none;
} */

.btn-save-autoship .ant-btn-loading-icon {
    display: flex;
}

/* pc only */

@media only screen and (min-width: 1025px) {
    .padding-addproduct-autoship {
        padding: 0 250px;
    }

    .padding-detail-autoship {
        padding: 0 250px;
    }
}

@media (max-width: 880px) {
    .img-autoship-size {
        height: 75%;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1920px) {
    .autoship-margin-bottom-between-card {
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .padding-butoon-add-autoship {
        padding-left: 40px;
        padding-right: 40px;
        min-width: 270px;
    }

    .autoship-margin-bottom-between-card-empty {
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .img-autoship-size {
        height: 75%;
    }

    .modal-button-position-cancel {
        justify-content: flex-start;
    }

    .modal-button-position-delete {
        justify-content: flex-end;
    }

    .margin-between-button {
        margin-bottom: 20px;
    }

    .modal-size-autoship {
        margin: 42px;
    }
}

@media only screen and (min-width: 415px) and (max-width: 1024px) {
    .autoship-margin-bottom-between-card {
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .autoship-margin-bottom-between-card-empty {
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .padding-butoon-add-autoship {
        padding-right: 40px;
        padding-left: 40px;
        min-width: 270px;
    }

    .modal-button-position-cancel {
        justify-content: flex-start;
    }

    .modal-button-position-delete {
        justify-content: flex-end;
    }

    .margin-between-button {
        margin-bottom: 20px;
    }

    .modal-size-autoship {
        margin: 30px;
    }
}

@media only screen and (min-width: 1px) and (max-width: 414px) {
    .autoship-margin-bottom-between-card {
        margin-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .autoship-margin-bottom-between-card-empty {
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .padding-butoon-add-autoship {
        /* padding-left: 20px;
        padding-right: 20px; */
        min-width: 270px;
    }

    .img-autoship-size {
        height: 75%;
    }

    .modal-button-position-cancel {
        justify-content: flex-start;
    }

    /* .modal-button-position-cancel {
        justify-content: center;
    } */
    .modal-button-position-delete {
        justify-content: flex-end;
    }

    /* .modal-button-position-delete {
        justify-content: center;
    } */
    /* .reverse-col-model-delete {
        flex-direction: column-reverse;
        margin-top: 30px;
    } */
    .modal-size-autoship {
        margin: 0px;
    }
}

/* end AutoShip Japan new design */
.fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.input-password-form-item-custom
    > .ant-form-item-control
    > .ant-form-item-control-input
    > .ant-form-item-children-icon {
    top: 45%;
}

.prod-card-table-detail > thead > tr > th {
    text-align: center;
    border-top: 2px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .prod-card-table-detail > thead:before {
    line-height: 1em;
    content: ".";
    color: white;
    display: block;
  }

  .prod-card-table-detail > tbody > tr > td {
    text-align: center;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .pay-summary .title {
    font-size: large;
  }

  .product-card .col-product-qtyinput {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }

  .product-card .antd-qty-input button {
    height: 45px;
    width: 45px;
  }

  .symbol-ban-1 {
    color: gray;
    opacity: 0.6;
    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  }

  .fa-ban:before {
    content: "\f05e"; }

.fa,
.fas,
.far,
.fal,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1; }

    .fa,
    .fas {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900; }

    @font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("../webfonts/fa-regular-400.eot");
    src: url("../webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.woff") format("woff"), url("../webfonts/fa-regular-400.ttf") format("truetype"), url("../webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

    @font-face {
        font-family: 'Font Awesome 5 Free';
        font-style: normal;
        font-weight: 900;
        font-display: auto;
        src: url("../webfonts/fa-solid-900.eot");
        src: url("../webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.woff") format("woff"), url("../webfonts/fa-solid-900.ttf") format("truetype"), url("../webfonts/fa-solid-900.svg#fontawesome") format("svg"); }

    .grayscale-image {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        }
          