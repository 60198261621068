.filter-modal-box {
    webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    h4 {
        margin: 0px;
        font-size: 18px;
    }

    .ant-modal-close {
        top: 7px;
    }

    .ant-modal-header {
        border-bottom: none;
        text-align: center;
        border-radius: 20px 20px 0px 0px;
        padding: 40px 24px 20px 24px;
    }

    .ant-modal-footer {
        text-align: center;
        border-top: none;
        padding: 20px 0px;
    }

    .ant-modal-body {
        padding: 20px 40px;
        background-color: #e3e8ed;

        .genealogy-search-result-item {
            background-color: #ffffff;

            text-align: center;


            p {
                font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: auto;
            }
        }


    }

    &.genealogy-bookmark {
        .ant-modal-body {
            border-radius: 0px 0px 20px 20px;
        }

        .genealogy-search-result-item {
            &.single-line {
                >div {
                    width: 100%;
                    height: 100%;
                }
            }

            grid-template-columns: auto 20px;
        }

        .genealogy-result--skeleton {
            flex-direction: column;
            width: 100%;

            .ant-skeleton {
                width: 100%;

                .ant-skeleton-button {
                    border-radius: 7px;
                }
            }

        }
    }

    &.genealogy-search-modal {
        text-align: center;

        .genealogy-search-result-item {

            &.single-line,
            &.two-line {
                grid-template-columns: auto;
            }
        }

        .genealogy-search--loader {
            flex-direction: column;
            width: 100%;
            padding-top: 0px;
            background-color: #e3e8ed;
            border-radius: 0px 0px 20px 20px;

            .ant-skeleton {
                width: 100%;

                .ant-skeleton-button {
                    border-radius: 7px;
                }
            }

        }

        .ant-modal-body {
            border-radius: 0px 0px 15px 15px;
        }

        .ant-input-affix-wrapper:focus {
            outline: 0;
            border-color: #22aaec;
        }

        .ant-input-affix-wrapper-focused {
            box-shadow: none;
        }

        .ant-modal-body {
            padding: 20px 40px;
            background-color: #e3e8ed;
            border-radius: 0px 0px 20px 20px;

            .search-input-box {
                background-color: #e3e8ed;
                border-radius: 0px 0px 20px 20px;
            }
        }

        .genealogy-result-item-container {
            padding-top: 20px;
            background-color: #e3e8ed;
            border-radius: 0px 0px 20px 20px;
        }
    }


    .profile-img-box {
        .avatar-box {
            width: 60px;
            height: 61px;
            border-radius: 80px;
        }
    }

    &.genealogy-breadcrumb-wrapper {
        &.no-footer {
            .ant-modal-body {
                border-radius: 0px 0px 20px 20px;
            }
        }
    }

    &.genealogy-page-filter {
        .genealogy-search-result-item {
            grid-template-columns: 100%;

            >div {
                text-align: center;
            }
        }
    }
}

.genealogy-search-box {
    display: grid;
    justify-content: center;
    grid-template-columns: 3fr;

    .ant-input-group {
        text-align: center;
        padding-left: -42px;
        border-radius: 7px!important;
    }

    h4 {
        margin-bottom: 20px;
    }
}

.drawer-filter {

    &.ant-drawer-open {
        .ant-drawer-content-wrapper {
            right: 40px;
            width: 374px !important;
        }
    }

    .ant-drawer-content-wrapper {
        width: 374px;
        height: auto;
        top: 92px;

        .ant-drawer-content {
            border-radius: 7px;
        }

        .ant-drawer-header-no-title {
            display: none;
        }

        .ant-drawer-body {
            padding: 0px;


            .floating-genealogy-filter {
                position: relative;

                .genealogy-menu {
                    margin-top: 0px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .list-group {
                        width: 100%;

                        .list-group-item {
                            font-size: 14px;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            min-height: 50px;
                            padding-left: 50px;
                            cursor: pointer;

                            &:last-child {
                                border-radius: 0px;
                            }

                            .btn-ok {
                                width: 20px;
                                height: 30px;
                                border-radius: 5px;
                                font-size: 12px;
                                padding: 0px 20px;
                            }

                            &.with-icon {
                                display: grid;
                                grid-template-columns: 25px auto;
                                grid-gap: 20px;
                            }


                            &.action-header {
                                display: grid;
                                grid-template-columns: 2fr 35px;
                                padding-left: 30px;
                            }

                            &.list-group-actions {
                                cursor: default;
                                background-color: #e3e8ed;
                                border: 0px;
                                height: auto;

                                ul {
                                    list-style: none;
                                    padding-left: 0px;
                                    width: 100%;

                                    li {
                                        height: 50px;
                                        display: flex;
                                        align-items: center;
                                        font-size: 14px;
                                        padding-left: 0px;


                                        >div {
                                            display: grid;
                                            grid-template-columns: repeat(2, auto);
                                            padding: 0px;
                                            width: 100%;

                                            >div:last-child {
                                                justify-self: end;
                                                margin-right: 30px;
                                            }
                                        }


                                    }
                                }
                            }
                        }
                    }

                    .filter-view-box {
                        padding: 30px;
                        padding-top: 20px;
                        background-color: #e3e8ed;

                        .anticon-loading {
                            font-size: 16px!important;
                            top: -2px!important;
                        }

                        p {
                            font-weight: 400;
                            text-transform: uppercase;
                        }

                        .list-group {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 10px;

                            .list-group-item {
                                border-radius: 7px;
                                border: 1px solid #e1e7ec;
                                cursor: pointer;
                                background-color: #ffffff;
                                color: #9EA9B9;
                                padding-left: 20px;

                                svg {
                                    path {
                                        fill: #9EA9B9;
                                    }

                                    rect {
                                        stroke: #9EA9B9
                                    }
                                }

                                &.item-active {
                                    background-color: #22AAEC;
                                    color: white;

                                    svg {
                                        path {
                                            fill: white;
                                        }

                                        rect {
                                            stroke: white
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.drawer-filter-wrapper {
    top: 0px !important;

    .ant-modal-body {
        padding: 0px;
        background: transparent;
    }

    .ant-modal-footer,
    .ant-modal-close {
        display: none;
    }

    .drawer-filter {
        top: 79px;
        width: 374px !important;
        height: auto;
        webkit-transform: translate(2000px, 0);
        -webkit-transition: -webkit-transform 1s linear;
        -moz-transform: translate(2000px, 0);
        -moz-transition: -moz-transform 1s linear;
        -ms-transform: translate(2000px, 0);
        -ms-transition: -ms-transform 1s linear;


        .floating-genealogy-filter {
            position: relative;

            .genealogy-menu {
                margin-top: 0px;
                width: 100%;
                display: flex;
                flex-direction: column;

                .list-group {
                    width: 100%;

                    .list-group-item {
                        font-size: 14px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        min-height: 50px;
                        padding-left: 50px;
                        cursor: pointer;

                        &:last-child {
                            border-radius: 0px;
                        }

                        .btn-ok {
                            width: 20px;
                            height: 30px;
                            border-radius: 5px;
                            font-size: 12px;
                            padding: 0px 20px;
                        }

                        &.with-icon {
                            display: grid;
                            grid-template-columns: 25px auto;
                            grid-gap: 20px;
                        }


                        &.action-header {
                            display: grid;
                            grid-template-columns: 2fr 35px;
                            padding-left: 30px;
                        }

                        &.list-group-actions {
                            cursor: default;
                            background-color: #e3e8ed;
                            border: 0px;
                            height: auto;

                            ul {
                                list-style: none;
                                padding-left: 0px;
                                width: 100%;

                                li {
                                    height: 50px;
                                    display: flex;
                                    align-items: center;
                                    font-size: 14px;
                                    padding-left: 0px;


                                    >div {
                                        display: grid;
                                        grid-template-columns: repeat(2, auto);
                                        padding: 0px;
                                        width: 100%;

                                        >div:last-child {
                                            justify-self: end;
                                            margin-right: 30px;
                                        }
                                    }


                                }
                            }
                        }
                    }
                }

                .filter-view-box {
                    padding: 30px;
                    padding-top: 20px;
                    background-color: #e3e8ed;
                    border-radius: 0px 0px 7px 7px;

                    p {
                        font-weight: 400;
                        text-transform: uppercase;
                    }

                    .list-group {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 10px;

                        .list-group-item {
                            border-radius: 7px;
                            border: 1px solid #e1e7ec;
                            cursor: pointer;
                            background-color: #ffffff;
                            color: #9EA9B9;
                            padding-left: 20px;

                            svg {
                                path {
                                    fill: #9EA9B9;
                                }

                                rect {
                                    stroke: #9EA9B9
                                }
                            }

                            &.item-active {
                                background-color: #22AAEC;
                                color: white;

                                svg {
                                    path {
                                        fill: white;
                                    }

                                    rect {
                                        stroke: white
                                    }
                                }
                            }
                        }
                    }
                }

                .ant-collapse {
                    border-radius: 7px;
                    overflow: hidden;
                    border: 0px;
                    background: #ffffff;

                    > .ant-collapse-item {
                        margin-bottom: 10px;
                        border-bottom: 0px;

                        &.ant-collapse-item-disabled {
                            > .ant-collapse-header {
                                color: rgba(0, 0, 0, 0.85);
                                cursor: default
                            }
                        }

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                    .ant-collapse-header {
                        background: #e3e8ed;
                    }

                    .ant-collapse-content {
                        border: 0px;
                    }

                    .ant-collapse-extra {
                        position: absolute;
                        right: 20px;
                        top: 10px;
                        cursor: pointer;
                    }

                    .ant-collapse-content-box {
                        padding: 0px;

                        .filter-view-box {
                            background-color: #ffffff;
                            padding: 20px 30px;
                            padding-bottom: 10px;


                        }

                        .switcher-items {
                            li {
                                background-color: #ffffff;
                            }
                        }

                        .vertical {
                            .list-group-item:first-child {
                                border-top: 0px;
                                border-left: 0px;
                                border-right: 0px;
                                border-radius: 0px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .drawer-filter {
        &.ant-drawer-open {

            .ant-drawer-content-wrapper {
                top: 79px;
                width: 354px !important;
            }
        }

        &.ant-drawer {
            position: absolute;
        }

        .ant-drawer-content-wrapper {
            top: 79px;
            width: 354px !important;
        }
    }
}




@media screen and (max-width: 414px) {
    .filter-modal-box {
        .ant-modal-body {
            padding: 20px !important;

            .genealogy-search-result-item {
                grid-template-columns: auto 62px;
                padding: 10px 10px 10px 20px;

                p {
                    font-size: 14px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: auto;
                }

                .profile-img-box {
                    &.with-rank-image {
                        >div {
                            width: 60px;
                            height: 60px;
                        }
                    }

                    svg {
                        width: 40px;
                    }

                    canvas {
                        width: 56px;
                        height: 56px;
                    }

                    &.mark-current-user {
                        border: 3px double #6fcf97
                    }
                }

                .spinner-container {
                    img {
                        width: 36px !important;
                        height: 36px !important;
                    }
                }
            }
        }

        &.genealogy-bookmark {
            .genealogy-search-result-item {
                grid-template-columns: auto 20px;
                align-items: center;
            }
        }

        &.genealogy-search {
            .genealogy-search-result-item {

                &.two-line,
                &.single-line {
                    grid-template-columns: 1fr;
                    align-items: center;
                }
            }
        }
    }

    .drawer-filter {
        &.ant-drawer-open {
            .ant-drawer-content-wrapper {
                width: 374px !important;
            }
        }

        .ant-drawer-content-wrapper {
            width: 374px !important;

            .ant-drawer-body {
                .floating-genealogy-filter {
                    .genealogy-menu {

                        .list-group {
                            .list-group-item {
                                padding-left: 50px;
                                padding-right: 50px;

                                &.list-group-actions {
                                    ul {
                                        >li {
                                            >div {

                                                >div:last-child {
                                                    margin-right: 0px;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.action-header {
                                    padding-right: 30px;
                                    padding-left: 30px;

                                    >div {
                                        margin: 0px;
                                    }

                                    >div:last-child {
                                        margin-right: 0px;
                                        justify-content: flex-end;
                                    }
                                }
                            }
                        }

                        .filter-view-box {
                            padding: 15px;
                        }
                    }
                }
            }
        }


    }

}

@media screen and (max-width: 767px) {


    .drawer-filter-wrapper {
        .drawer-filter {
            top: 20px;
        }
    }

}

@media screen and (max-width: 375px) {
    .drawer-filter {
        &.ant-drawer-open {

            .ant-drawer-content-wrapper {
                width: 354px !important;
                right: 10px;
            }
        }

        .ant-drawer-content-wrapper {
            width: 354px !important;
            right: 0px;

            .ant-drawer-body {
                .floating-genealogy-filter {
                    .genealogy-menu {
                        .filter-view-box {
                            padding: 15px;
                        }
                    }
                }
            }
        }
    }

    .drawer-filter-wrapper {


        .drawer-filter {
            width: 354px !important;
        }
    }
}


@media screen and (max-width: 320px) {
    .drawer-filter {
        left: 10px;

        &.ant-drawer-open {

            .ant-drawer-content-wrapper {
                width: 290px !important;
            }
        }

        .ant-drawer-content-wrapper {
            width: 290px !important;
        }
    }

    .drawer-filter-wrapper {
        right: 15px !important;

        .drawer-filter {
            width: 290px !important;

            .floating-genealogy-filter .genealogy-menu .filter-view-box {
                padding: 10px;
            }
        }
    }
}