.ushop-wrapper {

    width: 100%;
    height: 100%;
}

.web-backdrop {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .container {
        align-content: center;
        justify-content: center;
        display: flex;
    }

    .loadingImage {
        width: 75px;
        height: 75px;
        z-index: 101;
    }

    .bg-backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        opacity: .6;
    }
}