/* .modal-dialog {
  width: 100%;
  height: 90%;
  margin: 0;
  padding: 0;
}
.modal-content {
  height: auto;
  width: 100% !important;
  height: 100% !important;
  min-height: 100%;
  min-width: 100%;
  border-radius: 0;
} */
.ant-modal-confirm-btns {
    /* display: 'flex' !important; */
}
